import './private-wireless-contact.scss';
import MailIcon from '../../../../assets/images/private-wireless/mail.svg';
import {useForm} from "react-hook-form";
import Input from "../../ui-component/input/input";
import React, {useState} from "react";
import ContactBgImage from '../../../../assets/images/private-wireless/contact-bg.png';
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import Spinner from "../../ui-component/spinner/spinner";
import {toast} from "react-toastify";
import {submitContactForm} from "../../../../redux/workers/contact-forms/contact-forms";
import useMediaBelow from "../../../../shared/hooks/useMediaBelow";

const schema = yup.object().shape({
    name: yup.string().required('This field is required'),
    email: yup.string().required('This field is required'),
    phone: yup.string().required('This field is required'),
    company: yup.string().required('This field is required'),
    enquiryType: yup.string().required('This field is required')
});

export default function PrivateWirelessContact() {

    const isMobile = useMediaBelow(768);
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await submitContactForm(data);
            toast.success('Thanks for contacting with us!');
            reset();
        } catch (ex) {
            toast.error(ex.response?.data?.payload?.message || 'An error occurred!', {
                position: "top-right"
            });
        }
        finally {
            setLoading(false);
        }
    };

    function renderLeftSide() {
        return (
            <div className="pwt-contact--left-side">
                <div className="pwt-contact--title">
                    Contact information
                </div>
                <div className="pwt-contact--subtitle">
                    We are available between 9AM and 5PM Pacific Time.
                </div>
                <div className="pwt-contact--item">
                    <img src={MailIcon} alt="Email" className="pwt-contact--item-icon" />
                    <div className="pwt-contact--item-title">
                        <a href="mailto:support@gotalkwireless.com" target="_blank">
                            Support@gotalkwireless.com
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="pwt-contact" style={{
            background: `linear-gradient(0deg, rgba(35, 50, 79, 0.55), rgba(35, 50, 79, 0.55)), url(${ContactBgImage})`
        }}>
            {!isMobile ? renderLeftSide() : null}
            <div className="pwt-contact--right-side">
                <div className="pwt-contact--title">
                    Contact the sales team<br />for more information
                </div>
                <form className="pwt-contact--form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pwt-contact--form-item">
                        <Input
                            {...register('name')}
                            isInvalid={!!errors.name}
                            description={errors.name?.message}
                            type="text"
                            placeholder="Enter name"
                            label="Name"
                        />
                    </div>
                    <div className="pwt-contact--form-item">
                        <Input
                            {...register('email')}
                            isInvalid={!!errors.email}
                            description={errors.email?.message}
                            type="email"
                            placeholder="you@company.com"
                            label="E-mail"
                        />
                    </div>
                    <div className="pwt-contact--form-item">
                        <Input
                            {...register('phone')}
                            isInvalid={!!errors.phone}
                            description={errors.phone?.message}
                            type="phone"
                            placeholder="+1 (424) 000-000"
                            label="Phone"
                        />
                    </div>
                    <div className="pwt-contact--form-item">
                        <Input
                            {...register('company')}
                            isInvalid={!!errors.company}
                            description={errors.company?.message}
                            type="text"
                            placeholder="Enter company name"
                            label="Company"
                        />
                    </div>
                    <div className="pwt-contact--form-item">
                        <Input
                            {...register('enquiryType')}
                            isInvalid={!!errors.enquiryType}
                            description={errors.enquiryType?.message}
                            type="text"
                            placeholder="Education, Rural, Other"
                            label="Enquiry type"
                        />
                    </div>
                    <div className="pwt-contact--form-item">
                        {loading ? <Spinner /> : (
                            <button className="pwt-contact--button" type="submit">
                                SEND REQUEST
                            </button>
                        )}
                    </div>
                </form>
            </div>
            {isMobile ? renderLeftSide() : null}
        </div>
    );
}
