import API from "../../../axios/api";
import {CONVERSION_TRACKING} from "../../../axios/api-urls";
import conversionTrackingTypes from "./conversion-tracking-types";

const api = new API();
export const logConversionTracking = (data) => api.post(`${CONVERSION_TRACKING.log}`, data);
export const logUserConversionTracking = (data) => api.post(`${CONVERSION_TRACKING.userLog}`, data);

export function createDispatchLog(actionName, actionData = null) {
    return {
        type: conversionTrackingTypes.LOG_CONVERSION_TRACKING,
        payload: {
            actionName,
            actionData
        }
    }
}
