import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import useMediaBelow from '../../shared/hooks/useMediaBelow';
import { fetchHardwareDetail } from '../../redux/workers/hardwares/hardwares';
import BasketSuccessPopup from '../../elements/components/ui-component/basket-success-popup/basket-success-popup';
import { useDispatch } from 'react-redux';
import * as basketActions from '../../shared/basketActions';
import basketTypes from '../../redux/workers/basket/basket-types';
import { createDispatchLog } from '../../redux/workers/conversion-tracking';
import { ConversionTrackingActionNames } from '../../types/conversionTrackingActionNames';
import { getBasketItems } from '../../shared/basketActions';

export const HardwareDetailContext = createContext({});

export default function HardwareDetailContextProvider({ children }) {
  const [productDetail, setProductDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(0);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [selectablePlans, setSelectablePlans] = useState([]);
  const { slugUrl } = useParams();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaBelow(1200);
  const [showAddedPopup, setShowAddedPopup] = useState(false);
  const dispatch = useDispatch();

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);
  const [selectedConditionIndex, setSelectedConditionIndex] = useState(0);
  const [selectedStorageIndex, setSelectedStorageIndex] = useState(0);

  useEffect(() => {
    initFetch().then();
  }, [slugUrl]);

  async function initFetch() {
    setLoading(true);
    try {
      const res = await fetchHardwareDetail(slugUrl);
      const { productDetail, similarProducts, selectablePlans } = res.data.payload;

      setProductDetail(productDetail);
      setSimilarProducts(similarProducts);
      setSelectablePlans(selectablePlans);

      if (searchParams.has('hv')) {
        const hvId = Number(searchParams.get('hv'));
        const hvIndex = productDetail.HardwareVariants.findIndex((m) => m.id === hvId);
        if (hvIndex > -1) {
          setSelectedVariantIndex(hvIndex);
        }
      }

      if (searchParams.has('c')) {
        setSelectedConditionIndex(Number(searchParams.get('c')));
      }

      if (searchParams.has('s')) {
        setSelectedStorageIndex(Number(searchParams.get('s')));
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  }

  function onPlanChange(planId) {
    setSelectedPlanId(planId);
  }

  function onVariantChange(idx) {
    setSelectedVariantIndex(idx);
    setSelectedConditionIndex(0);
    setSelectedStorageIndex(0);
  }

  function onConditionChange(idx) {
    setSelectedConditionIndex(idx);
    setSelectedStorageIndex(0);
  }

  function onStorageChange(idx) {
    setSelectedStorageIndex(idx);
  }

  function addHardwareToBasket() {
    const hardwareBasketId = `${productDetail.id}-${selectedVariantIndex}-${selectedConditionIndex}-${selectedStorageIndex}`;
    basketActions.addHardwareToBasket(hardwareBasketId, {
      ...productDetail,
      selectedVariantIndex,
      selectedConditionIndex,
      selectedStorageIndex,
      selectedPlanId,
    });
    dispatch({
      type: basketTypes.BASKET_UPDATE,
      payload: {
        totalCount: basketActions.getBasketItems().length,
      },
    });
    dispatch(
      createDispatchLog(ConversionTrackingActionNames.BASKET, {
        actionDataType: 'BASKET_UPDATE',
        data: {
          items: getBasketItems(),
        },
      })
    );
    setShowAddedPopup(true);
  }

  function buyWithAffirm() {
    addHardwareToBasket();
  }

  const selectedVariant = useMemo(() => {
    return productDetail?.HardwareVariants?.[selectedVariantIndex];
  }, [productDetail, selectedVariantIndex]);

  const images = useMemo(() => {
    return selectedVariant?.images || [];
  }, [selectedVariant]);

  const selectedCondition = useMemo(() => {
    return selectedVariant?.conditions?.[selectedConditionIndex];
  }, [selectedConditionIndex, selectedVariant]);

  const selectedStorage = useMemo(() => {
    return selectedCondition?.storages?.[selectedStorageIndex];
  }, [selectedStorageIndex, selectedCondition]);

  const conditions = useMemo(() => {
    return selectedVariant?.conditions || [];
  }, [selectedVariant]);

  const storages = useMemo(() => {
    return selectedCondition?.storages || [];
  }, [selectedCondition]);

  return (
    <HardwareDetailContext.Provider
      value={{
        productDetail,
        selectedPlanId,
        similarProducts,
        loading,
        selectablePlans,
        isMobile,
        onPlanChange,
        onVariantChange,
        onConditionChange,
        onStorageChange,
        addHardwareToBasket,
        buyWithAffirm,
        images,
        selectedVariant,
        selectedCondition,
        selectedStorage,
        conditions,
        storages,
      }}
    >
      {children}

      {showAddedPopup && (
        <BasketSuccessPopup
          close={() => setShowAddedPopup(false)}
          onContinue={() => setShowAddedPopup(false)}
        />
      )}
    </HardwareDetailContext.Provider>
  );
}
