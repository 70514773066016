import './hardware-listing-filters.scss';
import {useContext} from "react";
import {HardwareListingContext} from "../../../../contexts/hardware-listing-context/hardware-listing-context";
import Accordion from "../accordion/accordion";
import Checkbox from "../checkbox/checkbox";
import PriceSlider from "../price-slider/price-slider";

export default function HardwareListingFilters() {
    const {filters, hasFilter, clearFilters, onFilterChange} = useContext(HardwareListingContext);

    function onCheckChange(filterIndex, itemIndex, checked) {
        const newFilters = [...filters];
        newFilters[filterIndex].items[itemIndex].checked = !!checked;
        onFilterChange(newFilters);
    }

    function onSliderChange(filterIndex, minValue, maxValue) {
        const newFilters = [...filters];
        const filterItem = newFilters[filterIndex];
        if(typeof minValue === "number" && minValue >= filterItem.minValue && minValue <= filterItem.selectedMaxValue) {
            filterItem.selectedMinValue = minValue;
        }
        if(typeof maxValue === "number" && maxValue <= filterItem.maxValue && maxValue >= filterItem.selectedMinValue) {
            filterItem.selectedMaxValue = maxValue;
        }
        onFilterChange(newFilters);
    }

    function renderFilterItems(filter, fIdx) {
        if(filter.type === 'checkbox') {
            return filter.items.map((item, itemIndex) => (
                <Checkbox
                    checked={item.checked}
                    onChange={checked => onCheckChange(fIdx, itemIndex, checked)}
                    key={itemIndex.toString()}>
                    {item.label}
                </Checkbox>
            ));
        }

        if(filter.type === 'slider') {
            return (
                <PriceSlider
                    minValue={filter.minValue}
                    maxValue={filter.maxValue}
                    selectedMinValue={filter.selectedMinValue}
                    selectedMaxValue={filter.selectedMaxValue}
                    onChange={({minValue, maxValue}) => onSliderChange(fIdx, minValue, maxValue)}
                />
            );
        }

        return null;
    }

    return (
        <div className="hwl-filters">
            <h3 className="hwl-filters--title">
                Filters {hasFilter && <span className="hwl-filters--clear-all" onClick={() => clearFilters()}>CLEAR ALL FILTERS</span>}
            </h3>

            {filters.map((filter, fIdx) => (
                <Accordion
                    label={filter.label}
                    key={fIdx.toString()}>
                    {renderFilterItems(filter, fIdx)}
                </Accordion>
            ))}
        </div>
    );
}
