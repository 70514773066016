import AccountTariffInfoPlanCircle from '../../../../components/account/tariff-info/circle/circle';
import AccountTariffInfoPlanCard from '../../../../components/account/tariff-info/plan-card/plan-card';
import AccountTariffInfoPlanHeader from '../../../../components/account/tariff-info/plan-header/plan-header';
import Button from '../../../../components/ui-component/button/button';
import Spinner from '../../../../components/ui-component/spinner/spinner';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import './tariff-card.scss';
import {useDispatch, useSelector} from 'react-redux';
import accountTypes from "../../../../../redux/workers/account/account-types";
import {useNavigate} from "react-router-dom";
import {isUnlimited} from "../../../../../utils/utils";
import ChangePlanContainer from "../../../../components/change-plan-container/change-plan-container";

const AccountTariffInfoCard = ({ addClass }) => {

  const account = useSelector((state) => state.accountReducer.accountData);
  const currentProduct = useSelector((state) => state.accountReducer.currentProduct);
  const isCurrentProductLoading = useSelector(
    (state) => state.loadingReducer.currentProductLoading
  );
  const isAccountLoading = useSelector((state) => state.loadingReducer.loadingAccountData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const changePlanRef = useRef();

  const isOuterSellingSim = get(currentProduct, 'isOuterSellingSim', false);
  const plan = get(currentProduct, 'plan', {});
  const name = get(plan, 'name', '');
  const costPerMonth = get(plan, 'costPerMonth', 0);
  const minuteCount = get(plan, 'minuteCount', 0);
  const SMSCount = get(plan, 'SMSCount', 0);

  const remainder = get(plan, 'remainder', {});
  const min = get(remainder, 'min', 0);

  const subscriberUsage = get(currentProduct, 'querySubscriber.product.usage', {});
  const internetCountUnit = get(subscriberUsage, 'limit.bucket_value.unit', '');
  const internetCountValue = get(subscriberUsage, 'limit.bucket_value.value', '');
  const internetRemaining = get(subscriberUsage, 'remaining.value', 0);
  const sms = get(remainder, 'sms', 0);

  const number = get(currentProduct, 'msisdn', '');

  const classes = classNames('account-tariff-info-card', addClass);

  function cancelSubscription() {
    const confirmed = confirm('Are you sure?\nYour sim will be deactivated.');
    if(confirmed) {
      dispatch({
        type: accountTypes.CANCEL_SUBSCRIPTION,
        data: {
          id: currentProduct.productId,
          redirect: navigate
        }
      });
    }
  }

  if(isCurrentProductLoading || isAccountLoading) {
    return (
        <div className={classes}>
          <div className="account-tariff-info__spinner-container">
            <Spinner />
          </div>
        </div>
    );
  }

  if(plan.status === 'cancelled_by_customer') {
    return (
        <div className={classes}>
          <div className="account-tariff-info__cancel">
            This sim has been cancelled
          </div>
        </div>
    );
  }

  return (
    <div className={classes}>
      <AccountTariffInfoPlanCard>
        <AccountTariffInfoPlanHeader number={number} tariff={name} sum={costPerMonth} isOuterSellingSim={isOuterSellingSim} />

        <div className="account-tariff-info__container">
          <div className="account-tariff-info__circles-wrapper">
            <AccountTariffInfoPlanCircle
                isUnlimited={isUnlimited(minuteCount)}
                count={min}
                unit="min"
                max={Number(minuteCount)}
            />
            <AccountTariffInfoPlanCircle
                isUnlimited={isUnlimited(internetCountValue)}
                count={Number(internetRemaining)}
                unit={internetCountUnit}
                max={Number(internetCountValue)}
            />
            <AccountTariffInfoPlanCircle
                isUnlimited={isUnlimited(SMSCount)}
                count={sms}
                unit="SMS"
                max={Number(SMSCount)}
            />
          </div>

          {/*<div className="account-tariff-info__params-wrapper">*/}
          {/*  {params.map((param) => (*/}
          {/*    <AccountTariffInfoPlanParameter*/}
          {/*      addClass="account-tariff-info__params-item"*/}
          {/*      key={param}*/}
          {/*      title={param}*/}
          {/*    />*/}
          {/*  ))}*/}
          {/*</div>*/}

          <a href="#" className="account-tariff-info__options-link">
            Add options
          </a>

          <Button addClass="account-tariff-info__change-btn" title="Change the plan" onClick={() => changePlanRef.current?.open()} />

          <button type="button" className="account-tariff-cancel-sub" onClick={() => cancelSubscription()}>
            Cancel Subscription
          </button>
        </div>
      </AccountTariffInfoPlanCard>
      <ChangePlanContainer
          ref={changePlanRef}
          currentProductId={currentProduct.productId}
          currentPlanId={plan?.id}
      />
    </div>
  );
};

AccountTariffInfoCard.propTypes = {
  addClass: PropTypes.string,
};

export default AccountTariffInfoCard;
