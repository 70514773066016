import accountWatcher from './account';
import authWatcher from './auth';
import paymentWatcher from './payment';
import plansWatcher from './plans';
import { all, fork } from 'redux-saga/effects';
import generalSettingsWatcher from "./general-settings";
import conversionTrackingWatcher from "./conversion-tracking";

const rootSaga = function* root() {
  yield all([fork(authWatcher)]);
  yield all([fork(plansWatcher)]);
  yield all([fork(paymentWatcher)]);
  yield all([fork(accountWatcher)]);
  yield all([fork(generalSettingsWatcher)]);
  yield all([fork(conversionTrackingWatcher)]);
};

export default rootSaga;
