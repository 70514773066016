export const AUTH = {
  signUp: '/v1/auth/signup',
  signIn: '/v1/auth/login',
  checkAuth: '/v1/users/me',
  multiFactorSignIn: '/v1/mfa/verificationMFA',
  sendEmailCode: '/v1/mfa/sendEmail',
  restorePassword: '/v1/auth/password/reset',
  setNewPassword: '/v1/auth/password/set',
  adminSignin: '/v1/auth/adminSignin',
  outerActivation: '/v1/auth/outer-activation',
};

export const USERS = {};

export const MAIN = {
  plans: '/v1/plans/acts',
};

export const PAYMENT = {
  buyPlans: '/v1/buy',
  buyPlansCheck: '/v1/buy/check',
  buyPlansAuthorized: '/v1/sim/buy',
};

export const ACCOUNT = {
  getUserData: '/v1/users/me',
  putUserData: '/v1/users',
  postUserPassword: '/v1/users/change-password',
  getProductInfo: '/v1/users/products',
  setMethodVerification: '/v1/mfa/switchMFA',
  sendVerificationEmailCode: '/v1/mfa/sendEmail',
  postActivateSim: '/v1/sim/activate-physical',
  postChangeNumber: '/v1/sim/set-phone',
  postActivateESim: '/v1/sim/esim/activate',
  getQr: '/v1/sim/esim/qr',
  createSetupIntent: '/v1/users/create-setup-intent',
  getSetupIntentResult: '/v1/users/get-setup-intent-result',
  getPaymentInformation: '/v1/users/get-payment-information',
  webGuardInfos: '/v1/users/web-guard-infos',
  getCallHistory: '/v1/users/get-call-history',
  getSmsHistory: '/v1/users/get-sms-history',
  getPaymentHistory: '/v1/users/get-payment-history',
  getIsUserEligibleForVpn: '/v1/vpn-user/is-user-eligible',
  getVpnUser: '/v1/vpn-user',
  addVpnUser: '/v1/vpn-user',
  getVpnAppLinks: '/v1/vpn-user/app-links',
  resetVpnUserPassword: '/v1/vpn-user/reset-password',
  setVpnUserPassword: '/v1/vpn-user/set-password',
  cancelSubscription: '/v1/sim/cancel-subscription',
  changePlan: '/v1/plans/change',
  confirmChangePlan: '/v1/plans/confirming-change',
  generateSecretAndGetOtpQr: '/v1/mfa/generate-secret-and-get-otp-qr',
  changeToNewDeviceGetQr: '/v1/sim/esim/new-device-get-qr',
  makePaymentForExpiredSim: '/v1/sim/make-payment-for-expired-sim',
};

export const COUPON = {
  canUseCoupon: '/v1/coupons/canUseCoupon',
};

export const GENERAL_SETTINGS = {
  getSettings: '/v1/general-settings/get',
};

export const STATIC_PAGES = {
  getTermsAndConditions: '/v1/static-pages/page-type/TERMS_CONDITIONS',
  getPrivacyPolicy: '/v1/static-pages/page-type/PRIVACY_POLICY',
  getReturnPolicy: '/v1/static-pages/page-type/RETURN_POLICY',
  getAcceptableUsePolicy: '/v1/static-pages/page-type/ACCEPTABLE_USE_POLICY',
  getWhyChangeCarrier: '/v1/static-pages/page-type/WHY_CHANGE_CARRIER',
  getFAQ: '/v1/static-pages/page-type/FAQ',
  getFraudProtection: '/v1/static-pages/page-type/FRAUD_PROTECTION',
  getSupport: '/v1/static-pages/page-type/SUPPORT',
};

export const LANDING_PAGES = {
  getPageByPageUrl: '/v1/landing-pages/page-url',
};

export const CONVERSION_TRACKING = {
  log: '/v1/conversion-tracking/log',
  userLog: '/v1/conversion-tracking/user-log',
};

export const CONTACT_FORM = {
  submit: '/v1/contact-forms',
};

export const HARDWARES = {
  listing: '/v1/hardwares/listing',
  detail: '/v1/hardwares/detail/',
  brands: '/v1/hardwares/brands',
};

export const USER_HARDWARES = {
  detail: '/v1/user-hardwares/',
};

export const AFFIRM = {
  saveOrder: '/v1/affirm/save-order',
  saveOrderUser: '/v1/affirm/save-order-user',
  generateCheckoutRequest: '/v1/affirm/generate-checkout-request',
  generateCheckoutRequestUser: '/v1/affirm/generate-checkout-request-user',
};

export const TAXES = {
  calculate: '/v1/taxes/calculate',
};
