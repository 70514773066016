/* eslint-disable react-hooks/exhaustive-deps */
import fiveG from '../../../../assets/images/icons/fiveG.svg';
import shield from '../../../../assets/images/icons/shield.svg';
import sim from '../../../../assets/images/icons/sim.svg';
import React from 'react';
import logo from '../../../../assets/images/logo/logo.svg';
import './present-main.scss';
import reactStringReplace from "react-string-replace";
import HomePresentBlock from "../home-present-block/home-present-block";

const PresentMain = () => {
  return (
    <div className="present-main-block">
      <HomePresentBlock />
      {/*<div className="present-main-protection">*/}
      {/*  <span className="present-main-protection__orange">*/}
      {/*    {reactStringReplace(generalSettings?.homeUnderBannerOrange, '{logo}', (match, i) => (*/}
      {/*        <img className="present-main-protection__logo" src={logo} alt="GoTalk Wireless" />*/}
      {/*    ))}*/}
      {/*  </span>*/}
      {/*  <div className="present-main-protection__row">*/}
      {/*    <span className="present-main-protection__black">*/}
      {/*      {reactStringReplace(generalSettings?.homeUnderBannerBlack, '{logo}', (match, i) => (*/}
      {/*          <img className="present-main-protection__logo" src={logo} alt="GoTalk Wireless" />*/}
      {/*      ))}*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="present-main-video">*/}
      {/*  <iframe src="https://www.youtube-nocookie.com/embed/917kcWeqpF0?start=16"*/}
      {/*          title="YouTube video player" frameBorder="0"*/}
      {/*          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
      {/*          allowFullScreen>*/}
      {/*  </iframe>*/}
      {/*</div>*/}
      <div className="present-main-technologies">
        <div className="present-main-technologies_block">
          <div className="technologies_block-item">
            <img src={shield} alt="main" className="technologies_block-image" />
            <p className="technologies_block-text">
              VPN, Anti Phishing tools and Port Out Block included with selected Plans
            </p>
          </div>
          <div className="technologies_block-item">
            <img src={sim} alt="main" className="technologies_block-image" />
            <p className="technologies_block-text">
              E-Sim technology<br/>Move from any carrier in under 10 minutes
            </p>
          </div>
          <div className="technologies_block-item">
            <img src={fiveG} alt="main" className="technologies_block-image" />
            <p className="technologies_block-text">99% US coverage with 5G capabilities</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentMain;
