import './billing-form.scss';
import routes from '../../../navigation/routes';
import paymentTypes from '../../../redux/workers/payment/payment-types';
import {getBasketItems, getCouponFromLocalStorage} from '../../../shared/basketActions';
import { billingUserSchema, deliverySchema, emptySchema } from '../../../shared/schemas/validation-rules';
import DeliveryInfoForm from '../delivery-info-form/delivery-info-form';
import Checkbox from '../ui-component/checkbox/checkbox';
import SimsOutErrors from '../ui-component/sims-out-errors/sims-out-errors';
import UserInfoForm from '../user-info-form/user-info-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {PlanFilterPageType, useFilteredPlansByPlaceType} from "../../../hooks/useFilteredPlansByPlaceType";
import {generateCheckoutRequest, generateUserCheckoutRequest} from "../../../redux/workers/affirm/affirm";
import {startLoading, stopLoading} from "../../../redux/workers/loading";

const BillingForm = ({ onSubmit, checkout, isBasketEmpty }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plans = useFilteredPlansByPlaceType({
    pageType: PlanFilterPageType.BASKET
  });
  const { isSignedIn, user } = useSelector((state) => state.authReducer);
  const { errors } = useSelector((state) => state.payment);
  const { hasDelivery, paymentType, paymentMethodType, deliveryProvider } = useSelector((state) => state.basketReducer);
  const [isDeliveryAuto, setDeliveryAuto] = useState(true);
  const [billingAddress, setBillingAddress] = useState();

  const deliveryContentClasses = classNames(
    'billing-form__form-container',
    'delivery',
    { 'billing-form__form-container--wide': isSignedIn },
  );

  const schema = yup.object().shape({
    user: isSignedIn ? emptySchema() : billingUserSchema(),
    delivery: isDeliveryAuto
    || !hasDelivery
      ? emptySchema()
      : deliverySchema(),
  }).required();

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!hasDelivery && isSignedIn && !checkout) {
      navigate(routes.bag);
    }
  }, [hasDelivery, isSignedIn, navigate, checkout]);

  useEffect(() => {
    dispatch({ type: paymentTypes.RESET_PAYMENT_ERRORS });
  }, [dispatch]);

  useEffect(() => {
    let sub;
    if (isDeliveryAuto) {
      sub = methods.watch((value) => {
        setBillingAddress({ ...value.user });
      });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [isDeliveryAuto, methods]);

  useEffect(() => {
    if(isSignedIn) {
      setDeliveryAuto(false);
    }
  }, [isSignedIn]);

  const handleUserInfo = async (data) => {
    const products = getBasketItems();
    const payloadData = {
      products: products.map(p => {
        if(p.type === 'hardware') {
          return {
            ...p,
            data: {
              id: p.data.id,
              selectedVariantId: p.data.HardwareVariants[p.data.selectedVariantIndex].id,
              selectedConditionIndex: p.data.selectedConditionIndex,
              selectedStorageIndex: p.data.selectedStorageIndex
            }
          }
        }

        return p;
      }),
      coupon: await getCouponFromLocalStorage(),
      paymentType
    };

    if(!data.delivery) {
      data.delivery = {
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        apartment: '',
        country: '',
        zip: ''
      }
    }

    if (hasDelivery) {
      payloadData.delivery = data.delivery;
    }

    if (!isSignedIn) {
      payloadData.user = data.user;
      delete payloadData.user.passwordConfirmation;
    }

    if (isDeliveryAuto && hasDelivery) {
      if(isSignedIn) {
        Object.keys(data.delivery).forEach((key) => {
          payloadData.delivery[key] = user[key];
        });
      }
      else {
        Object.keys(data.delivery).forEach((key) => {
          payloadData.delivery[key] = payloadData.user[key];
        });
      }
    }

    let creditCard = localStorage.getItem('AUTHORIZE_NET_INFO');
    if(creditCard) {
      localStorage.removeItem('AUTHORIZE_NET_INFO');
      payloadData.creditCard = JSON.parse(creditCard);
    }

    if(hasDelivery) {
      payloadData.delivery.deliveryProvider = deliveryProvider;
    }

    if(paymentMethodType === "AFFIRM") {
      openAffirmCheckout(payloadData);
    }
    else {
      if (isSignedIn) {
        dispatch({
          type: paymentTypes.BUY_PLAN_AUTHORIZED,
          payload: payloadData,
          navigate,
          user,
          checkout: !!checkout
        });
      } else {
        dispatch({
          type: paymentTypes.BUY_PLAN,
          payload: payloadData,
          navigate,
          user: payloadData.user,
          checkout: !!checkout
        });
      }
    }
  };

  const handleSwitch = (event) => {
    const { checked } = event.target;

    if (checked) {
      methods.clearErrors('delivery');
      setDeliveryAuto(true);
      setBillingAddress(methods.getValues('user'));
    } else {
      setDeliveryAuto(false);
    }
  };

  const openAffirmCheckout = async (payloadData) => {
    dispatch(startLoading('buyPlansLoading'));
    const products = getBasketItems();

    const affirmCheckoutResponse = await (isSignedIn ? generateUserCheckoutRequest : generateCheckoutRequest)({
      savedOrderId: window.localStorage.getItem('AFFIRM_ORDER_ID'),
      payloadData,
      products: products.map(item => {
        if(item.type === 'hardware') {
          return {
            type: item.type,
            hardwareBasketId: item.hardwareBasketId,
            count: item.count
          };
        }
        return item;
      }),
      redirectSuccessUrl: `${window.location.origin}${routes.paymentSuccess}`,
      redirectFailUrl: `${window.location.origin}${routes.checkout}`,
      hardwareItemUrl: `${window.location.origin}${routes.hardwares.base}${routes.hardwares.cellPhoneDetail}`,
      planItemUrl: `${window.location.origin}${routes.plans}`
    });
    const affirmCheckout = affirmCheckoutResponse.data;
    window.localStorage.setItem('AFFIRM_ORDER_ID', affirmCheckout.order_id.toString());
    window.affirm.checkout(affirmCheckout);
    window.affirm.checkout.open({
      onFail: () => dispatch(stopLoading('buyPlansLoading')),
      onOpen: () => dispatch(stopLoading('buyPlansLoading')),
      onValidationError: () => dispatch(stopLoading('buyPlansLoading'))
    });
  }

  if(isBasketEmpty) {
    return null;
  }

  return (
    <div className="billing-form">
      <FormProvider {...methods}>
        <form
          autoComplete="on"
          className="billing-form__form"
          onSubmit={methods.handleSubmit(handleUserInfo)}
        >
          <div className="billing-form__row">
            {!isSignedIn
            && (
            <>
              <div className="billing-form__form-container user">
                <div className="billing-form__form-header-container">
                  <h3 className="billing-form__form-header">Create Account</h3>
                </div>
                <div className="billing-form__form-content">
                  <UserInfoForm parentName="user" isBillingUserInfo onlyCreateAccount={true} />
                </div>
              </div>
              <div className="billing-form__form-container user">
                <div className="billing-form__form-header-container">
                  <h3 className="billing-form__form-header">Billing Address</h3>
                </div>
                <div className="billing-form__form-content">
                  <UserInfoForm parentName="user" isBillingUserInfo />
                  {hasDelivery
                      && (
                          <Checkbox
                              onChange={(event) => handleSwitch(event)}
                              addClass="billing-form__ship-to-address"
                              label="Ship to billing address"
                              checked={isDeliveryAuto}
                          />
                      )}
                </div>
              </div>
            </>
            )}
            {(hasDelivery && isSignedIn || hasDelivery && !isDeliveryAuto) && (
                <div className={deliveryContentClasses}>
                  <div className="billing-form__form-header-container">
                    <h3 className="billing-form__form-header">Shipping Address</h3>
                  </div>
                  <DeliveryInfoForm
                    parentName="delivery"
                    addressData={(isDeliveryAuto && !isSignedIn) ? billingAddress : null}
                    wide={isSignedIn}
                  />
                </div>
              )}
          </div>
          <SimsOutErrors errors={errors} plans={plans} />
          <button
              className="billing-form__submit"
              type="submit"
              style={{display: checkout ? "none" : "block"}}
          >
            NEXT STEP
          </button>
        </form>
      </FormProvider>
    </div>
  );
};

BillingForm.defaultProps = {
  onSubmit: () => {},
};

BillingForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default BillingForm;
