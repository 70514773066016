import React,{ useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Carousel from '../carousel/carousel';

function Instagram({token, ...props}) {

    const [feeds, setFeedsData] = useState([])
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    tokenProp.current = token;

    useEffect(() => {
      // this is to avoid memory leaks
      const abortController = new AbortController();

      async function fetchInstagramPost () {
        try{
          axios
              .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,caption&limit=${props.limit}&access_token=${tokenProp.current}`)
              .then((resp) => {
                  setFeedsData(resp.data.data)
              })
        } catch (err) {
        }
      }

      // manually call the fecth function
      fetchInstagramPost();

      return () => {
          // cancel pending fetch request on component unmount
          abortController.abort();
      };
    }, [props.limit])

    return (
        <Carousel feeds={feeds} />
    );
}
export default Instagram;
