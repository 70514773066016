import './account-screen-hardware-detail.scss';
import React from 'react';
import Spinner from '../../../components/ui-component/spinner/spinner';
import Tracker from '../../../components/account/delivery/tracker/tracker';
import { useSelector } from 'react-redux';

export default function AccountScreenHardwareDetail() {
  const isCurrentProductLoading = useSelector(
    (state) => state.loadingReducer.currentProductLoading
  );
  const currentProduct = useSelector((state) => state.accountReducer.currentProduct);

  function format(date) {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    return `${d.getDate()}/${
      d.getMonth() + 1
    }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
  }

  if (isCurrentProductLoading || !currentProduct) {
    return (
      <div className="ashd--container">
        <div className="ashd--spinner">
          <Spinner />
        </div>
      </div>
    );
  }

  const invoiceNumber =
    currentProduct.payData?.authorizeNetInvoiceNumber || currentProduct.payData?.userPaysId;
  return (
    <div className="ashd--container">
      <h2 className="ashd--title">
        #{invoiceNumber} {currentProduct.title}
        {currentProduct.count > 1 ? ` (${currentProduct.count})` : ''}
      </h2>

      <div className="ashd--content">
        <div className="ashd--content--left">
          <div className="ashd--content-items">
            <div className="ashd--content-item">
              <div className="ashd--content-item--title">Bought at</div>
              <div className="ashd--content-item--value">
                {format(currentProduct.createdAt, 'MM.dd.yyyy hh:mm')}
              </div>
            </div>
            <div className="ashd--content-item">
              <div className="ashd--content-item--title">Count</div>
              <div className="ashd--content-item--value">{currentProduct.count}</div>
            </div>
            <div className="ashd--content-item">
              <div className="ashd--content-item--title">Color</div>
              <div className="ashd--content-item--value">
                <span className="hw-color" style={{ backgroundColor: currentProduct.colorHex }} />
                {currentProduct.color}
              </div>
            </div>
            <div className="ashd--content-item">
              <div className="ashd--content-item--title">Paid price</div>
              <div className="ashd--content-item--value">${currentProduct.paidPrice}</div>
            </div>
            <div className="ashd--content-item">
              <div className="ashd--content-item--title">Storage</div>
              <div className="ashd--content-item--value">{currentProduct.storage}</div>
            </div>
            <div className="ashd--content-item">
              <div className="ashd--content-item--title">Condition</div>
              <div className="ashd--content-item--value">{currentProduct.condition}</div>
            </div>
          </div>
        </div>
        <div className="ashd--content--right">
          <div
            className="ashd--content-image"
            style={{ backgroundImage: `url(${currentProduct.imageUrl})` }}
          />
        </div>
      </div>

      <Tracker />
    </div>
  );
}
