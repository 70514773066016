import './hardware-listing-product-item.scss';
import React, {useEffect, useMemo, useState} from "react";
import routes from "../../../../navigation/routes";
import {Link} from "react-router-dom";
import DummyPhoneImage from "../../../../assets/images/hardwares/dummy-phone.png";

export default function HardwareListingProductItem({product}) {
    const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);

    const productDetailUrl = useMemo(() => {
        return `${routes.hardwares.base}${routes.hardwares.cellPhones}/${product.slugUrl}-${product.id}`;
    }, [product.id]);

    const activeThumbnail = useMemo(() => {
        return product.HardwareVariants?.[activeThumbnailIndex];
    }, [product.id, activeThumbnailIndex]);

    const storage = useMemo(() => {
        return product.HardwareVariants?.[activeThumbnailIndex]?.conditions?.[0]?.storages?.[0];
    }, [product.id, activeThumbnailIndex]);

    const price = (storage?.hasDiscount ? storage.discountedPrice : storage?.price) || 0;
    useEffect(() => {
        if(price) {
            setTimeout(() => window.affirm.ui.refresh(), 100);
        }
    }, [price]);

    return (
        <Link to={productDetailUrl} className="hwl-product-item">
            <div className="hwl-product-item--top-tags">
                {product.topTags?.map((topTag, topTagIdx) => (
                    <div className="hwl-product-item--top-tag" key={topTagIdx.toString()}>
                        {topTag.label}
                    </div>
                ))}
            </div>
            <div className="hwl-product-item--thumbnail">
                <div className="hwl-product-item--thumbnail-img" style={{ backgroundImage: `url(${activeThumbnail?.images?.[0]?.imageUrl || DummyPhoneImage})` }} />
            </div>
            <div className="hwl-product-item--brand">
                {product.brandName}
            </div>
            <div className="hwl-product-item--title">
                {product.title}
            </div>
            {product.HardwareVariants?.length > 0 ? (
                <div className="hwl-product-item--colors">
                    {product.HardwareVariants.map((variant, tIdx) => (
                        <div key={tIdx}
                             className={`hwl-product-item--colors--color ${activeThumbnail.color === variant.color ? 'active' : ''}`}
                             onClick={(ev) => {
                                 ev.preventDefault();
                                 setActiveThumbnailIndex(tIdx);
                             }}
                        >
                            <div style={{backgroundColor: variant.color}} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="hwl-product-item--colors-empty" />
            )}
            <div className="hwl-product-item--price">
                {storage?.hasDiscount ? (
                    <>
                        <span className="hwl-product-item--price--discount">
                            ${storage?.price}
                        </span>
                        ${storage?.discountedPrice}
                    </>
                ) : `$${storage?.price}` }
            </div>
            <div className="hwl-product-item--monthly">
                <p className="affirm-as-low-as" data-page-type="search" data-amount={price * 100}></p>
            </div>
        </Link>
    );
}
