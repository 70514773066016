import './hardware-listing-search.scss';
import { useContext, useState } from 'react';
import { HardwareListingContext } from '../../../../contexts/hardware-listing-context/hardware-listing-context';
import SearchIcon from '../../../../assets/images/hardwares/search.svg';
import { HardwareListingSortTypes } from '../../../../types/hardwareListingSortTypes';
import Dropdown from '../dropdown/dropdown';
import SlidersAltIcon from '../../../../assets/images/hardwares/sliders-alt.svg';
import ExitIcon from '../../../../assets/images/hardwares/exit.svg';
import HardwareListingFilters from '../hardware-listing-filters/hardware-listing-filters';

export default function HardwareListingSearch() {
  const { searchQuery, onSearchChange, sortBy, onSortChange, isMobile } =
    useContext(HardwareListingContext);
  const [showFilters, setShowFilters] = useState(false);

  const sortables = [
    { id: HardwareListingSortTypes.DATE_DESC, label: 'Latest Phones' },
    { id: HardwareListingSortTypes.POPULAR, label: 'Popular' },
    { id: HardwareListingSortTypes.PRICE_ASC, label: 'Price: Low to high' },
    { id: HardwareListingSortTypes.PRICE_DESC, label: 'Price: High to low' },
    { id: HardwareListingSortTypes.ALPHABET, label: 'By alphabet' },
    { id: HardwareListingSortTypes.DISCOUNT, label: 'Discount' },
  ];
  const selectedSort = sortables.find((m) => m.id === sortBy);

  return (
    <>
      <div className={`hwl-search ${isMobile ? 'mobile' : ''}`}>
        <div className="hwl-search--query">
          <img src={SearchIcon} className="hwl-search--query--search-icon" alt="Search" />
          <input
            className="hwl-search--query--search-input"
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
        <div className="hwl-search--filter-sort">
          {isMobile && (
            <div className="hwl-search--filter">
              <div className="hwl-search--filter--button" onClick={() => setShowFilters(true)}>
                Filter <img src={SlidersAltIcon} alt="filter" />
              </div>
            </div>
          )}
          <div className="hwl-search--sort">
            <Dropdown
              label={selectedSort.label}
              onClickItem={(sort) => onSortChange(sort.id)}
              options={sortables}
            />
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={`hwl-search-filter-modal ${showFilters ? 'active' : ''}`}>
          <div className="hwl-search-filter-modal--content">
            <div className="hwl-search-filter-modal--close" onClick={() => setShowFilters(false)}>
              <img src={ExitIcon} alt="exit" />
            </div>
            <HardwareListingFilters />
          </div>
          <div
            className="hwl-search-filter-modal--close-side"
            onClick={() => setShowFilters(false)}
          />
        </div>
      )}
    </>
  );
}
