import Input from '../../../ui-component/input/input';
import AccountEditSection from '../section/section';
import PropTypes from 'prop-types';
import React from 'react';
import './contact-details.scss';
import usStates from "../../../../../assets/data/us-states.json";
import Select from "../../../ui-component/select/select";

const AccountEditContactDetails = ({ register }) => (
  <AccountEditSection title="Contact details">
    <div className="account-screen-form__wrapper">
      <Input
        type="text"
        placeholder="Enter street address"
        label="Street address"
        containerClass="account-screen-edit__input"
        {...register('street')}
      />
      <Input
        type="text"
        placeholder="Enter town/city"
        label="Town / City"
        containerClass="account-screen-edit__input"
        {...register('city')}
      />
      <Select
          {...register('country')}
          containerClass="account-screen-edit__input"
          placeholder="Select state"
          label="State"
          options={usStates.map(m => ({
            id: m.abbreviation,
            displayName: `${m.state} - ${m.abbreviation}`
          }))}
      />
      <Input
        type="text"
        placeholder="Enter apartment, suite, unit"
        label="Apartment, suite, unit"
        containerClass="account-screen-edit__input"
        {...register('apartment')}
      />
      <Input
        type="text"
        placeholder="Enter Zip"
        label="Zip"
        containerClass="account-screen-edit__input"
        {...register('zip')}
      />
    </div>
  </AccountEditSection>
);

AccountEditContactDetails.propTypes = {
  register: PropTypes.func,
};

export default AccountEditContactDetails;
