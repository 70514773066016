import basketTypes from '../workers/basket/basket-types';

const initialState = {
  totalPrice: 0,
  totalSimPrice: 0,
  totalCount: 0,
  totalLines: 0,
  totalHardwares: 0,
  hasDelivery: false,
  coupon: null,
  paymentType: '',
  paymentMethodType: 'CARD',
  deliveryProvider: {key: 'FREE', label: 'Free Delivery (3 - 5 days)', price: 0},
  deliveryZip: '',
  userZip: '',
  tax: null
};

const basketReducer = (state = initialState, action) => {
  switch (action.type) {
    case basketTypes.BASKET_UPDATE:
      return { ...state, ...action.payload };

    case basketTypes.SET_COUPON:
      return { ...state, coupon: action.payload };

    default:
      return state;
  }
};

export default basketReducer;
