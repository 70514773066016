import './hardware-listing-banner.scss';
import routes from "../../../../navigation/routes";
import chevronRight from "../../../../assets/images/icons/chevron-right.svg";
import messageVector from "../../../../assets/images/icons/message-vector.svg";
import MiddleBannerImage from "../../../../assets/images/hardwares/hardware-listing-middle-banner.png";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function HardwareListingBanner() {
    const navigate = useNavigate();

    return (
        <div className="hwl-products--banner">
            <div className="hwl-products--banner--wrapper">
                <div className="hwl-products--banner--title">
                    Stay Protected<br />with Go talk
                </div>
                <div className="hwl-products--banner--subtitle">
                    We provide you with a secure line and a handful set of tools. <br />
                    <span className="bold">Starting from $15</span>
                    <br />
                    <br />
                    <span className="bold">Free VPN & Anti Phishing</span> Tools Included *
                </div>
                <button type="button" className="hpb-button" onClick={() => navigate(routes.plans)}>
                    Find a Plan <img src={chevronRight} className="hpb-button--icon" />
                    <img src={messageVector} className="hpb-button--vector" />
                </button>
            </div>

            <img className="hwl-products--banner--background" src={MiddleBannerImage} alt="Stay Protected with Go talk" />
        </div>
    );
}
