import routes from '../../../../navigation/routes';
import basketTypes from '../../../../redux/workers/basket/basket-types';
import actionsType from '../../../../redux/workers/main-page/actions-type';
import { addToBasket, getBasketItems } from '../../../../shared/basketActions';
import BasketSuccessPopup from '../../ui-component/basket-success-popup/basket-success-popup';
import Button from '../../ui-component/button/button';
import Card from '../../ui-component/card/card';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './tariffs.scss';
import { useNavigate } from 'react-router-dom';
import PlanCard from "../../ui-component/plan-card/plan-card";
import {createDispatchLog} from "../../../../redux/workers/conversion-tracking";
import {ConversionTrackingActionNames} from "../../../../types/conversionTrackingActionNames";
import {PlanFilterPageType, useFilteredPlansByPlaceType} from "../../../../hooks/useFilteredPlansByPlaceType";

const Tariffs = () => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plans = useFilteredPlansByPlaceType({
    popular: true,
    pageType: PlanFilterPageType.HOME
  });

  const clickCard = (id) => {
    dispatch({ type: actionsType.LOAD_CURRENT_PLAN, id });
    navigate(`${routes.tariff}/${id}`);
  };

  const handleBasketAddition = (id) => {
    if (id) {
      addToBasket(id);
      setShowPopup(true);
      dispatch({
        type: basketTypes.BASKET_UPDATE,
        payload: { totalCount: getBasketItems().length },
      });
      dispatch(createDispatchLog(ConversionTrackingActionNames.BASKET, {
        actionDataType: 'BASKET_UPDATE',
        data: {
          items: getBasketItems()
        }
      }));
    }
  };

  const renderCardPlan = () => plans.map(plan => <PlanCard key={plan.id} plan={plan} onBuyClick={() => handleBasketAddition(plan.id)} />);

  const handleNavigation = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    navigate(routes.plans);
  };

  return (
    <div className="tariffs-container">
      <h2 className="header-two">Popular <span>SafeSim&trade;</span> Plans</h2>
      <div className="tariffs__card">{renderCardPlan()}</div>
      <Button onClick={handleNavigation} title="VIEW ALL" addClass="tariffs__button" />
      {showPopup && (
        <BasketSuccessPopup
          close={() => setShowPopup(false)}
          onContinue={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default Tariffs;
