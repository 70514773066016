import './detail-page.scss';
import HardwareDetailContextProvider, {
  HardwareDetailContext,
} from '../../../../contexts/hardware-detail-context/hardware-detail-context';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Spinner from '../../../components/ui-component/spinner/spinner';
import HardwareDetailImages from '../../../components/hardwares/hardware-detail-images/hardware-detail-images';
import HardwareDetailSideInfos from '../../../components/hardwares/hardware-detail-side-infos/hardware-detail-side-infos';
import HardwareDetailTabbedInfos from '../../../components/hardwares/hardware-detail-tabbed-infos/hardware-detail-tabbed-infos';
import HardwareDetailSimilarProducts from '../../../components/hardwares/hardware-detail-similar-products/hardware-detail-similar-products';

function HardwaresDetailPage() {
  const { productDetail, loading, isMobile } = useContext(HardwareDetailContext);

  if (loading || !productDetail) {
    return (
      <>
        <Helmet>
          <title>Gotalk - Cell Phone</title>
        </Helmet>
        <div className="hwd-container">
          <div className="hwd-spinner">
            <Spinner />
          </div>
        </div>
      </>
    );
  }

  const metaTitle =
    productDetail.title?.toLowerCase().indexOf('unlocked') > -1
      ? productDetail.title
      : `${productDetail.title} Unlocked`;
  const metaDescription = `Buy the ${metaTitle} from Go Talk Wireless, featuring the latest ${productDetail.brandName} technology. Available with fast US and worldwide delivery.`;
  const metaKeywords = `${productDetail.brandName},${metaTitle},unlocked,buy,${productDetail.brandName},${metaTitle},us,delivery,unlocked,buy,${productDetail.brandName},${metaTitle},unlocked`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <div className="hwd-container">
        <div className="hwd-sides">
          <div className="hwd-sides--left">
            <HardwareDetailImages />
          </div>
          <div className="hwd-sides--right">
            <HardwareDetailSideInfos />
          </div>
        </div>
        <div className="hwd-tabbed-infos-container">
          <HardwareDetailTabbedInfos />
        </div>
        <div className="hwd-similar-products-container">
          <HardwareDetailSimilarProducts />
        </div>
      </div>
    </>
  );
}

export default function HardwaresDetailPageWrapper() {
  return (
    <HardwareDetailContextProvider>
      <HardwaresDetailPage />
    </HardwareDetailContextProvider>
  );
}
