import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {calculateTaxes} from "../../../redux/workers/taxes";
import basketTypes from "../../../redux/workers/basket/basket-types";
import './basket-taxes-surcharges.scss';

let lastControlledZip = '';
export default function BasketTaxesSurcharges({ checkout, discountAmount }) {
    const { userZip, hasDelivery, totalPrice, totalLines, totalHardwares, tax } = useSelector((state) => state.basketReducer);
    const { isSignedIn, user } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showTaxes, setShowTaxes] = useState(false);

    useEffect(() => {
        controlTaxes();
    }, [isSignedIn, user, hasDelivery, userZip, discountAmount]);

    useEffect(() => {
        controlTaxes(true);
    }, [discountAmount]);

    function controlTaxes(discountAmountChanged) {
        if(!checkout) {
            return;
        }

        const zip = (isSignedIn ? user?.zip?.toString() : userZip?.toString()) || '';

        if(zip === lastControlledZip && !discountAmountChanged) {
            return;
        }

        if(!zip?.length || zip.length !== 5) {
            return;
        }

        lastControlledZip = zip.toString();
        setLoading(true);
        calculateTaxes({
            sumPrice: totalPrice - discountAmount,
            simCount: totalLines,
            hardwareCount: totalHardwares,
            zipCode: zip
        }).then(res => {
            dispatch({
                type: basketTypes.BASKET_UPDATE,
                payload: {
                    tax: res?.data?.payload || null
                }
            });
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className="tax--container">
            <div className="basket__price-row" onClick={() => setShowTaxes(s => !s)}>
                <div className="basket__price-col">Taxes and Surcharges:</div>
                <div className="basket__price-col sum">{tax ? `$${tax.taxSum}` : '-'}</div>
            </div>
            {(showTaxes && tax?.calculatedTaxes?.length) ? (
                <div className="tax--taxes">
                    {tax.calculatedTaxes.map((item, idx) => (
                        <div className="tax--tax-item" key={idx}>
                            <div className="tax--tax-item-col">{item.title}:</div>
                            <div className="tax--tax-item-col">${item.calculatedPrice}</div>
                        </div>
                    ))}
                    {tax.calculatedTaxes.map((item, idx) => (
                        <div className="tax--tax-item" key={idx}>
                            <div className="tax--tax-item-col">{item.title}:</div>
                            <div className="tax--tax-item-col">${item.calculatedPrice}</div>
                        </div>
                    ))}
                    {tax.calculatedTaxes.map((item, idx) => (
                        <div className="tax--tax-item" key={idx}>
                            <div className="tax--tax-item-col">{item.title}:</div>
                            <div className="tax--tax-item-col">${item.calculatedPrice}</div>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}
