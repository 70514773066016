import './accordion.scss';
import useClassnames from '../../../../shared/hooks/useClassnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

const Accordion = ({
  title, content, className,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={`${useClassnames('accordion', className)} ${isOpen ? 'open' : ''}`}
             onClick={() => setIsOpen(o => !o)}>
            <summary className="accordion__summary">{title}</summary>
            <p className="accordion__details" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    );
}

Accordion.defaultProps = {
  title: '',
  content: '',
};

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Accordion;
