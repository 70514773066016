import conversionTrackingTypes from "../workers/conversion-tracking/conversion-tracking-types";
import {logConversionTracking, logUserConversionTracking} from "../workers/conversion-tracking";
import { call, takeLatest, put, select } from 'redux-saga/effects';
import actionsType from "../workers/main-page/actions-type";

function* logConversionTrackingSaga(params) {
    try {
        const state = yield select();
        const isSignedIn = !!localStorage.getItem('got-accessToken');
        const { data } = yield call(isSignedIn ? logUserConversionTracking : logConversionTracking, {
            actionName: params.payload.actionName,
            actionData: params.payload.actionData,
            pageUrl: window.location.href,
            referrer: localStorage.getItem('user_referrer') || '',
            followId: state.mainReducer.followId || null,
        });

        yield put({
            type: actionsType.FOLLOW_ID,
            payload: data.payload.followId
        });
    }
    catch {}
}

export default function* conversionTrackingWatcher() {
    yield takeLatest([conversionTrackingTypes.LOG_CONVERSION_TRACKING], logConversionTrackingSaga);
}
