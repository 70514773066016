import React, {useEffect, useMemo, useRef, useState} from "react";
import Spinner from "../../../components/ui-component/spinner/spinner";
import Button from "../../../components/ui-component/button/button";
import {NavLink, useLocation} from "react-router-dom";
import routes from "../../../../navigation/routes";
import ManageCardPopup from "../manage-card-popup/manage-card-popup";
import {getPaymentInformation, makePaymentForExpiredSim} from "../../../../redux/workers/account";
import {toast} from "react-toastify";

export default function PaymentInformation() {

    const { search } = useLocation();
    const id = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('id') || 0;
    }, [search]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const manageCardPopupRef = useRef();

    useEffect(() => {
        if(id > 0) {
            initData();
        }
    }, [search]);

    function initData() {
        setLoading(true);
        getPaymentInformation(id).then(res => {
            setLoading(false);
            setData(res.data);
        }, err => {
            setLoading(false);
        })
    }

    function manageCard() {
        manageCardPopupRef.current?.showPopup();
    }

    const nextPaymentDate = useMemo(() => {
        if(!data?.nextPaymentDate) {
            return '';
        }
        const d = new Date(data.nextPaymentDate);
        return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`;
    }, [data?.nextPaymentDate]);

    const simExpired = useMemo(() => {
        if (!data?.nextPaymentDate) {
            return false;
        }
        const d = new Date(new Date(data.nextPaymentDate).setHours(0, 0, 0, 0));
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        return d.getTime() < today.getTime();
    }, [data?.nextPaymentDate]);

    function makePayment() {
        setLoading(true);
        makePaymentForExpiredSim({
            userSimPlanId: id
        }).then(res => {
            const {message, success} = res.data.payload;
            if(success) {
                initData();
                toast.success(message || "", {
                    position: "top-right"
                });
            } else {
                toast.error(message || "", {
                    position: "top-right"
                });
            }
        }, err => {
            toast.error(err.response?.data?.payload?.message || "", {
                position: "top-right"
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    if(loading) {
        return (
            <div className="account-sidebar__spinner-container">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            {(data?.simStatus === 'blocked_paid' || data?.simStatus === 'blocked') && (
                <div className="account-sidebar__item__title" style={{
                    textAlign: 'center'
                }}>
                    SIM BLOCKED
                </div>
            )}
            {(data?.simStatus === 'not_active') && (
                <div className="account-sidebar__item__title" style={{
                    textAlign: 'center'
                }}>
                    SIM NOT ACTIVE
                </div>
            )}
            <div className="account-sidebar__item__title">
                Next Payment
                <span>${data?.nextPaymentPrice}</span>
            </div>
            <div className="account-sidebar__item__sub">
                Due Date
                <span>{nextPaymentDate}</span>
            </div>
            {data?.hasCard && (
                <>
                    <div className="account-sidebar__item__card">
                        Using card ending <b className="account-sidebar__item__bold">{data?.last4}</b>
                    </div>
                    {simExpired && (
                        <div className="account-sidebar__item__payment">
                            <Button
                                addClass="account-sidebar__item__button"
                                onClick={() => makePayment()}
                                title="Make Payment"
                            />
                        </div>
                    )}
                </>
            )}
            <Button
                addClass="account-sidebar__item__button"
                onClick={() => manageCard()}
                title={data?.hasCard ? 'Update Card' : 'Add Card'}
            />
            <div className="text-center">
                <NavLink className="account-sidebar__item__link" to={`${routes.account.history.payment}?id=${id}`}>
                    VIEW PAYMENT HISTORY
                </NavLink>
            </div>
            <ManageCardPopup ref={manageCardPopupRef} />
        </>
    );
}
