import { ReactComponent as MockQrCode } from '../../../../../assets/images/account/mock-qr-code.svg';
import AccountTariffInfoPlanCard from '../../../../components/account/tariff-info/plan-card/plan-card';
import classNames from 'classnames';
import React, {useMemo, useState} from 'react';
import './transfer-device.scss';
import {useLocation} from "react-router-dom";
import Loader from "../../../../components/loader/loader";
import {changeToNewDeviceGetQr} from "../../../../../redux/workers/account";

const AccountTariffInfoTransferDevice = ({ addClass }) => {

  const classes = classNames('account-tariff-info-transfer-device', addClass);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState('');

  const { search } = useLocation();
  const id = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('id') || 0;
  }, [search]);

  function renderQR() {
    if(loading) {
      return <Loader />
    }
    if(data) {
      return <img src={`data:image/png;base64,${data}`} className="account-tariff-info-show-qr-wrapper__img" />;
    }
    return <MockQrCode />;
  }

  function changeToNewDevice() {
    setLoading(true);
    setData('');
    changeToNewDeviceGetQr({
      userSimPlanId: id
    }).then(res => {
      setData(res.data?.payload?.qr || '');
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <div className={classes}>
      <AccountTariffInfoPlanCard addClass="account-tariff-info-transfer-device__wrapper">
        <h6 className="account-tariff-info-transfer-device__title">
          Transfer To A New Device
        </h6>

        <div className="account-tariff-info-transfer-device__code-wrapper">
          {renderQR()}
        </div>

        <button className="account-tariff-info-transfer-device__link-generate" onClick={changeToNewDevice}>
          GENERATE QR-CODE
        </button>
      </AccountTariffInfoPlanCard>
    </div>
  );
};

export default AccountTariffInfoTransferDevice;
