import './private-wireless-banner.scss';
import AbcNewsImage from '../../../../assets/images/private-wireless/abc-news.png';
import UsaTodayImage from '../../../../assets/images/private-wireless/usa-today.png';
import YahooFinanceImage from '../../../../assets/images/private-wireless/yahoo-finance.png';
import IosScreenImage from '../../../../assets/images/private-wireless/ios-screen.png';

export default function PrivateWirelessBanner() {
    return (
      <div className="pw-banner">
        <div className="pw-banner--left">
            <div className="pw-banner--content-wrapper">
                <div className="pw-banner--content">
                    <h1 className="pw-banner--title">
                        Private Wireless – Bridge your <span>Go Talk</span> wireless service using private infrastructure
                    </h1>
                    <p className="pw-banner--subtitle">We will bridge your use between a national 99% coverage and a zone that you want to deploy private wireless coverage</p>
                    <div>
                        <button className="pw-banner--button" type="button">
                            CONNECT ME
                        </button>
                    </div>
                </div>
            </div>

            <div className="pw-banner--recommend">
                <span>RECOMMENDED BY</span>
                <img className="pw-banner--rec-image" src={AbcNewsImage} alt="Abc News" />
                <img className="pw-banner--rec-image" src={UsaTodayImage} alt="Usa Today" />
                <img className="pw-banner--rec-image" src={YahooFinanceImage} alt="Yahoo Finance" />
            </div>
        </div>
          <div className="pw-banner--right">
              <img className="pw-banner--ios-screen" src={IosScreenImage} alt="Ios Screen" />
          </div>
      </div>
    );
}
