import './home-present-block.scss';
import {useSelector} from "react-redux";
import React, {useMemo} from "react";
import messageVector from "../../../../assets/images/icons/message-vector.svg";
import chevronRight from "../../../../assets/images/icons/chevron-right.svg";
import {useNavigate} from "react-router-dom";
import routes from "../../../../navigation/routes";

export default function HomePresentBlock() {

    const isBusinessMode = useSelector((state) => state.mainReducer.isModeBusiness);
    const generalSettings = useSelector(state => state.generalSettingsReducer.generalSettings);
    const navigate = useNavigate();

    function goToPlanPage() {
        navigate(routes.plans);
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    const info = useMemo(() => {
        if(!generalSettings) {
            return null;
        }

        let imageUrl = generalSettings.homeConsumerBgImage;
        if(isBusinessMode) {
            imageUrl = generalSettings.homeBusinessBgImage;
        }

        return {
            imageUrl
        };
    }, [generalSettings]);

    function renderBannerVideo() {
        let show = !!generalSettings?.homeConsumerShowVideo;
        let videoLink = generalSettings?.homeConsumerVideoLink;
        let videoTitle = generalSettings?.homeConsumerVideoTitle;
        if(isBusinessMode) {
            show = !!generalSettings?.homeBusinessShowVideo;
            videoLink = generalSettings?.homeBusinessVideoLink;
            videoTitle = generalSettings?.homeConsumerVideoTitle;
        }

        if(!show) {
            return null;
        }

        return (
            <div className="banner-video-container">
                <div className="banner-video-title">
                    {videoTitle}
                </div>
                <iframe width="560" height="315" src={videoLink}
                        title={videoTitle} frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="banner-video">
                </iframe>
            </div>
        );
    }

    return (
        <div className="hpb-container" style={{
            backgroundImage: `url(${info?.imageUrl})`
        }}>
            <div className="hpb-container--inner-view">
                <div className="hpb-container--inner-view--texts">
                    <div className="hpb-text-1">
                        Stay Protected
                        with <span>Go talk</span>
                    </div>
                    <div className="hpb-pills">
                        <div className="hpb-pill-item">
                            Premium Wireless
                        </div>
                        <div className="hpb-pill-item">
                            99% US Coverage
                        </div>
                    </div>
                    <div className="hpb-text-2">
                        We provide you with a secure line and
                        a handful set of tools. Starting from <span>$15</span>
                    </div>
                    <div className="hpb-text-3">
                        <span>Free VPN & Anti Phishing</span> Tools Included <sup>*</sup>
                    </div>
                    <div className="hpb-text-4">
                        <span>*</span> Available in our iOS & Google Play Store Apps. Desktop VPN Apps included. T&Cs apply.
                    </div>
                    <button type="button" className="hpb-button" onClick={() => goToPlanPage()}>
                        Find a Plan <img src={chevronRight} className="hpb-button--icon" />
                        <img src={messageVector} className="hpb-button--vector" />
                    </button>
                </div>
            </div>
            {renderBannerVideo()}
        </div>
    );
}
