import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import './static-pages.scss';
import {STATIC_PAGES} from "../../../axios/api-urls";
import Spinner from "../../components/ui-component/spinner/spinner";
import Api from "../../../axios/api";
import routes from "../../../navigation/routes";
import NavigationBack from "../../components/ui-component/navigation-back/navigation-back";
import FaqList from "../../components/faq-list/faq-list";
import {Helmet} from "react-helmet";
import MapBlock from "../../components/main-page-section/map-block/map-block";

const api = new Api();

const StaticPage = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData(pathname);
    }, [pathname]);

    function loadData(pathname) {
        setLoading(true);
        let requestUrl = "";
        if(pathname.startsWith(routes.termsConditions)) {
            requestUrl = STATIC_PAGES.getTermsAndConditions;
        }
        else if(pathname.startsWith(routes.acceptable)) {
            requestUrl = STATIC_PAGES.getAcceptableUsePolicy;
        }
        else if(pathname.startsWith(routes.privacy)) {
            requestUrl = STATIC_PAGES.getPrivacyPolicy;
        }
        else if(pathname.startsWith(routes.returnPolicy)) {
            requestUrl = STATIC_PAGES.getReturnPolicy;
        }
        else if(pathname.startsWith(routes.faq)) {
            requestUrl = STATIC_PAGES.getFAQ;
        }
        else if(pathname.startsWith(routes.worksNew) || pathname.startsWith(routes.works)) {
            requestUrl = STATIC_PAGES.getWhyChangeCarrier;
        }
        else if(pathname.startsWith(routes.protect)) {
            requestUrl = STATIC_PAGES.getFraudProtection;
        }
        else if(pathname.startsWith(routes.support)) {
            requestUrl = STATIC_PAGES.getSupport;
        }
        api.get(requestUrl)
            .then(res => setData(res.data.payload))
            .finally(() => setLoading(false));
    }

    if(!data || loading) {
        return (
            <section className="section-information-pages">
                <Spinner />
            </section>
        );
    }

    return (
        <>
            <Helmet>
                <title>{data.metaTitle}</title>
                <meta name="description" content={data.metaDescription} />
                <meta name="keywords" content={data.metaKeywords} />
            </Helmet>
            <section className="section-information-pages">
                <NavigationBack to={routes.home} />
                <h2 className="information-pages__title">{data.title}</h2>
                {data.pageType === 'FAQ' && (
                    <FaqList questions={data.questions || []} />
                )}
                <p className="information-pages__paragraph" dangerouslySetInnerHTML={{
                    __html: data.htmlContent
                }} />
                {data.pageType === 'WHY_CHANGE_CARRIER' && <MapBlock />}
            </section>
        </>
    );
};
export default StaticPage;
