import React, {forwardRef, useImperativeHandle, useRef} from "react";
import useClassnames from "../../../../shared/hooks/useClassnames";

const Select = ({
   type,
   onChange,
   placeholder,
   label,
   description,
   disabled,
   isInvalid,
   containerClass,
   options,
   ...selectProps
}, ref) => {
    const selectRef = useRef(null);

    const descriptionClassNames = useClassnames('styled-input__description', isInvalid ? 'error' : '');
    const containerClassNames = useClassnames('styled-input', containerClass);
    const inputClassNames = useClassnames('styled-input__input', isInvalid ? 'error' : '');

    useImperativeHandle(ref, () => selectRef.current);

    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className={containerClassNames}>
            <div className="styled-input__label">
                {label}
            </div>
            <div className="styled-input__select-wrapper">
                <select
                    disabled={disabled}
                    ref={selectRef}
                    className={inputClassNames}
                    placeholder={placeholder}
                    onChange={(e) => handleChange(e)}
                    {...selectProps}
                >
                    <option value="" selected disabled>{placeholder}</option>
                    {options.map(opt => (
                        <option key={opt.id} value={opt.id}>{opt.displayName}</option>
                    ))}
                </select>
                <div className="styled-input__select-arrow" />
            </div>
            <p className={descriptionClassNames}>{description}</p>
        </div>
    );
}

export default forwardRef(Select);
