export default {
  SIGN_UP: 'SIGN_UP',
  SAVE_USER: 'SAVE_USER',
  SIGN_UP_FAIL: 'SIGN_UP_FAIL',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_IN_FAIL',
  SIGN_IN: 'SIGN_IN',
  CHECK_AUTH: 'CHECK_AUTH',
  SIGNED_IN: 'SIGNED_IN',
  LOGOUT: 'LOGOUT',
  MULTI_FACTOR_AUTH: 'MULTI_FACTOR_AUTH',
  RESTORE_PASSWORD: 'RESTORE_PASSWORD',
  RESTORE_PASSWORD_SUCCESS: 'RESTORE_PASSWORD_SUCCESS',
  RESTORE_PASSWORD_FAIL: 'RESTORE_PASSWORD_FAIL',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_FAIL: 'SET_NEW_PASSWORD_FAIL',
  RESET_ERRORS: 'RESET_ERRORS',
  OUTER_ACTIVATION_FAIL: 'OUTER_ACTIVATION_FAIL',
  OUTER_ACTIVATION_SUCCESS: 'OUTER_ACTIVATION_SUCCESS',
  OUTER_ACTIVATION: 'OUTER_ACTIVATION'
};
