import accountTypes from '../workers/account/account-types';

const initialState = {
  accountData: {},
  accountProduct: [],
  currentProduct: [],
  verificationEmailProgerss: {
    status: '',
    message: '',
  },
  verificationYubikeyProgress: {
    status: '',
    message: '',
  },
  verificationOtpProgress: {
    status: '',
    message: '',
  },
  activatedSim: [],
  changeNumber: [],
  activatedESim: [],
  qrActivate: '',
  errorMessage: '',
  activationMessage: '',
  isUserEligibleForVpn: false,
  vpnUser: null,
  vpnUserLoading: false,
  vpnAppLinks: [],
  vpnAppLinksLoading: false,
  resetVpnUserPasswordLoading: false,
  setVpnUserPasswordLoading: false,
  changePlanResponse: null,
  confirmChangePlanResponse: null,
  otpQr: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountTypes.SET_ACCOUNT_DATA:
      return { ...state, accountData: action.data };
    case accountTypes.GET_PRODUCT_DATA:
      return { ...state, accountProduct: action.payload };
    case accountTypes.GET_CURRENT_PRODUCT:
      return { ...state, currentProduct: action.payload };
    case accountTypes.SET_VERIFICATION_EMAIL_PROGERSS:
      return { ...state, verificationEmailProgerss: action.progress };
    case accountTypes.SET_VERIFICATION_YUBIKEY_PROGRESS:
      return { ...state, verificationYubikeyProgress: action.progress };
    case accountTypes.SET_VERIFICATION_OTP_PROGRESS:
      return { ...state, verificationOtpProgress: action.progress };
    case accountTypes.POST_ACTIVATE_SIM:
      return { ...state, activatedSim: action.payload, activationMessage: action.message };
    case accountTypes.POST_CHANGE_NUMBER:
      return { ...state, changeNumber: action.payload };
    case accountTypes.POST_ACTIVATE_ESIM:
      return { ...state, activatedESim: action.payload, activationMessage: action.message };
    case accountTypes.GET_QR:
      return { ...state, qrActivate: action.payload };
    case accountTypes.ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    case accountTypes.IS_USER_ELIGIBLE_FOR_VPN:
      return { ...state, isUserEligibleForVpn: action.payload };
    case accountTypes.VPN_USER:
      return { ...state, vpnUser: action.payload };
    case accountTypes.VPN_USER_LOADING:
      return { ...state, vpnUserLoading: action.payload };
    case accountTypes.VPN_APP_LINKS:
      return { ...state, vpnAppLinks: action.payload };
    case accountTypes.VPN_APP_LINKS_LOADING:
      return { ...state, vpnAppLinksLoading: action.payload };
    case accountTypes.RESET_VPN_USER_PASSWORD_LOADING:
      return { ...state, resetVpnUserPasswordLoading: action.payload };
    case accountTypes.SET_VPN_USER_PASSWORD_LOADING:
      return { ...state, setVpnUserPasswordLoading: action.payload };
    case accountTypes.CHANGE_PLAN_RESPONSE:
      return { ...state, changePlanResponse: action.payload };
    case accountTypes.CONFIRM_CHANGE_PLAN_RESPONSE:
      return { ...state, confirmChangePlanResponse: action.payload };
    case accountTypes.OTP_QR:
      return { ...state, otpQr: action.payload };
    default:
      return state;
  }
};

export default accountReducer;
