import './listing-page.scss';
import HardwareListingContextProvider from "../../../../contexts/hardware-listing-context/hardware-listing-context";
import {Helmet} from "react-helmet";
import HardwareListingFilters from "../../../components/hardwares/hardware-listing-filters/hardware-listing-filters";
import HardwareListingProducts from "../../../components/hardwares/hardware-listing-products/hardware-listing-products";
import HardwareListingSearch from "../../../components/hardwares/hardware-listing-search/hardware-listing-search";
import HardwareListingPagination from "../../../components/hardwares/hardware-listing-pagination/hardware-listing-pagination";
import useMediaBelow from "../../../../shared/hooks/useMediaBelow";

export default function HardwaresListingPage() {
    // 992px mobile
    const isMobile = useMediaBelow(992);

    return (
        <>
            <Helmet>
                <title>Gotalk - Cell phones</title>
                <meta name="description" content="Gotalk - Cell phones" />
                <meta name="keywords" content="Gotalk - Cell phones" />
            </Helmet>
            <HardwareListingContextProvider isMobile={isMobile}>
                <div className="hwl-container">
                    <h1 className="hwl-title">Cell phones</h1>
                    <div className="hwl-content-container">
                        {!isMobile && (
                            <div className="hwl-filters-container">
                                <HardwareListingFilters />
                            </div>
                        )}
                        <div className="hwl-products-container">
                            <HardwareListingSearch />
                            <HardwareListingProducts />
                            <HardwareListingPagination />
                        </div>
                    </div>
                </div>
            </HardwareListingContextProvider>
        </>
    )
}
