import './hardware-detail-images.scss';
import {useContext, useEffect, useMemo, useState} from "react";
import {HardwareDetailContext} from "../../../../contexts/hardware-detail-context/hardware-detail-context";
import ChevronUpIcon from "../../../../assets/images/hardwares/chevron-up.svg";

export default function HardwareDetailImages() {
    const { productDetail, isMobile, images, selectedVariant } = useContext(HardwareDetailContext);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        setSelectedIndex(0);
    }, [selectedVariant]);

    function goPrevImage() {
        setSelectedIndex(s => s <= 0 ? images.length - 1 : s - 1);
    }

    function goNextImage() {
        setSelectedIndex(s => s >= images.length - 1 ? 0 : s + 1);
    }

    return (
        <div className="hwd-images">
            {isMobile ? (
                <div className="hwd-images--title">
                    {productDetail.title}
                </div>
            ) : null}
            <div className="hwd-images--big">
                <div className="hwd-images--prev" onClick={() => goPrevImage()}>
                    <img src={ChevronUpIcon} alt="Previous" />
                </div>
                <div className="hwd-images--next" onClick={() => goNextImage()}>
                    <img src={ChevronUpIcon} alt="Next" />
                </div>

                {images.map((image, idx) => {
                    const isActive = idx === selectedIndex;
                    return (
                        <div className={`hwd-images--big-image ${isActive ? 'active' : ''}`}
                             style={{left: isActive ? 0 : window.innerWidth}}
                             key={idx.toString()}>
                            <img src={image.imageUrl} alt={`${productDetail.title} - ${selectedVariant?.title}`} />
                        </div>
                    );
                })}
            </div>
            <div className="hwd-images--thumbnails">
                {images.map((image, idx) => {
                    const isActive = idx === selectedIndex;
                    return (
                        <div className={`hwd-images--thumbnail ${isActive ? 'active' : ''}`}
                             key={idx.toString()}
                             onClick={() => setSelectedIndex(idx)}>
                            <img src={image.imageUrl} alt={`${productDetail.title} - ${selectedVariant?.title} ${idx}`} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
