const paymentTypes = {
  BUY_PLAN: 'BUY_PLAN',
  BUY_PLAN_AUTHORIZED: 'BUY_PLAN_AUTHORIZED',
  BUY_PLAN_STRIPE: 'BUY_PLAN_STRIPE',
  BUY_PlAN_CHECK: 'BUY_PlAN_CHECK',
  PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  RESET_PAYMENT_ERRORS: 'RESET_PAYMENT_ERRORS',
  SET_PAYMENT_STATUS: 'SET_PAYMENT_STATUS',
  CHECKOUT_DATAS: 'CHECKOUT_DATAS'
};

export default paymentTypes;
