import routes from '../../../../navigation/routes';
import actionsTypes from '../../../../redux/workers/account/account-types';
import checkRole, { USER_ROLES } from '../../../../shared/checkRole';
import Button from '../../../components/ui-component/button/button';
import Spinner from '../../../components/ui-component/spinner/spinner';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import './accounts-select.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const AccountSelect = ({ addClass }) => {
  const [isOpen, setOpen] = useState(false);
  const {
    user,
    accountProduct,
    accountData,
    currentProduct,
    isAccountProductLoading,
    isAccountLoading,
    isUserEligibleForVpn,
  } = useSelector((state) => ({
    user: state.authReducer.user,
    accountProduct: state.accountReducer.accountProduct,
    accountData: state.accountReducer.accountData,
    currentProduct: state.accountReducer.currentProduct,
    isAccountProductLoading: state.loadingReducer.accountProductLoading,
    isAccountLoading: state.loadingReducer.loadingAccountData,
    isUserEligibleForVpn: state.accountReducer.isUserEligibleForVpn,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isOwner = checkRole(user) === USER_ROLES.OWNER;

  const handleAccountSelect = () => setOpen(!isOpen);

  const { search, pathname } = useLocation();
  const id = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('id') || 0;
  }, [search]);

  const clickPlan = (id, simType, simStatus) => {
    handleAccountSelect();

    switch (simStatus) {
      case 'BLOCKED':
        navigate(`${routes.account.tracker}?${simStatus}&id=${id}`);
        break;
      case 'BUSY':
        navigate(`${routes.account.tariffInfo}?id=${id}`);
        break;
      default:
        navigate(`${routes.account.tracker}/${simType}?id=${id}`);
    }
  };

  const buyMore = () => {
    navigate(routes.plans);
  };

  const handleAccount = () => {
    handleAccountSelect();

    navigate(routes.account.edit.base);
  };

  useEffect(() => {
    if (id) {
      dispatch({
        type: actionsTypes.LOAD_CURRENT_PRODUCT,
        id,
        productType: pathname.indexOf(routes.account.hardwares) > -1 ? 'hardware' : 'sim',
      });
    }
  }, [id]);

  useEffect(() => {
    dispatch({
      type: actionsTypes.LOAD_IS_USER_ELIGIBLE_FOR_VPN,
    });
  }, []);

  const classes = classNames('account-select', addClass, { 'account-select_active': isOpen });
  const classesReveal = classNames('account-select-user__reveal', {
    'account-select-user__reveal_active': isOpen,
  });
  const classesContent = classNames('account-select-content', {
    'account-select-content_active': isOpen,
  });

  function pathnameActive(goToLink) {
    if (id) {
      return false;
    }
    return pathname.includes(goToLink);
  }

  function renderSidebarProduct(item, idx) {
    if (item.productType === 'hardware') {
      const isActive = item.id == id;
      const invoiceNumber = item.payData?.authorizeNetInvoiceNumber || item.payData?.userPaysId;
      return (
        <div
          role="button"
          tabIndex="0"
          key={idx}
          className={`account-select-item ${isActive ? 'active' : ''}`}
          onClick={() =>
            navigate(`${routes.account.base}/${routes.account.hardwares}?id=${item.id}`)
          }
        >
          <div className="account-select-item__icon"></div>
          <div>
            <p className="account-select-item__number">
              #{invoiceNumber} {item.title || ''}
              {item.count > 1 ? ` (${item.count})` : ''}
            </p>
            <p className="account-select-item__tariff">{`Color: ${item.color}`}</p>
          </div>
        </div>
      );
    }

    if (item.productType === 'sim') {
      const isActive = item.userSimPlanId == id;
      return (
        <div
          role="button"
          tabIndex="0"
          key={idx}
          className={`account-select-item ${isActive ? 'active' : ''}`}
          onClick={() => clickPlan(item.userSimPlanId, item.simType, item.simStatus)}
        >
          <div className="account-select-item__icon"></div>
          <div>
            <p className="account-select-item__number">{item.phone || ''}</p>
            <p className="account-select-item__tariff">{`tariff: ${item.planName}`}</p>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className={classes}>
      {isAccountProductLoading || isAccountLoading ? (
        <div className="account-select__spinner-container">
          <Spinner />
        </div>
      ) : (
        <div className="account-select-wrapper">
          <div className="account-select-mobile__container">
            <p className="account-select-user__mail">{accountData?.email}</p>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="button" onClick={handleAccountSelect} className={classesReveal} />
          </div>

          <div className={classesContent}>
            <div className="account-select-content__wrapper">
              <div
                role="button"
                tabIndex="0"
                className={`account-select-item ${
                  pathnameActive(routes.account.edit.base) ? 'active' : ''
                }`}
                onClick={handleAccount}
              >
                <div className="account-select-item__icon">&nbsp;</div>
                <div>
                  <p className="account-select-user__account">Account</p>
                  <p className="account-select-user__mail">My Profile</p>
                </div>
              </div>

              {isOwner && (
                <div className="account-select-content__link-admin-wrapper">
                  <a
                    href="https://gotalkwireless.com/admin/#/users"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="account-select-content__link-admin"
                  >
                    see list of users
                  </a>
                </div>
              )}

              {!isOwner && (
                <>
                  {isUserEligibleForVpn && (
                    <div
                      role="button"
                      tabIndex="0"
                      className={`account-select-item ${
                        pathnameActive(`${routes.account.base}/${routes.account.vpnUser}`)
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => navigate(`${routes.account.base}/${routes.account.vpnUser}`)}
                    >
                      <div className="account-select-item__icon"></div>
                      <div>
                        <p className="account-select-item__number">VPN Account</p>
                      </div>
                    </div>
                  )}
                  {accountData.isOldCustomer && (
                    <div
                      role="button"
                      tabIndex="0"
                      className={`account-select-item ${
                        pathnameActive(`${routes.account.base}/${routes.account.oldCustomer}`)
                          ? 'active'
                          : ''
                      }`}
                      onClick={() =>
                        navigate(`${routes.account.base}/${routes.account.oldCustomer}`)
                      }
                    >
                      <div className="account-select-item__icon"></div>
                      <div>
                        <p className="account-select-item__number">GoTalk USA Purchases</p>
                      </div>
                    </div>
                  )}
                  {accountProduct.map(renderSidebarProduct)}
                </>
              )}
            </div>
            <Button addClass="account-select-buy__btn" onClick={buyMore} title="Buy more" />
          </div>
        </div>
      )}
    </div>
  );
};

AccountSelect.defaultProps = {
  addClass: '',
};

AccountSelect.propTypes = {
  addClass: PropTypes.string,
};

export default AccountSelect;
