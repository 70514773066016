import './payment-form.scss';
import routes from '../../../navigation/routes';
import { PAYMENT_STATUSES } from '../../../redux/reducers/payment';
import paymentTypes from '../../../redux/workers/payment/payment-types';
import PaymentPopup from '../basket/payment-popup/payment-popup';
import Checkbox from '../ui-component/checkbox/checkbox';
import get from 'lodash/get';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import authTypes from "../../../redux/workers/auth/auth-types";
import {getDiscountAmount, setCouponToLocalStorage} from "../../../shared/basketActions";
import actionsTypes from "../../../redux/workers/account/account-types";
import PaymentStripe from "./components/payment-stripe/payment-stripe";
import PaymentAuthorize from "./components/payment-authorize/payment-authorize";
import basketTypes from "../../../redux/workers/basket/basket-types";
import CreditCardIcon from "../../../assets/images/payment/credit_card_icon.png";
import AffirmLogo from "../../../assets/images/payment/affirm_logo.png";

const PaymentForm = ({checkout, isBasketEmpty}) => {

  const [showPopup, setShowPopup] = useState(false);
  const sendReceipt = useRef(false);
  const { totalPrice, paymentStatus, coupon, paymentType, paymentMethodType, totalHardwares, tax, totalSimPrice } = useSelector((state) => ({
    totalPrice: state.basketReducer.totalPrice,
    paymentStatus: state.payment.paymentStatus,
    coupon: state.basketReducer.coupon,
    paymentType: state.basketReducer.paymentType,
    paymentMethodType: state.basketReducer.paymentMethodType,
    totalHardwares: state.basketReducer.totalHardwares,
    tax: state.basketReducer.tax,
    totalSimPrice: state.basketReducer.totalSimPrice
  }));
  const {checkoutDatas} =  useSelector(state => state.payment);
  const {isSignedIn} = useSelector(state => state.authReducer);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const stripeRef = useRef();
  const authorizeNetRef = useRef();

  const handleSubmit = () => {
    if(paymentType === "stripe") {
      stripeRef.current?.handleSubmit({
        sendReceipt: sendReceipt.current,
        checkout
      });
    } else if(paymentType === "authorizeNet") {
      authorizeNetRef.current?.handleCheckoutDatas({
        checkout
      });
    }
  };

  const handleClosePopup = () => {
    switch (paymentStatus.status) {
      case PAYMENT_STATUSES.ERROR_STRIPE:
        setShowPopup(false);
        break;

      case PAYMENT_STATUSES.ERROR_SIM_CARD:
        navigate(routes.plans);
        break;

      case PAYMENT_STATUSES.SUCCESS:
        setCouponToLocalStorage(null);
        if(!isSignedIn) {
          dispatch({
            type: authTypes.SIGN_IN,
            payload: {
              userData: {
                email: checkoutDatas.user.email,
                password: checkoutDatas.user.password
              },
              userSimPlanId: checkoutDatas.userSimPlanId,
              simType: checkoutDatas.simType,
              redirect: navigate
            }
          });
        } else {
          dispatch({ type: actionsTypes.LOAD_CURRENT_PRODUCT, id: checkoutDatas.userSimPlanId, productType: 'sim' });
          navigate(`${routes.account.base}/${routes.account.tracker}/${checkoutDatas.simType}?id=${checkoutDatas.userSimPlanId}`);
        }
        break;

      default:
        setShowPopup(false);
    }
  };

  const handleCheck = (event) => {
    const { checked } = event.target;
    sendReceipt.current = checked;
  };

  const resetPaymentStatus = () => dispatch({
    type: paymentTypes.SET_PAYMENT_STATUS,
    paymentStatus: { status: PAYMENT_STATUSES.DISABLE, message: '' },
  });

  useEffect(() => {
    switch (paymentStatus.status) {
      case PAYMENT_STATUSES.DISABLE:
        return;

      case PAYMENT_STATUSES.SUCCESS:
        navigate(routes.paymentSuccess, {
          state: {
            success: true,
            checkoutDatas,
            message: paymentStatus.message
          }
        });
        addShareASaleScripts();
        addGoogleSaleScripts();
        addWhistleOutScripts();
        break;

      case PAYMENT_STATUSES.ERROR_SIM_CARD:
        setShowPopup(true);
        break;

      case PAYMENT_STATUSES.ERROR_STRIPE:
        setShowPopup(true);
        break;

      default:
        break;
    }
  }, [paymentStatus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetPaymentStatus, []);

  useEffect(() => {
    removeShareASaleScripts();
    removeGoogleSaleScripts();
    removeWhistleOutScripts();
  }, []);

  function addShareASaleScripts() {
    const img = document.createElement("img");
    img.src = `https://www.shareasale.com/sale.cfm?tracking=${getPayIds()[0]}&amount=${totalPrice - (discountAmount || 0)}&merchantID=127982&transtype=sale`;
    img.width = "1";
    img.height = "1";
    img.id = "shareasaleImg";
    document.getElementsByTagName("body")[0].appendChild(img);

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://www.dwin1.com/19038.js";
    s.id = "shareasale";
    document.getElementsByTagName("head")[0].appendChild(s);
  }

  function removeShareASaleScripts() {
    document.getElementById("shareasale")?.remove();
    document.getElementById("shareasaleImg")?.remove();
  }

  function addGoogleSaleScripts() {
    const s = document.createElement("script");
    s.id = "googlesale";
    s.innerHTML = `gtag('event', 'conversion', { 'send_to': 'AW-10903290545/Y7XzCODKocMDELGFjM8o', 'transaction_id': '${getPayIds()[0]}' });`;
    document.getElementsByTagName("head")[0].appendChild(s);
  }

  function removeGoogleSaleScripts() {
    document.getElementById("googlesale")?.remove();
  }

  function addWhistleOutScripts() {
    const s = document.createElement("script");
    s.id = "whistleout_sale";
    s.innerHTML = `gtag('event', 'conversion', { 'allow_custom_scripts': true, 'send_to': 'DC-12757151/saleq0/sale40+standard' });`;
    document.getElementsByTagName("head")[0].appendChild(s);
  }

  function removeWhistleOutScripts() {
    document.getElementById("whistleout_sale")?.remove();
  }

  const discountAmount = useMemo(() => {
    return getDiscountAmount(coupon, totalSimPrice);
  }, [coupon, totalSimPrice]);

  async function checkoutSubmitForms() {
    if(paymentMethodType === "AFFIRM") {
      document.querySelector('.billing-form__submit')?.click();
    }
    else {
      if(!checkoutDatas) {
        if(paymentType === "authorizeNet") {
          const creditCard = await authorizeNetRef.current.handleSubmit();
          if(creditCard) {
            localStorage.setItem('AUTHORIZE_NET_INFO', JSON.stringify(creditCard));
            document.querySelector('.billing-form__submit')?.click();
          }
        }
        else {
          document.querySelector('.billing-form__submit')?.click();
        }
      }
      else {
        handleSubmit();
      }
    }
  }

  useEffect(() => {
    if(checkoutDatas) {
      handleSubmit();
    }
  }, [checkoutDatas]);

  function getPayIds() {
    return checkoutDatas?.payIds || get(location, 'state.payIds', ['']);
  }

  function changePaymentMethodType(type) {
    dispatch({
      type: basketTypes.BASKET_UPDATE,
      payload: {
        paymentMethodType: type
      }
    });
  }

  const showPaymentMethodSelector = totalHardwares > 0;

  return (
    <form onSubmit={handleSubmit} className="payment__form">
      {!isBasketEmpty ? (
          <div className="payment-form">
            {!checkout && (
                <>
                  <div className="payment-form__header-container">
                    <h3 className="payment-form__header">Payment</h3>
                  </div>
                  <span className="payment-form__total-price-container">
                    <strong className="payment-form__total-price-text">Total</strong>
                    <strong className="payment-form__total-price-price">{`$${(totalPrice - (discountAmount || 0)) + (tax?.taxSum || 0)}`}</strong>
                  </span>
                </>
            )}
            {totalPrice === 0 && (
                <div className="payment-form__trial">
                  Please enter your card details below to allow us to validate you are a real person.
                  You will not be charged during the trial period.
                </div>
            )}

            {showPaymentMethodSelector && (
                <div className="payment-type-selector">
                  <div className={`payment-type-selector--item ${paymentMethodType === 'CARD' ? 'active': ''}`}
                       onClick={() => changePaymentMethodType('CARD')}>
                    <img className="payment-type-selector--item--logo" src={CreditCardIcon} alt="Credit Card" />
                    CREDIT CARD
                  </div>
                  <div className={`payment-type-selector--item ${paymentMethodType === 'AFFIRM' ? 'active': ''}`}
                       onClick={() => changePaymentMethodType('AFFIRM')}>
                    <img className="payment-type-selector--item--logo" src={AffirmLogo} alt="Affirm" />
                    AFFIRM
                  </div>
                </div>
            )}

            {(paymentMethodType === "CARD" || !showPaymentMethodSelector) ? (
                <>
                  {paymentType === "stripe" && <PaymentStripe ref={stripeRef} />}
                  {paymentType === "authorizeNet" && <PaymentAuthorize ref={authorizeNetRef} />}
                </>
            ) : null}

            <div className="payment-form__row">
              <Checkbox
                  onChange={handleCheck}
                  label="Get a receipt by email"
                  addClass="payment-form__checkbox"
              />
              <span className="payment-form__total-price-container">
                <strong className="payment-form__total-price-text">Total</strong>
                <strong className="payment-form__total-price-price">{`$${(totalPrice - (discountAmount || 0)) + (tax?.taxSum || 0)}`}</strong>
              </span>
            </div>
            <div className="payment-form__controls-container">
              {checkout ? (
                  <button type="button" className="payment-form__button-pay" onClick={checkoutSubmitForms}>
                    {paymentMethodType === "AFFIRM" ? "PAY WITH AFFIRM" : "PAY"}
                  </button>
              ) : (
                  <button type="submit" className="payment-form__button-pay">
                    {paymentMethodType === "AFFIRM" ? "PAY WITH AFFIRM" : "PAY"}
                  </button>
              )}
            </div>
            <div className="payment-form__policy">
              By placing your order, you agree to our <a href="/terms-conditions" target="_blank">Terms & Conditions</a>, <a href="/return-policy" target="_blank">Refund Policy</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
          </div>
      ) : null}
      {showPopup && (
        <PaymentPopup
          isSuccess={paymentStatus.status === PAYMENT_STATUSES.SUCCESS}
          close={handleClosePopup}
          onSubmit={handleClosePopup}
          message={paymentStatus.message}
        />
      )}
    </form>
  );
};

export default PaymentForm;
