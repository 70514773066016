import './payment-stripe.scss';
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {forwardRef, useImperativeHandle, useMemo, useRef} from "react";
import Input from "../../../ui-component/input/input";
import {formatHolder} from "../../../../../shared/cardFormatter";
import StripeTrustBadge from "../../../../../assets/images/payment/stripe-trust-badge.png";
import get from "lodash/get";
import paymentTypes from "../../../../../redux/workers/payment/payment-types";
import {useDispatch, useSelector} from "react-redux";

const PaymentStripe = forwardRef((props, ref) => {
    const elements = useElements();
    const stripe = useStripe();
    const holderRef = useRef(null);
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontFamily: 'Roboto-Regular, sans-serif',
                    fontStyle: 'normal',
                    fontSize: '18px',
                },
            },
        }),
        [],
    );
    const {checkoutDatas} =  useSelector(state => state.payment);
    const {user} = useSelector(state => state.authReducer);
    const dispatch = useDispatch();

    const handleSubmit = (req) => {
        const {
            sendReceipt,
            checkout
        } = req;

        if (!holderRef.current?.value) {
            return;
        }

        if (holderRef.current.value.length < 4) {
            holderRef.current.classList.add('invalid');
            return;
        }

        if (!stripe || !elements) {
            return;
        }

        const paymentConfig = {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    address: {
                        city: (checkoutDatas.user || user)?.city,
                        country: 'US',
                        line1: (checkoutDatas.user || user)?.street,
                        line2: (checkoutDatas.user || user)?.apartment,
                        postal_code: (checkoutDatas.user || user)?.zip,
                        state: (checkoutDatas.user || user)?.country
                    },
                    email: null,
                    name: `${(checkoutDatas.user || user)?.firstName} ${(checkoutDatas.user || user)?.lastName}`,
                    phone: (checkoutDatas.user || user)?.phone
                },
            },
        };

        if (sendReceipt) {
            paymentConfig.receipt_email = checkout
                ? checkoutDatas.user.email
                : location.state?.user?.email;
        }

        let clientSecret, payIds, email;
        if(checkout) {
            clientSecret = checkoutDatas.clientSecret;
            payIds = checkoutDatas.payIds;
            email = checkoutDatas.user.email;
        }
        else {
            clientSecret = get(location, 'state.clientSecret', '');
            payIds = get(location, 'state.payIds', '');
            email = get(location, 'state.user.email', '');
        }

        dispatch({
            type: paymentTypes.BUY_PLAN_STRIPE,
            stripe,
            clientSecret,
            paymentConfig,
            payIds,
            email,
        });
    }

    useImperativeHandle(
        ref,
        () => ({
            handleSubmit
        })
    );

    return (
        <div className="payment-form__card-container">
            <div className="payment-form__card-side front">
                <div className="payment-form__field-container">
                    <p className="payment-form__field-label">Credit card number</p>
                    <CardNumberElement options={{ ...options, placeholder: '0000 0000 0000 0000' }} />
                </div>
                <div className="payment-form__row">
                    <div className="payment-form__field-container holder">
                        <p className="payment-form__field-label">Card Holder name</p>
                        <Input
                            ref={holderRef}
                            onFocus={() => holderRef.current.classList.remove('invalid')}
                            onChange={(event) => {
                                const input = event.target;
                                input.value = formatHolder(input.value);
                                return event;
                            }}
                            containerClass="payment-form__input"
                            placeholder="Enter credit holder name"
                            type="text"
                            autoComplete="cc-name"
                        />
                    </div>
                    <div className="payment-form__field-container">
                        <p className="payment-form__field-label">Expiration Date</p>
                        <CardExpiryElement options={options} />
                    </div>
                </div>
            </div>
            <div className="payment-form__card-side back">
                <div className="payment-form__cvv-background" />
                <div className="payment-form__field-container cvv">
                    <p className="payment-form__field-label">CVC</p>
                    <CardCvcElement options={{ ...options }} />
                </div>
            </div>
            <div className="payment-form__card__trustbadge">
                <img src={StripeTrustBadge} alt="Stripe Secure Payment" className="payment-form__card__trustbadge-img"/>
            </div>
        </div>
    );
});

export default PaymentStripe;
