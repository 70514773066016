/* eslint-disable no-console */
import createQueryParams from "../../shared/createQueryParams";
import delay from "../../shared/delay";
import accountTypes from "../workers/account/account-types";
import {
  getAccountData,
  setAccountData,
  putAccountData,
  postAccountPassword,
  getAccountProduct,
  getCurrentProduct,
  sendVerificationEmailCode,
  setMethodVerification,
  setVerificationEmailProgerss,
  setVerificationYubikeyProgress,
  postActivateSim,
  postChangeNumber,
  postActivateESim,
  getQr,
  getIsUserEligibleForVpn,
  getVpnUser,
  addVpnUser,
  getVpnAppLinks,
  resetVpnUserPassword,
  setVpnUserPassword,
  cancelSubscription,
  changePlan,
  generateSecretAndGetOtpQr,
  setVerificationOtpProgress,
  confirmChangePlan,
} from "../workers/account/index";
import { startLoading, stopLoading } from "../workers/loading";
import get from "lodash/get";
import { call, takeLatest, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import routes from "../../navigation/routes";

function* getAccountDataSaga() {
  try {
    yield put(startLoading("loadingAccountData"));

    const response = yield call(getAccountData);
    const data = yield get(response, "data.payload", {});

    yield put(setAccountData(data));
  } catch (error) {
  } finally {
    yield delay(500);
    yield put(stopLoading("loadingAccountData"));
  }
}

function* putAccountDataSaga({ data }) {
  try {
    yield put(startLoading("loadingAccountData"));
    yield call(putAccountData, data);

    const responseNewData = yield call(getAccountData);
    const newData = yield get(responseNewData, "data.payload", {});

    yield put(setAccountData(newData));
  } catch (error) {
  } finally {
    yield delay(500);
    yield put(stopLoading("loadingAccountData"));
  }
}

function* postAccountPasswordSaga({ body }) {
  try {
    yield put(startLoading("loadingAccountData"));
    yield call(postAccountPassword, body);
  } catch (error) {
  } finally {
    yield delay(500);
    yield put(stopLoading("loadingAccountData"));
  }
}

function* getAccountProductSaga() {
  try {
    yield put(startLoading("accountProductLoading"));
    const data = yield call(getAccountProduct);
    yield put({
      type: accountTypes.GET_PRODUCT_DATA,
      payload: data.data.payload,
    });
  } catch (error) {
  } finally {
    yield put(stopLoading("accountProductLoading"));
  }
}

function* currentProductSaga(params) {
  try {
    yield put(startLoading("currentProductLoading"));

    const data = yield call(getCurrentProduct, params.id, params.productType);

    yield put({
      type: accountTypes.GET_CURRENT_PRODUCT,
      payload: data.data.payload,
    });
  } catch (error) {
  } finally {
    yield put(stopLoading("currentProductLoading"));
  }
}

export function* activateSimSaga(action) {
  try {
    const { ICCID, productId } = action.payload;

    yield put(startLoading("activateSimLoading"));

    const data = yield call(postActivateSim, { ICCID });

    yield put({
      type: accountTypes.POST_ACTIVATE_SIM,
      payload: data.data.payload,
      message: "Activation successful",
    });

    yield call(currentProductSaga, { id: productId });
  } catch (error) {
    yield put({
      type: accountTypes.POST_ACTIVATE_SIM,
      payload: error.response.data.payload,
      message: "Activation failed",
    });
  } finally {
    yield put(stopLoading("activateSimLoading"));
  }
}

export function* changeNumberSaga(body) {
  try {
    yield put(startLoading("changeNumberLoading"));
    const data = yield call(postChangeNumber, body.payload);
    yield put({
      type: accountTypes.POST_CHANGE_NUMBER,
      payload: data.data.payload,
    });
  } catch (error) {
    yield put({
      type: accountTypes.POST_CHANGE_NUMBER,
      payload: error.response.data.payload,
    });
    yield put({ type: accountTypes.ERROR_MESSAGE, payload: "ERROR" });
  } finally {
    yield put(stopLoading("changeNumberLoading"));
  }
}

export function* activateESimSaga(body) {
  try {
    yield put(startLoading("activateEsimLoading"));
    const data = yield call(postActivateESim, body.payload);
    yield put({
      type: accountTypes.POST_ACTIVATE_ESIM,
      payload: data.data.payload,
      message: "Activation successful",
    });
  } catch (error) {
    yield put({
      type: accountTypes.POST_ACTIVATE_ESIM,
      payload: error.response.data.payload,
      message: "Activation failed",
      success: false,
    });
  } finally {
    yield put(stopLoading("activateEsimLoading"));
  }
}

export function* scanQrSaga(body) {
  const paramsQr = createQueryParams(body.payload);
  try {
    yield put(startLoading("getQRLoading"));

    const response = yield call(getQr, paramsQr);
    const qr = get(response, "data.payload", "");

    yield put({
      type: accountTypes.GET_QR,
      payload: `data:image/png;base64,${qr}`,
    });
  } catch (error) {
    yield put({
      type: accountTypes.GET_QR,
      payload: error.response.data.payload,
    });
  } finally {
    yield put(stopLoading("getQRLoading"));
  }
}

export function* activateESimAndScanQrSaga(body) {
  try {
    //#region Activating sim
    yield put(startLoading("activateEsimLoading"));
    const data = yield call(postActivateESim, body.payload);
    yield put({
      type: accountTypes.POST_ACTIVATE_ESIM,
      payload: data.data.payload,
      message: "Activation successful",
    });
    yield put(stopLoading("activateEsimLoading"));
    //#endregion

    //#region Getting QR
    const paramsQr = createQueryParams(body.payload);
    try {
      yield put(startLoading("getQRLoading"));

      const response = yield call(getQr, paramsQr);
      const qr = get(response, "data.payload", "");

      yield put({
        type: accountTypes.GET_QR,
        payload: `data:image/png;base64,${qr}`,
      });
    } catch (error) {
      yield put({
        type: accountTypes.GET_QR,
        payload: error.response.data.payload,
      });
    } finally {
      yield put(stopLoading("getQRLoading"));
    }
    //#endregion
  } catch (error) {
    yield put(stopLoading("activateEsimLoading"));
    yield put({
      type: accountTypes.POST_ACTIVATE_ESIM,
      payload: {
        ...error.response.data.payload,
        success: false,
      },
      message: "Activation failed",
    });
  }
}

function* sendVerificationEmailCodeSaga() {
  try {
    yield put(startLoading("loadingaccountEditMethodVerification"));
    yield call(sendVerificationEmailCode);
  } catch (e) {
  } finally {
    yield delay(500);
    yield put(stopLoading("loadingaccountEditMethodVerification"));
  }
}

function* setMethodVerificationSaga({ data }) {
  try {
    yield put(startLoading("loadingaccountEditMethodVerification"));

    yield call(setMethodVerification, data);

    yield delay(500);
    yield put(stopLoading("loadingaccountEditMethodVerification"));

    if (data.emailFactor) {
      yield put(
        setVerificationEmailProgerss({ status: "success", message: "" })
      );
    }

    if (data.yubicoFactor) {
      yield put(
        setVerificationYubikeyProgress({ status: "success", message: "" })
      );
    }

    if (data.otpFactor) {
      yield put(setVerificationOtpProgress({ status: "success", message: "" }));
    }

    yield* getAccountDataSaga();
  } catch (e) {
    yield delay(500);
    yield put(stopLoading("loadingaccountEditMethodVerification"));

    if (data?.emailFactor) {
      yield put(
        setVerificationEmailProgerss({
          status: "error",
          message: e?.response?.data?.message || "Error",
        })
      );
    }

    if (data?.yubicoFactor) {
      yield put(
        setVerificationYubikeyProgress({
          status: "error",
          message: e?.response?.data?.message || "Error",
        })
      );
    }

    if (data?.otpFactor) {
      yield put(
        setVerificationOtpProgress({
          status: "error",
          message: e?.response?.data?.message || "Error",
        })
      );
    }
  }
}

function* getIsUserEligibleForVpnSaga() {
  try {
    const { data } = yield call(getIsUserEligibleForVpn);
    yield put({
      type: accountTypes.IS_USER_ELIGIBLE_FOR_VPN,
      payload: !!data?.payload?.status,
    });
  } catch {
    yield put({
      type: accountTypes.IS_USER_ELIGIBLE_FOR_VPN,
      payload: false,
    });
  }
}

function* getVpnUserSaga() {
  try {
    yield put({
      type: accountTypes.VPN_USER_LOADING,
      payload: true,
    });
    const { data } = yield call(getVpnUser);
    yield put({
      type: accountTypes.VPN_USER,
      payload: data.payload,
    });
  } catch (ex) {
    yield put({
      type: accountTypes.VPN_USER,
      payload: null,
    });
  } finally {
    yield put({
      type: accountTypes.VPN_USER_LOADING,
      payload: false,
    });
  }
}

function* addVpnUserSaga(payload) {
  try {
    yield put({
      type: accountTypes.VPN_USER_LOADING,
      payload: true,
    });
    const { data } = yield call(addVpnUser, payload.data);
    yield put({
      type: accountTypes.VPN_USER,
      payload: data.payload,
    });
  } catch (ex) {
    if (ex?.response?.data?.payload?.message) {
      toast.error(ex.response.data.payload.message, {
        position: "top-right",
      });
    }
  } finally {
    yield put({
      type: accountTypes.VPN_USER_LOADING,
      payload: false,
    });
  }
}

function* getVpnAppLinksSaga() {
  try {
    yield put({
      type: accountTypes.VPN_APP_LINKS_LOADING,
      payload: true,
    });
    const { data } = yield call(getVpnAppLinks);
    yield put({
      type: accountTypes.VPN_APP_LINKS,
      payload: data.payload,
    });
  } catch {
  } finally {
    yield put({
      type: accountTypes.VPN_APP_LINKS_LOADING,
      payload: false,
    });
  }
}

function* resetVpnUserPasswordSaga(payload) {
  try {
    yield put({
      type: accountTypes.RESET_VPN_USER_PASSWORD_LOADING,
      payload: true,
    });
    const { data } = yield call(resetVpnUserPassword, payload.data);
    if (data.payload) {
      toast.success("Reset password link sent to your email address", {
        position: "top-right",
      });
    }
  } catch (ex) {
    if (ex?.response?.data?.payload?.message) {
      toast.error(ex.response.data.payload.message, {
        position: "top-right",
      });
    }
  } finally {
    yield put({
      type: accountTypes.RESET_VPN_USER_PASSWORD_LOADING,
      payload: false,
    });
  }
}

function* setVpnUserPasswordSaga(payload) {
  try {
    yield put({
      type: accountTypes.SET_VPN_USER_PASSWORD_LOADING,
      payload: true,
    });
    const { data } = yield call(setVpnUserPassword, payload.data);
    if (data.payload) {
      toast.success("You can login with your new password now", {
        position: "top-right",
      });
    }
  } catch (ex) {
    if (ex?.response?.data?.payload?.message) {
      toast.error(ex.response.data.payload.message, {
        position: "top-right",
      });
    }
  } finally {
    yield put({
      type: accountTypes.SET_VPN_USER_PASSWORD_LOADING,
      payload: false,
    });
  }
}

function* cancelSubscriptionSaga(payload) {
  try {
    yield put(startLoading("currentProductLoading"));
    const { data } = yield call(cancelSubscription, payload.data);
    if (data.payload) {
      toast.success("Your subscription cancelled", {
        position: "top-right",
      });
      payload.navigate(routes.account.base);
    } else {
      toast.error("Technical error occurred", {
        position: "top-right",
      });
    }
  } catch {
    toast.error("Technical error occurred", {
      position: "top-right",
    });
  } finally {
    yield put(stopLoading("currentProductLoading"));
  }
}

function* changePlanSaga(payload) {
  try {
    yield put(startLoading("changePlanLoading"));
    const { data } = yield call(changePlan, payload.data);
    yield put({
      type: accountTypes.CHANGE_PLAN_RESPONSE,
      payload: data.payload,
    });
    if (data.payload.message) {
      toast.success(data.payload.message, {
        position: "top-right",
      });
    }
  } catch (ex) {
    if (ex?.response?.data?.payload?.message) {
      toast.error(ex.response.data.payload.message, {
        position: "top-right",
      });
    }
  } finally {
    yield put(stopLoading("changePlanLoading"));
  }
}

function* confirmChangePlanSaga(payload) {
  try {
    yield put(startLoading("confirmChangePlanLoading"));
    const { data } = yield call(confirmChangePlan, payload.data);
    yield put({
      type: accountTypes.CONFIRM_CHANGE_PLAN_RESPONSE,
      payload: data.payload,
    });
    yield call(currentProductSaga, {
      id: data.payload.changedUserSimPlanIds[0],
    });
    if (data.payload.message) {
      toast.success(data.payload.message, {
        position: "top-right",
      });
    }
  } catch (ex) {
    if (ex?.response?.data?.payload?.message) {
      toast.error(ex.response.data.payload.message, {
        position: "top-right",
      });
    }
  } finally {
    yield put(stopLoading("confirmChangePlanLoading"));
  }
}

function* loadOtpQrSaga() {
  const { data } = yield call(generateSecretAndGetOtpQr);
  yield put({
    type: accountTypes.OTP_QR,
    payload: data.qr,
  });
}

export default function* accountWatcher() {
  yield takeLatest([accountTypes.GET_ACCOUNT_DATA], getAccountDataSaga);
  yield takeLatest([accountTypes.PUT_ACCOUNT_DATA], putAccountDataSaga);
  yield takeLatest(
    [accountTypes.POST_ACCOUNT_PASSWORD],
    postAccountPasswordSaga
  );
  yield takeLatest([accountTypes.LOAD_PRODUCT], getAccountProductSaga);
  yield takeLatest([accountTypes.LOAD_CURRENT_PRODUCT], currentProductSaga);
  yield takeLatest(
    [accountTypes.SEND_VERIFICATION_EMAIL_CODE],
    sendVerificationEmailCodeSaga
  );
  yield takeLatest(
    [accountTypes.SET_METHOD_VERIFICATION],
    setMethodVerificationSaga
  );
  yield takeLatest([accountTypes.LOAD_ACTIVATE_SIM], activateSimSaga);
  yield takeLatest([accountTypes.LOAD_CHANGE_NUMBER], changeNumberSaga);
  yield takeLatest([accountTypes.LOAD_ACTIVATE_ESIM], activateESimSaga);
  yield takeLatest([accountTypes.LOAD_GET_QR], scanQrSaga);
  yield takeLatest(
    [accountTypes.LOAD_ACTIVATE_ESIM_AND_LOAD_GET_QR],
    activateESimAndScanQrSaga
  );
  yield takeLatest(
    [accountTypes.LOAD_IS_USER_ELIGIBLE_FOR_VPN],
    getIsUserEligibleForVpnSaga
  );
  yield takeLatest([accountTypes.LOAD_VPN_USER], getVpnUserSaga);
  yield takeLatest([accountTypes.POST_VPN_USER], addVpnUserSaga);
  yield takeLatest([accountTypes.LOAD_VPN_APP_LINKS], getVpnAppLinksSaga);
  yield takeLatest(
    [accountTypes.RESET_VPN_USER_PASSWORD],
    resetVpnUserPasswordSaga
  );
  yield takeLatest(
    [accountTypes.SET_VPN_USER_PASSWORD],
    setVpnUserPasswordSaga
  );
  yield takeLatest([accountTypes.CANCEL_SUBSCRIPTION], cancelSubscriptionSaga);
  yield takeLatest([accountTypes.CHANGE_PLAN], changePlanSaga);
  yield takeLatest([accountTypes.CONFIRM_CHANGE_PLAN], confirmChangePlanSaga);
  yield takeLatest([accountTypes.LOAD_OTP_QR], loadOtpQrSaga);
}
