import './private-wireless.scss';
import {Helmet} from "react-helmet";
import React from "react";
import PrivateWirelessBanner from "../../components/private-wireless/private-wireless-banner/private-wireless-banner";
import PrivateWirelessReasonToDeploy
    from "../../components/private-wireless/private-wireless-reason-to-deploy/private-wireless-reason-to-deploy";
import PrivateWirelessCoverage
    from "../../components/private-wireless/private-wireless-coverage/private-wireless-coverage";
import PrivateWirelessProtect
    from "../../components/private-wireless/private-wireless-protect/private-wireless-protect";
import PrivateWirelessContact
    from "../../components/private-wireless/private-wireless-contact/private-wireless-contact";

export default function PrivateWireless() {
    return (
        <>
            <Helmet>
                <title>Private Wireless</title>
                <meta name="description" content="Private Wireless – Bridge your Go Talk wireless service using private infrastructure" />
                <meta name="keywords" content="private, wireless, go talk" />
            </Helmet>
            <section className="private-wireless-container">
                <PrivateWirelessBanner />
                <PrivateWirelessReasonToDeploy />
                <PrivateWirelessCoverage />
                <PrivateWirelessProtect />
                <PrivateWirelessContact />
            </section>
        </>
    );
}
