export const home = [
  {
    key: 'title',
    value: 'Go Talk Wireless - Your protection against sim swap fraud',
  },
  {
    key: 'description',
    value:
      'Protect your sim, accounts and investments with Sim Card Fraud Protection from Go Talk Wireless',
  },
  {
    key: 'keywords',
    value: 'Sim, card, swap, fraud, gotalk, protection, wireless',
  },
];

export const plans = [
  {
    key: 'title',
    value: 'Fins the best plans on Go Talk to protect you from sim swap fraud',
  },
  {
    key: 'description',
    value:
      'Browse our selection of consumer and business plans for purchase on the Go Talk Wireless network with sim card fraud protection',
  },
  {
    key: 'keywords',
    value: 'Wireless, plans, consumer, business, go talk',
  },
];

export const howItWorks = [
  {
    key: 'title',
    value: 'Why Change Carrier? - Go Talk Wireless',
  },
  {
    key: 'description',
    value:
      'Find out all the answers to your questions about switching your wireless service to Go Talk Wireless',
  },
  {
    key: 'keywords',
    value: 'Questions, gotalk, wireless, connect, to',
  },
];

export const blogs = [
  {
    key: 'title',
    value: 'Blogs - Go Talk Wireless',
  },
  {
    key: 'description',
    value:
      'Blogs on Go Talk Wireless',
  },
  {
    key: 'keywords',
    value: 'Blog, gotalk, wireless, post',
  },
];

export const FAQs = [
  {
    key: 'title',
    value: 'FAQs - Go Talk Wireless',
  },
  {
    key: 'description',
    value:
      'Check out answers the most commonly asked questions for connecting to Go Talk and keeping your wireless service safe and secure',
  },
  {
    key: 'keywords',
    value: 'Faqs, go, talk, wireless, connect, service',
  },
];

export const simSwapFraudProtection = [
  {
    key: 'title',
    value: 'Sim Swap Fraud - Protect yourself with Go Talk Wireless',
  },
  {
    key: 'description',
    value:
      'Read stories about how people have been affected by sim swap fraud and how Go Talk Wireless can assist in protecting you from this happening',
  },
  {
    key: 'keywords',
    value: 'Sim, swap, fraud, go, talk, proection, wireless',
  },
];
