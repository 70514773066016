import './basket.scss';
import routes from '../../../navigation/routes';
import basketTypes from '../../../redux/workers/basket/basket-types';
import paymentTypes from '../../../redux/workers/payment/payment-types';
import {
  countPrice,
  countTotalPlans,
  countTotalHardwares,
  deleteFromBasket,
  getBasketItems, getCouponFromLocalStorage,
  hasDeliverableBasketItem, setCouponToLocalStorage,
  updateBasket, updateHardwareBasket, decidePaymentType, getDiscountAmount,
} from '../../../shared/basketActions';
import Button from '../ui-component/button/button';
import SimsOutErrors from '../ui-component/sims-out-errors/sims-out-errors';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useForm} from "react-hook-form";
import Input from "../ui-component/input/input";
import Spinner from "../ui-component/spinner/spinner";
import { ReactComponent as CrossIcon } from '../../../assets/images/icons/cross.svg';
import {canUseCoupon} from "../../../redux/workers/basket/coupon";
import StripeTrustBadge from "../../../assets/images/payment/stripe-trust-badge.png";
import MoneyBackIcon from "../../../assets/images/payment/shield.svg";
import SSLIcon from "../../../assets/images/payment/ssl.svg";
import DeliveryIcon from "../../../assets/images/payment/delivery.svg";
import FiveGIcon from "../../../assets/images/payment/5g.svg";
import TrustBadge from "../main-page-section/trust-badge/trust-badge";
import BillingForm from "../billing-form/billing-form";
import PaymentForm from "../payment-form/payment-form";
import {useMediaQuery} from "../../../hooks/useMediaQuery";
import NewBasketItem from "../ui-component/new-basket-item/new-basket-item";
import {createDispatchLog} from "../../../redux/workers/conversion-tracking";
import {ConversionTrackingActionNames} from "../../../types/conversionTrackingActionNames";
import ReviewsCarousel from "../reviews-carousel/reviews-carousel";
import {PlanFilterPageType, useFilteredPlansByPlaceType} from "../../../hooks/useFilteredPlansByPlaceType";
import HardwareBasketItem from "../ui-component/hardware-basket-item/hardware-basket-item";
import BasketDeliveryProvider from "../basket-delivery-provider/basket-delivery-provider";
import BasketTaxesSurcharges from "../basket-taxes-surcharges/basket-taxes-surcharges";

const Basket = ({ isBasketEmpty, setBasketEmpty, checkout }) => {
  const navigate = useNavigate();
  const [basketItems, setBasketItems] = useState(getBasketItems());
  const totalPrice = useSelector((state) => state.basketReducer.totalPrice);
  const totalSimPrice = useSelector((state) => state.basketReducer.totalSimPrice);
  const totalLines = useSelector((state) => state.basketReducer.totalLines);
  const totalHardwares = useSelector((state) => state.basketReducer.totalHardwares);
  const totalCount = useSelector((state) => state.basketReducer.totalCount);
  const hasDelivery = useSelector((state) => state.basketReducer.hasDelivery);
  const coupon = useSelector((state) => state.basketReducer.coupon);
  const paymentMethodType = useSelector((state) => state.basketReducer.paymentMethodType);
  const tax = useSelector((state) => state.basketReducer.tax);
  const plans = useFilteredPlansByPlaceType({
    pageType: PlanFilterPageType.BASKET
  });
  const { errors: paymentErrors } = useSelector((state) => state.payment);
  const [couponLoading, setCouponLoading] = useState(false);
  const dispatch = useDispatch();
  const isSmallForBasketProps = useMediaQuery('(max-width: 1040px)');
  const deliveryProvider = useSelector(state => state.basketReducer.deliveryProvider);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleNavigateToBillingForm = () => {
    if(checkout) {
      document.querySelector('.payment-form__button-pay').click();
      return;
    }

    navigate(routes.checkout, {
      state: {
        hasDelivery,
      },
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch({ type: paymentTypes.RESET_PAYMENT_ERRORS });
  }, [dispatch]);

  useEffect(() => {
    if (!basketItems.length || !totalCount) {
      setBasketEmpty(true);
      dispatch({ type: basketTypes.BASKET_TOTAL_PRICE_UPDATE, payload: 0 });
    }
  }, [basketItems, setBasketEmpty, dispatch, totalCount]);

  const onBasketItemChange = (data) => {
    if(data.type === 'hardware') {
      updateHardwareBasket(data.hardwareBasketId, data.count);
    }
    else {
      updateBasket(data.planId, data.count, data.isEsim);
    }

    dispatch({
      type: basketTypes.BASKET_UPDATE,
      payload: {
        totalLines: countTotalPlans(),
        totalHardwares: countTotalHardwares(),
        totalPrice: countPrice(plans, hasDelivery, deliveryProvider),
        totalSimPrice: countPrice(plans, hasDelivery, deliveryProvider, "sim"),
        hasDelivery: hasDeliverableBasketItem(),
        paymentType: decidePaymentType()
      },
    });
  };

  const onBasketItemDelete = (itemId, type) => {
    deleteFromBasket(itemId, type);
    setBasketItems(getBasketItems());
    dispatch({
      type: basketTypes.BASKET_UPDATE,
      payload: {
        totalCount: getBasketItems().length,
      },
    });
  };

  const renderBasketItems = (type) => {
    if(type === 'hardware') {
      const hardwares = basketItems.filter(m => m.type === "hardware");
      if(!hardwares.length) {
        return null;
      }

      return (
          <div className="basket__products-list-item">
            {hardwares.map((item, idx) => (
                <HardwareBasketItem
                    item={item}
                    key={idx}
                    onChange={onBasketItemChange}
                    onDelete={onBasketItemDelete}
                />
            ))}
          </div>
      )
    }

    const basketItemsObj = {};
    basketItems.forEach((item) => {
      basketItemsObj[item.planId] = item.count;
    });

    const basketItemsIds = Object.keys(basketItemsObj);
    const basketPlans = plans?.filter((plan) => basketItemsIds.indexOf(plan.id?.toString()) > -1) || [];

    basketPlans.forEach((plan) => {
      const updatedPlan = plan;
      const basketItem = basketItems.find((basketItem) => basketItem.planId === plan.id);
      updatedPlan.count = basketItemsObj[plan.id.toString()];
      updatedPlan.isEsim = basketItem?.isEsim || false;
      updatedPlan.isLinxdot = basketItem?.isLinxdot || false;
      return updatedPlan;
    });

    if(!basketPlans.length) {
      return null;
    }

    return (
        <div className="basket__products-list-item">
          {basketPlans.map((plan) => (
              <NewBasketItem
                  plan={plan}
                  key={plan.id}
                  onChange={onBasketItemChange}
                  onDelete={onBasketItemDelete}
                  collapsible={!!checkout}
              />
          ))}
        </div>
    );
  };

  const setCoupon = (c) => {
    dispatch({
      type: basketTypes.SET_COUPON,
      payload: c
    });
  }

  useEffect(() => {
    getCouponFromLocalStorage().then(res => setCoupon(res));
  }, []);

  const discountAmount = useMemo(() => {
    return getDiscountAmount(coupon, totalSimPrice);
  }, [coupon, totalSimPrice]);

  const couponForm = () => {
    if(couponLoading) {
      return (
          <div className="basket__price-col__coupon-spinner">
            <Spinner />
          </div>
      );
    }

    if(coupon) {
      return (
        <div className="basket__price-col__coupon-active">
            <div className="basket__price-col__coupon-active-text">
              <b>{coupon.code}</b> <br />
              Yay! You saved <b>${discountAmount}</b> for <b>{coupon.monthCount}</b> {coupon.monthCount > 1 ? 'Months' : 'Month'}
            </div>
            <div className="basket__price-col__coupon-active-cross" onClick={() => {
              dispatch(createDispatchLog(ConversionTrackingActionNames.COUPON, {
                actionDataType: 'COUPON_REMOVE',
                data: null
              }));
              setCoupon(undefined);
              setCouponToLocalStorage(null);
            }}>
              <CrossIcon />
            </div>
        </div>
      );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="basket__price-col__coupon-form">
            <Input
                {...register('couponCode', {
                  required: true,
                })}
                type="text"
                placeholder="Enter promo code"
                containerClass="basket__price-col__coupon-input"
                isInvalid={!!errors.couponCode}
            />
            <button className="basket__price-col__coupon-button" type="submit">
              APPLY
            </button>
          </div>
        </form>
    );
  }

  const onSubmit = async ({couponCode}) => {
    setCouponLoading(true);
    try {
      const coupon = await canUseCoupon({
        code: couponCode,
        planIds: basketItems.filter(m => m.planId).map(m => m.planId),
        hardwareIds: basketItems.filter(m => m.hardwareId).map(m => m.hardwareId)
      });
      setCouponToLocalStorage(coupon?.data?.payload);
      if(coupon?.data?.payload) {
        setCoupon(coupon.data.payload);
        dispatch(createDispatchLog(ConversionTrackingActionNames.COUPON, {
          actionDataType: 'COUPON_ADD',
          data: coupon.data.payload
        }));
      } else {
        alert('No coupon applied!');
      }
    }
    catch (e) {
      alert('An error occurred while applying the coupon code');
    }

    setCouponLoading(false);
  };

  function renderBasketProps() {
    return (
        <div className="basket__products-props">
          <div className="basket__products-props__item">
            <img src={SSLIcon} alt="Secure Shopping" />
            <span>
              Secure shopping<br/>
              with SSL encryption
            </span>
          </div>
          <div className="basket__products-props__item">
            <img src={FiveGIcon} alt="5G" />
            <span>
              5G included<br/>
              on all Go Talk plans
            </span>
          </div>
          <div className="basket__products-props__item">
            <img src={DeliveryIcon} alt="Free 2 day delivery" />
            <span>
              Choose Esim or<br/>
              free 2 day delivery
            </span>
          </div>
        </div>
    );
  }

  const checkoutButtonText = paymentMethodType === "AFFIRM" ? "PAY WITH AFFIRM" : "PAY";

  return (
    <>
      <div className="basket">
        <section className="basket__products">
          {!checkout && (
              <>
                <h2 className="basket__title">Basket</h2>
                <div className="basket__sub-title">Your order list:</div>
              </>
          )}
          <SimsOutErrors errors={paymentErrors} plans={plans} />
          <div className="basket__products-container">
            <div className="basket__products-list">
              {renderBasketItems('sim')}
              {renderBasketItems('hardware')}
              {!checkout && !isSmallForBasketProps && renderBasketProps()}
              {checkout && <BillingForm isBasketEmpty={isBasketEmpty} onSubmit={(data) => data} checkout={true} /> }
              {checkout && <PaymentForm isBasketEmpty={isBasketEmpty} checkout={true} /> }
            </div>
            <div className={`basket__right ${checkout ? 'basket__sticky' : ''}`}>
              {(!checkout || (checkout && !isSmallForBasketProps)) && !isBasketEmpty ? (
                  <div className="basket__price-container">
                    <div className="basket__price-col coupon">
                      <div className="basket__price-col__coupon-title">Checkout</div>
                      <div className="basket__price-col__coupon-form-wrapper">
                        <div className="basket__price-col__coupon-form-wrapper--title">
                          Promo code
                        </div>
                        {couponForm()}
                      </div>
                    </div>
                    <hr />
                    <div className="basket__price-row">
                      <BasketDeliveryProvider />
                    </div>
                    <div className="basket__price-row">
                      <div className="basket__price-col">Lines:</div>
                      <div className="basket__price-col sum">{totalLines}</div>
                    </div>
                    <div className="basket__price-row">
                      <div className="basket__price-col">Hardware:</div>
                      <div className="basket__price-col sum">{totalHardwares}</div>
                    </div>
                    <BasketTaxesSurcharges checkout={checkout} discountAmount={discountAmount} />
                    {coupon && (
                        <div className="basket__price-row discount">
                          <div className="basket__price-col">Coupon Discount:</div>
                          <div className="basket__price-col sum">{`(-) $${discountAmount}`}</div>
                        </div>
                    )}
                    <div className="basket__price-row total">
                      <div className="basket__price-col">
                        Total: <br />
                        <small>Including all taxes and fees</small>
                      </div>
                      <div className="basket__price-col sum">{`$${(totalPrice - (discountAmount || 0)) + (tax?.taxSum || 0)}`}</div>
                    </div>
                    <div className="basket__price-row policy">
                      <p className="basket__price-row__policy-text">
                        By placing your order, you agree to our <a href="/terms-conditions" target="_blank">Terms & Conditions</a>, <a href="/return-policy" target="_blank">Refund Policy</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                      </p>
                    </div>
                    <Button
                        onClick={handleNavigateToBillingForm}
                        addClass="basket__button-next"
                        title={checkout ? checkoutButtonText : "CHECKOUT"}
                    />
                    <div className="basket__price-row__ptb">
                      <img src={StripeTrustBadge} alt="Stripe Secure Payment" className="basket__price-row__ptb__img" />
                    </div>
                  </div>
              ) : null}
              {(!checkout || (checkout && !isSmallForBasketProps)) && !isBasketEmpty ? (
                  <div className="basket__money-back">
                    <div className="basket__money-back__icon">
                      <img src={MoneyBackIcon} alt="7 DAY" />
                      <span className="basket__money-back__icon__text">
                      <b>7</b><br/>
                      DAY
                    </span>
                    </div>
                    <div className="basket__money-back__text">
                      MONEY-BACK<br/>
                      100% GUARANTEE
                    </div>
                  </div>
              ) : null}
              {!checkout && isSmallForBasketProps && !isBasketEmpty ? renderBasketProps() : null}
            </div>
          </div>
        </section>
      </div>
      {!checkout && <ReviewsCarousel style={{padding: '20px 0'}}/>}
      {!checkout && <TrustBadge />}
    </>
  );
};

Basket.defaultProps = {
  setBasketEmpty: () => {},
};

Basket.propTypes = {
  setBasketEmpty: PropTypes.func,
};

export default Basket;
