import './dropdown.scss';
import {useEffect, useState} from "react";
import ChevronDownIcon from "../../../../assets/images/hardwares/chevron-down.svg";

export default function Dropdown({label, options, onClickItem}) {
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        const onDocumentClick = (ev) => {
            if(!opened) {
                return;
            }

            const closestItem = ev.target.closest('.hwl-dropdown');
            if(!closestItem) {
                setOpened(false);
            }
        }

        document.addEventListener('click', onDocumentClick);

        return () => document.removeEventListener('click', onDocumentClick);
    }, [opened, setOpened]);

    return (
      <div className={`hwl-dropdown ${opened && 'opened'}`}>
          <div className="hwl-dropdown--title" onClick={() => setOpened(s => !s)}>
              {label}
              <img src={ChevronDownIcon} className="hwl-dropdown--opener" alt="Dropdown opener" />
          </div>
          {opened && (
              <div className="hwl-dropdown--list">
                  {options.map(item => (
                      <div key={item.id} className="hwl-dropdown--list--item" onClick={() => {
                          onClickItem(item);
                          setOpened(false);
                      }}>
                          {item.label}
                      </div>
                  ))}
              </div>
          )}
      </div>
    );
}
