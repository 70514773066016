import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ChangePlan from "../change-plan/change-plan";
import ChangePlanPayment from "../change-plan-payment/change-plan-payment";
import Spinner from "../ui-component/spinner/spinner";
import {useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import accountTypes from "../../../redux/workers/account/account-types";
import './change-plan-container.scss';

function ChangePlanContainer({ currentProductId, currentPlanId }, ref) {

    const [showPlanSelect, setShowPlanSelect] = useState(false);
    const [showChangePlanPayment, setShowChangePlanPayment] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const confirmChangePlanLoading = useSelector((state) => state.loadingReducer.confirmChangePlanLoading);
    const changePlanResponse = useSelector((state) => state.accountReducer.changePlanResponse);
    useEffect(() => {
        if(changePlanResponse) {
            setShowPlanSelect(false);
            if(changePlanResponse.stripId) {
                setShowChangePlanPayment(true);
            }
        }
    }, [changePlanResponse]);

    useEffect(() => {
        handleInitial();
    }, []);

    function handleInitial() {
        if(!searchParams.get('payId')) {
            return;
        }

        let status = 'success';
        let errorMessage = "";

        if(!searchParams.get('payment_intent_client_secret') || !searchParams.get('payment_intent')) {
            status = 'fail';
            errorMessage = "Invalid request!";
        }

        if(searchParams.get('redirect_status') !== 'succeeded') {
            status = 'fail';
            errorMessage = "Payment has been failed!";
        }

        switch (status) {
            case 'success':
                dispatch({
                    type: accountTypes.CONFIRM_CHANGE_PLAN,
                    data: {
                        payId: searchParams.get('payId')
                    }
                });
                break;

            case 'fail':
                toast.error(errorMessage, {
                    position: "top-right"
                });
                break;
        }

        searchParams.delete('payId');
        searchParams.delete('payment_intent_client_secret');
        searchParams.delete('payment_intent');
        searchParams.delete('redirect_status');
        setSearchParams(searchParams);
    }

    useImperativeHandle(
        ref,
        () => ({
            open: () => {
                setShowPlanSelect(true);
                setShowChangePlanPayment(false);
            },
            close: () => {
                setShowPlanSelect(false);
                setShowChangePlanPayment(false);
            }
        })
    );

    return (
        <>
            {showPlanSelect && (
                <ChangePlan
                    currentProductId={currentProductId}
                    currentPlanId={currentPlanId}
                    onClose={() => setShowPlanSelect(false)}
                />
            )}
            {showChangePlanPayment && (
                <ChangePlanPayment
                    onClose={() => setShowChangePlanPayment(false)}
                    productId={currentProductId}
                />
            )}
            {confirmChangePlanLoading && (
                <Spinner addClass="change-plan-container--spinner" />
            )}
        </>
    )
}

export default forwardRef(ChangePlanContainer);
