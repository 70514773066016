import './payment-success.scss';
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import routes from "../../../navigation/routes";
import {setCouponToLocalStorage} from "../../../shared/basketActions";
import authTypes from "../../../redux/workers/auth/auth-types";
import actionsTypes from "../../../redux/workers/account/account-types";
import {useDispatch, useSelector} from "react-redux";
import SmileIcon from "../../../assets/images/icons/smile.svg";
import Button from "../ui-component/button/button";
import {getAccountData} from "../../../redux/workers/account";
import {createDispatchLog} from "../../../redux/workers/conversion-tracking";
import {ConversionTrackingActionNames} from "../../../types/conversionTrackingActionNames";
import basketTypes from "../../../redux/workers/basket/basket-types";

function PaymentSuccessWithCheckoutDatas() {
    const location = useLocation();
    const { checkoutDatas } = location.state;
    const navigate = useNavigate();
    const {isSignedIn} = useSelector(state => state.authReducer);
    const dispatch = useDispatch();

    function goToPlanPage() {
        setCouponToLocalStorage(null);
        if(!isSignedIn) {
            dispatch({
                type: authTypes.SIGN_IN,
                payload: {
                    userData: {
                        email: checkoutDatas.user.email,
                        password: checkoutDatas.user.password
                    },
                    userSimPlanId: checkoutDatas.userSimPlanId,
                    userHardwareId: checkoutDatas.userHardwareId,
                    simType: checkoutDatas.simType,
                    redirect: navigate
                }
            });
        } else {
            if(checkoutDatas.userSimPlanId) {
                dispatch({
                    type: actionsTypes.LOAD_CURRENT_PRODUCT,
                    id: checkoutDatas.userSimPlanId,
                    productType: 'sim'
                });
                navigate(`${routes.account.base}/${routes.account.tracker}/${checkoutDatas.simType}?id=${checkoutDatas.userSimPlanId}`);
            }
            else if(checkoutDatas.userHardwareId) {
                dispatch({
                    type: actionsTypes.LOAD_CURRENT_PRODUCT,
                    id: checkoutDatas.userHardwareId,
                    productType: 'hardware'
                });
                navigate(`${routes.account.base}/${routes.account.hardwares}?id=${checkoutDatas.userHardwareId}`);
            }
        }
    }

    return (
        <>
            <p className="payment-success__subtitle">{location.state?.message}</p>
            <Button
                addClass="payment-success__submit"
                title={checkoutDatas?.userSimPlanId ? 'GO TO MY NEW SIM' : 'GO TO MY NEW HARDWARE'}
                onClick={() => goToPlanPage()}
            />
        </>
    );
}

function PaymentSuccessWithAffirm() {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const {isSignedIn} = useSelector(state => state.authReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createDispatchLog(ConversionTrackingActionNames.BUY, {
            actionDataType: 'BUY_SUCCESS',
            data: {
                email: query.get('email')
            }
        }));

        dispatch({
            type: basketTypes.BASKET_UPDATE,
            payload: {
                totalCount: 0,
                totalPrice: 0,
                totalSimPrice: 0,
                totalLines: 0,
                totalHardwares: 0,
            },
        });

        localStorage.setItem('basket', JSON.stringify([]));
    }, []);

    async function goToPlanPage() {
        setCouponToLocalStorage(null);

        if(!isSignedIn) {
            window.localStorage.setItem('got-accessToken', query.get('token'));
            const userData = await getAccountData();
            dispatch({ type: authTypes.SIGN_IN_SUCCESS, payload: userData.data.payload });
            dispatch({ type: authTypes.SIGNED_IN });
        }

        if(query.get('usp')) {
            dispatch({
                type: actionsTypes.LOAD_CURRENT_PRODUCT,
                id: query.get('usp'),
                productType: 'sim'
            });
            navigate(`${routes.account.base}/${routes.account.tracker}/${query.get('st')}?id=${query.get('usp')}`);
        }
        else if(query.get('uhd')) {
            dispatch({
                type: actionsTypes.LOAD_CURRENT_PRODUCT,
                id: query.get('uhd'),
                productType: 'hardware'
            });
            navigate(`${routes.account.base}/${routes.account.hardwares}?id=${query.get('uhd')}`);
        }
    }

    return (
        <>
            <p className="payment-success__subtitle">
                We’ll email {query.get('email')} a receipt for each invoice
            </p>
            <Button
                addClass="payment-success__submit"
                title={query.get('usp') ? 'GO TO MY NEW SIM' : 'GO TO MY NEW HARDWARE'}
                onClick={() => goToPlanPage()}
            />
        </>
    );
}

export default function PaymentSuccess() {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        if(!query.get('affirm') && !location.state?.success) {
            navigate(routes.home);
        }
    }, []);

    return (
        <div className="payment-success">
            <img className="payment-success__icon" src={SmileIcon} alt="Smiling face" />
            <h2 className="payment-success__title">
                Payment successful!
            </h2>

            {location.state?.checkoutDatas ? (
                <PaymentSuccessWithCheckoutDatas />
            ) : (
                <PaymentSuccessWithAffirm />
            )}
        </div>
    );
}
