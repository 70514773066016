import './new-basket-item.scss';
import React, {useEffect, useMemo, useState} from "react";
import messageVectorIndigo from "../../../../assets/images/icons/message-vector-indigo.svg";
import messageVectorWhite from "../../../../assets/images/icons/message-vector-white.svg";
import minusWhite from "../../../../assets/images/icons/minus-white.svg";
import plusWhite from "../../../../assets/images/icons/plus-white.svg";
import trashBlack from "../../../../assets/images/icons/trash-black.svg";
import routes from "../../../../navigation/routes";
import Switch from "../switch/switch";
import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import {ReactComponent as ChevronRightIcon} from "../../../../assets/images/icons/chevron-right.svg";
import {createDispatchLog} from "../../../../redux/workers/conversion-tracking";
import {ConversionTrackingActionNames} from "../../../../types/conversionTrackingActionNames";
import {getBasketItems} from "../../../../shared/basketActions";
import {useDispatch} from "react-redux";
import {isUnlimited} from "../../../../utils/utils";

export default function NewBasketItem({plan, onChange, onDelete, collapsible}) {

    const isMobile = useMediaQuery('(max-width: 680px)');

    const [itemData, setItemData] = useState({
        planId: plan.id,
        count: plan.count,
        isEsim: plan.isEsim,
        isLinxdot: plan.isLinxdot
    });
    const [collapsed, setCollapsed] = useState(!!collapsible);
    const dispatch = useDispatch();
    const isUnlimitedPlan = isUnlimited(plan.SMSCount) && isUnlimited(plan.minuteCount);
    const hasSmsMinute = plan.planType === 'DEFAULT';

    useEffect(() => {
        if (onChange) {
            onChange(itemData);
        }
    }, [itemData, onChange]);

    const handleRemove = () => {
        if (itemData.count > 1) {
            setItemData({ ...itemData, count: itemData.count - 1 });
        }
    };

    const handleAddition = () => {
        setItemData({ ...itemData, count: itemData.count + 1 });
        setTimeout(() => {
            dispatch(createDispatchLog(ConversionTrackingActionNames.BASKET, {
                actionDataType: 'BASKET_UPDATE',
                data: {
                    items: getBasketItems()
                }
            }));
        }, 1000);
    };

    const handleSwitch = (isChecked) => {
        setItemData({ ...itemData, isEsim: isChecked });
    };

    const handleDeletion = () => {
        if (onDelete) {
            onDelete(plan.id, 'sim');
            setTimeout(() => {
                dispatch(createDispatchLog(ConversionTrackingActionNames.BASKET, {
                    actionDataType: 'BASKET_UPDATE',
                    data: {
                        items: getBasketItems()
                    }
                }));
            }, 1000);
        }
    };

    const infoList = useMemo(() => {
        let output = [];

        if(hasSmsMinute) {
            if(isUnlimitedPlan) {
                output.push({type: 'vpn', text: `<span>Unlimited</span> Minutes and SMS`});
            } else {
                output.push({type: 'vpn', text: `<span>${plan.minuteCount}</span> Minutes and <span>${plan.SMSCount}</span> SMS`});
            }
        }

        if(plan.costBuyPlan >= 30) {
            output = [
                ...output,
                {type: 'vpn', text: 'Free VPN (Mobile and Desktop apps)'},
                {type: 'vpn', text: 'Anti-Phishing tools'},
                {type: 'vpn', text: 'ID Theft Protection'}
            ];
        }

        return [
            ...output,
            ...plan.props?.info.filter(m => m).map(m => {
                if(m.trim().toLowerCase() === 'use your phone overseas') {
                    return {
                        type: 'info',
                        text: `${m}*`
                    };
                }
                return {
                    type: 'info',
                    text: m
                };
            })
        ];
    }, [plan.id, isUnlimitedPlan, hasSmsMinute]);

    const price = useMemo(() => {
        return (plan.hasDiscount && !plan.isLinxdot) ? plan.discountedPrice : plan.costBuyPlan;
    }, [plan.id]);

    const discountPercentage = useMemo(() => {
        if(!plan.hasDiscount || plan.isLinxdot) {
            return 0;
        }

        return (plan.costBuyPlan - plan.discountedPrice) / plan.costBuyPlan * 100;
    }, [plan.id]);

    const showDetails = collapsible && !collapsed || !collapsible;

    function renderOfferProps() {
        if(!showDetails) {
            return null;
        }
        return (
            <div className="new-basket-item__props">
                <div className="new-basket-item__props__title">
                    We offer you:
                </div>
                {infoList.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <div className="new-basket-item__props__item">
                            <div className="new-basket-item__props__item__icon">
                                <img src={messageVectorIndigo} />
                                <img src={messageVectorWhite} className="hover" />
                            </div>
                            <div className="new-basket-item__props__item__text" dangerouslySetInnerHTML={{
                                __html: item.text
                            }} />
                        </div>
                        {(idx === 3) && (
                            <div className="new-basket-item__props__plus">
                                Plus
                            </div>
                        )}
                    </React.Fragment>
                ))}
                {plan.isLinxdot && (
                    <div className="new-basket-item__props__highlight">* Free Linxdot Helium Hotspot Offer (12 Months)</div>
                )}
                <div className="new-basket-item__props__asterisk">
                    <b>*</b> Extra charges apply for use of phone services overseas and domestic allowance is not included
                </div>
            </div>
        );
    }

    function renderRightBottom() {
        if(!showDetails) {
            return null;
        }
        return (
            <>
                <div className="new-basket-item__type">
                    <div className="new-basket-item__type__title">
                        SIM card type
                    </div>
                    <div className="new-basket-item__type__desc">
                        {itemData.isEsim ? (
                            <>Connect within 5 minutes <a href={routes.worksNew}>(Learn more)</a></>
                        ) : (
                            <>Your sim will arrive via USPS within 2 – 4 business days</>
                        )}
                    </div>
                    <div className="new-basket-item__type__switch">
                        <Switch
                            checked={itemData.isEsim}
                            onChange={handleSwitch}
                            addClass="new-basket-item__type__switch__text"
                            label="Order an eSim"
                        />
                    </div>
                </div>
                <div className="new-basket-item__price">
                    <div className="new-basket-item__price__title">
                        {(plan.hasDiscount && !plan.isLinxdot) ? 'New customer offer:' : 'Offer:'}
                    </div>
                    <div className="new-basket-item__price-row">
                        <div className="new-basket-item__price-unit">$</div>
                        <div className="new-basket-item__price-cost">{price}</div>
                        <div className="new-basket-item__price-monthly">
                            <div className="new-basket-item__price-monthly--text">
                                Monthly / Per User <br />
                                {(plan.hasMinMonthLimit && !plan.isLinxdot) && `First ${plan.minMonthLimit} months`}
                            </div>
                            {(plan.hasDiscount && !plan.isLinxdot) && (
                                <div className="new-basket-item__price-monthly--pill">
                                    SAVE {discountPercentage.toFixed(2).replace(/\.00$/, '')}%
                                </div>
                            )}
                        </div>
                    </div>
                    {(plan.hasMinMonthLimit && !plan.isLinxdot) && (
                        <div className="new-basket-item__price-min-month">
                            Total cost: ${price * plan.minMonthLimit} for the first {plan.minMonthLimit} months
                        </div>
                    )}
                </div>
            </>
        );
    }

    return (
        <div className="new-basket-item">
            <div className="new-basket-item__row">
                <div className="new-basket-item__row__left">
                    <div className="new-basket-item__title" dangerouslySetInnerHTML={{
                        __html: plan.name
                    }} />

                    {showDetails && (
                        <>
                            {plan.slogan && (
                                <div className="new-basket-item__slogan">
                                    {plan.slogan}
                                </div>
                            )}

                            <div className="new-basket-item__price small">
                                <div className="new-basket-item__price-row">
                                    <div className="new-basket-item__price-unit">$</div>
                                    <div className="new-basket-item__price-cost">{plan.costBuyPlan}</div>
                                    <div className="new-basket-item__price-monthly">
                                        <div className="new-basket-item__price-monthly--text">
                                            Monthly <br />
                                            Per User
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {!isMobile && renderOfferProps()}
                </div>
                <div className="new-basket-item__row__right">
                    <div className="new-basket-item__quantity">
                        <div className="new-basket-item__quantity__changer">
                            <div className="new-basket-item__quantity__changer__text">
                                Quantity:
                            </div>
                            <img src={minusWhite} className="new-basket-item__quantity__changer__icon" onClick={() => handleRemove()} />
                            <span className="new-basket-item__quantity__changer__count">{itemData.count}</span>
                            <img src={plusWhite} className="new-basket-item__quantity__changer__icon" onClick={() => handleAddition()} />
                        </div>
                        <div className="new-basket-item__quantity__trash">
                            <img src={trashBlack} className="new-basket-item__quantity__trash__icon" onClick={() => handleDeletion()} />
                        </div>
                        {collapsible ? (
                            <ChevronRightIcon
                                className={`new-basket-item__quantity__collapser ${collapsed ? 'collapsed' : ''}`}
                                onClick={() => setCollapsed(v => !v)}
                            />
                        ) : null}
                    </div>
                    {showDetails && (
                        <div className="new-basket-item__data_container">
                            <div className="new-basket-item__data">
                                <div className={`new-basket-item__data__text ${plan.internetCount.value?.toString().length >= 3 ? 'small' : ''}`}>
                                    <b>{plan.internetCount.value}<small>{plan.internetCount.unit}</small></b>
                                    <span>4G / 5G Data</span>
                                </div>
                                <img src={messageVectorIndigo} />
                            </div>
                            {hasSmsMinute ? (
                                isUnlimitedPlan ? (
                                    <div className="new-basket-item__data">
                                        <div className="new-basket-item__data__text">
                                            <b className="new-basket-item__data__text__infinite">∞</b>
                                            <span>Minutes & SMS</span>
                                        </div>
                                        <img src={messageVectorIndigo} />
                                    </div>
                                ) : (
                                    <div className="new-basket-item__data">
                                        <div className="new-basket-item__data__text" style={{marginTop: 0}}>
                                            <b className="new-basket-item__data__text__small">
                                                {plan.minuteCount} & {plan.SMSCount}
                                            </b>
                                            <span>Minutes & SMS</span>
                                        </div>
                                        <img src={messageVectorIndigo} />
                                    </div>
                                )
                            ) : null}
                        </div>
                    )}
                    {!isMobile && renderRightBottom()}
                </div>
            </div>
            {isMobile && renderOfferProps()}
            {isMobile && renderRightBottom()}
        </div>
    );
}
