import './hardware-listing-products.scss';
import React, {useContext} from "react";
import {HardwareListingContext} from "../../../../contexts/hardware-listing-context/hardware-listing-context";
import HardwareListingBanner from "../hardware-listing-banner/hardware-listing-banner";
import HardwareListingProductItem from "../hardware-listing-product-item/hardware-listing-product-item";
import Spinner from "../../ui-component/spinner/spinner";

export default function HardwareListingProducts() {
    const { products, loading } = useContext(HardwareListingContext);

    if(loading) {
        return (
            <div className="hwl-products">
                <div className="hwl-products--loading">
                    <Spinner />
                </div>
            </div>
        )
    }

    return (
        <div className="hwl-products">
            {products.map((product, pIdx) => (
                <React.Fragment key={product.id}>
                    <div className="hwl-products--item">
                        <HardwareListingProductItem product={product} />
                    </div>
                    {pIdx === 5 && <HardwareListingBanner />}
                </React.Fragment>
            ))}
            {products.length < 6 && <HardwareListingBanner />}
        </div>
    );
}
