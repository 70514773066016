import './payment-authorize.scss';
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import Input from "../../../ui-component/input/input";
import StripeTrustBadge from "../../../../../assets/images/payment/stripe-trust-badge.png";
import * as yup from 'yup';
import valid from 'card-validator';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "../../../../../utils/payment-form-utils";
import {useDispatch, useSelector} from "react-redux";
import paymentTypes from "../../../../../redux/workers/payment/payment-types";
import get from "lodash/get";

const validationSchema = yup.object().shape({
    cardHolder: yup.string().test('test-holder', 'Card holder name is invalid', value => valid.cardholderName(value).isValid).required(),
    cardNumber: yup.string().test('test-number', 'Credit Card number is invalid', value => valid.number(value).isValid).required(),
    expirationDate: yup.string().test('test-expiration-date', 'Expiration date is invalid', value => valid.expirationDate(value).isValid).required(),
    cvv: yup.string().test('test-cvv', 'Card CVV is invalid', value => valid.cvv(value, 4).isValid || valid.cvv(value, 3).isValid).required(),
});

const PaymentAuthorize = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const {checkoutDatas} =  useSelector(state => state.payment);

    const {
        register,
        handleSubmit: hookFormHandleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { cardNumber, expirationDate, cvv } = watch();

    useEffect(() => {
        const formatted = formatCreditCardNumber(cardNumber);
        setValue('cardNumber', formatted);
    }, [cardNumber]);

    useEffect(() => {
        const formatted = formatExpirationDate(expirationDate);
        setValue('expirationDate', formatted);
    }, [expirationDate]);

    useEffect(() => {
        const formatted = formatCVC(cvv);
        setValue('cvv', formatted);
    }, [cvv]);

    const handleCheckoutDatas = (req) => {
        const {
            checkout
        } = req;

        let payIds, email;
        if(checkout) {
            payIds = checkoutDatas.payIds;
            email = checkoutDatas.user.email;
        }
        else {
            payIds = get(location, 'state.payIds', '');
            email = get(location, 'state.user.email', '');
        }

        dispatch({
            type: paymentTypes.BUY_PlAN_CHECK,
            payIds,
            email,
        });
    }

    useImperativeHandle(
        ref,
        () => ({
            handleSubmit: () => {
                return new Promise((resolve, reject) => {
                    hookFormHandleSubmit(formValues => {
                        resolve(formValues);
                    }, () => {
                        resolve(null);
                    })();
                });
            },
            handleCheckoutDatas
        })
    );

    return (
        <div className="payment-form__card-container">
            <div className="payment-form__card-side front">
                <div className="payment-form__field-container">
                    <p className="payment-form__field-label">Credit card number</p>
                    <Input
                        {...register('cardNumber')}
                        maxLength="19"
                        containerClass="payment-form__input"
                        type="numeric"
                        autoComplete="cc-number"
                        inputMode="numeric"
                        placeholder="0000 0000 0000 0000"
                        isInvalid={!!errors?.cardNumber}
                    />
                </div>
                <div className="payment-form__row">
                    <div className="payment-form__field-container holder">
                        <p className="payment-form__field-label">Card Holder name</p>
                        <Input
                            {...register('cardHolder')}
                            containerClass="payment-form__input"
                            placeholder="Enter credit holder name"
                            type="text"
                            autoComplete="cc-name"
                            isInvalid={!!errors?.cardHolder}
                        />
                    </div>
                    <div className="payment-form__field-container">
                        <p className="payment-form__field-label">Expiration Date</p>
                        <Input
                            {...register('expirationDate')}
                            containerClass="payment-form__input"
                            placeholder="AA/YY"
                            type="text"
                            isInvalid={!!errors?.expirationDate}
                            maxLength="5"
                        />
                    </div>
                </div>
            </div>
            <div className="payment-form__card-side back">
                <div className="payment-form__cvv-background" />
                <div className="payment-form__field-container cvv">
                    <p className="payment-form__field-label">CVC</p>
                    <Input
                        {...register('cvv')}
                        containerClass="payment-form__input"
                        placeholder="CVC"
                        type="text"
                        isInvalid={!!errors?.cvv}
                        maxLength="4"
                        inputMode="numeric"
                    />
                </div>
            </div>
            <div className="payment-form__card__trustbadge">
                <img src={StripeTrustBadge} alt="Stripe Secure Payment" className="payment-form__card__trustbadge-img"/>
            </div>
        </div>
    );
});

export default PaymentAuthorize;
