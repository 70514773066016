export default {
  home: '/',
  signUp: '/sign-up',
  signIn: {
    base: '/sign-in',
    multiFactor: 'multi-factor'
  },
  restorePassword: {
    base: '/restore-password',
    email: 'email',
    reset: 'reset',
    resetSuccess: 'success',
  },
  account: {
    base: '/account',
    edit: {
      base: 'edit',
      registerYubikeyDevice: 'register-yubikey-device',
      yubikeyRegistrationSuccessful: 'yubikey-registration-successful',
      verificationEmailCode: 'verification-email-code',
      verificationEmailSuccessful: 'verification-email-successful',
      registerOtp: 'register-otp',
      registerOtpSuccessful: 'register-otp-successful'
    },
    tariffInfo: 'tariff-info',
    myPlan: 'my-plan',
    plans: 'plans',
    tracker: 'tracker',
    history: {
      base: 'history',
      payment: '/account/history/payment',
      calls: '/account/history/calls',
      sms: '/account/history/sms',
    },
    vpnUser: 'vpn-user',
    oldCustomer: 'old-customer',
    hardwares: 'hardwares'
  },
  tariff: '/tariff',
  faq: '/faq',
  plans: '/plans',
  works: '/how-it-works',
  worksNew: '/why-change-carrier',
  termsConditions: '/terms-conditions',
  support: '/support',
  privacy: '/privacy-policy',
  returnPolicy: '/return-policy',
  acceptable: '/acceptable',
  protect: '/protect',
  setupIntentResult: '/setup-intent-result',
  outerActivation: '/activate',
  bag: '/bag',
  billingDetails: '/bag/billing-details',
  payment: '/bag/payment',
  checkout: '/bag/checkout',
  blog: '/blog',
  linxdot: '/linxdot',
  tmoCoverageForApp: '/tmo-coverage-for-app',
  paymentSuccess: '/bag/payment-success',
  landingPage: '/landing',
  hardwares: {
    base: '/hardware',
    cellPhones: '/cell-phones',
    cellPhoneDetail: '/cell-phones/:slugUrl'
  },
  privateWireless: '/private-wireless'
};
