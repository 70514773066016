import './private-wireless-reason-to-deploy.scss';
import {useState} from "react";
import Reason1Icon from '../../../../assets/images/private-wireless/reason-1.svg';
import Reason2Icon from '../../../../assets/images/private-wireless/reason-2.svg';
import Reason3Icon from '../../../../assets/images/private-wireless/reason-3.svg';
import Reason4Icon from '../../../../assets/images/private-wireless/reason-4.svg';
import RightArrowIcon from '../../../../assets/images/private-wireless/right-arrow.svg';
import ReasonBanner1Image from '../../../../assets/images/private-wireless/reason-banner-1.png';
import useMediaBelow from "../../../../shared/hooks/useMediaBelow";

export default function PrivateWirelessReasonToDeploy() {
    const isMobile = useMediaBelow(1030);
    const [selectedReasonIndex, setSelectedReasonIndex] = useState(0);

    const reasons = [
        {
            title: 'Increased security',
            icon: Reason1Icon,
            banner: ReasonBanner1Image,
            content: 'Private networks are physically isolated from public networks, reducing the risk of unauthorized access or hacking. Private LTE/5G networks use strong encryption algorithms to protect data in transit, making it difficult for unauthorized parties to intercept or tamper with the data.'
        },
        {
            title: 'Reduced dependence',
            icon: Reason2Icon,
            banner: ReasonBanner1Image,
            content: 'Private networks can be used as an alternative to public networks in areas with poor coverage, ensuring a more consistent and reliable communication experience.'
        },
        {
            title: 'Improved reliability',
            icon: Reason3Icon,
            banner: ReasonBanner1Image,
            content: 'Private networks can be designed with specific coverage and capacity requirements, leading to improved network reliability.'
        },
        {
            title: 'Increased control',
            icon: Reason4Icon,
            banner: ReasonBanner1Image,
            content: 'With a private network, the owner has full control over network configurations, security policies, and service quality, and can make changes quickly, efficiently.'
        },
    ];
    const activeReason = reasons[selectedReasonIndex];

    function goNext() {
        setSelectedReasonIndex(s => s + 1 === reasons.length ? 0 : s + 1);
    }

    return (
        <div className="pw-reason">
            <h2 className="pw-reason--title">
                REASON TO DEPLOY PRIVATE LTE/5G
            </h2>
            <div className="pw-reason--content">
                {isMobile ? (
                    <div className="pw-reason--content-left">
                        <div className="pw-reason--mobile-boxes">
                            {reasons.map((reason, rIdx) => (
                                <div key={rIdx.toString()} className="pw-reason--mobile-box">
                                    <div className="pw-reason--mobile-box-image">
                                        <img src={reason.icon} alt={reason.title} />
                                    </div>
                                    <div className="pw-reason--mobile-box-title">
                                        {reason.title}
                                    </div>
                                    <div className="pw-reason--mobile-box-content">
                                        {reason.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="pw-reason--content-left">
                        <div className="pw-reason--boxes">
                            {reasons.map((reason, rIdx) => (
                                <div key={rIdx.toString()}
                                     className={`pw-reason--box ${rIdx === selectedReasonIndex ? 'active' : ''}`}
                                     onClick={() => setSelectedReasonIndex(rIdx)}>
                                    <div className="pw-reason--box-title">
                                        {reason.title}
                                    </div>
                                    <div className="pw-reason--box-image">
                                        <img src={reason.icon} alt={reason.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="pw-reason--reason-content">
                            <div className="pw-reason--reason-content--title">{activeReason.title}</div>
                            <div className="pw-reason--reason-content--content">{activeReason.content}</div>
                            <div>
                                <button type="button" className="pw-reason--connect-button">
                                    CONNECT ME
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="pw-reason--content-right">
                    <div className="pw-reason--slider">
                        {reasons.map((reason, rIdx) => (
                            <div key={rIdx.toString()}
                                 className={`pw-reason--slider-item ${rIdx === selectedReasonIndex ? 'active' : ''}`}
                                style={{
                                    left: rIdx === selectedReasonIndex ? 0 : (isMobile ? window.innerWidth : 500)
                                }}>
                                <img src={reason.banner} alt={reason.title} />
                            </div>
                        ))}

                        <div className="pw-reason--slider-next" role="button" onClick={goNext}>
                            NEXT <img src={RightArrowIcon} alt="Go next" />
                        </div>

                        <div className="pw-reason--slider-index">
                            {selectedReasonIndex >= 9 ? selectedReasonIndex : `0${selectedReasonIndex + 1}`}
                            <small>/{reasons.length >= 10 ? reasons.length : `0${reasons.length}`}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
