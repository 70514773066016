import {useNavigate} from "react-router-dom";
import routes from "../../../../../navigation/routes";
import React, {useEffect} from "react";
import PopupSuccessful from "../../../../components/ui-component/popup-successful/popup-successful";
import Button from "../../../../components/ui-component/button/button";
import './otp-verification-successful.scss';
import {useSelector} from "react-redux";
import get from "lodash/get";

export default function AccountEditOtpVerificationSuccessful() {
    const navigate = useNavigate();

    const close = () => navigate(`${routes.account.base}/${routes.account.edit.base}`);

    const handleDone = () => navigate(`${routes.account.base}/${routes.account.edit.base}`);

    const {
        accountData
    } = useSelector(state => ({
        accountData: state.accountReducer.accountData,
    }));
    const otpFactor = get(accountData, 'multiFactors.otpFactor', false);

    useEffect(() => {
        setTimeout(handleDone, 5000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PopupSuccessful close={close}>
            <div className="account-edit-otp-successful">
                <h6 className="account-edit-otp-successful__title">
                    {otpFactor ? (
                        'Authenticator App OTP Registration Successful'
                    ) : (
                        'Authenticator App OTP Disconnection Successful'
                    )}
                </h6>

                <Button addClass="account-edit-otp-successful__btn" title="Done" onClick={handleDone} />
            </div>
        </PopupSuccessful>
    );
}
