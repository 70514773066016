import './hardware-detail-side-infos.scss';
import { useContext, useEffect, useState } from 'react';
import { HardwareDetailContext } from '../../../../contexts/hardware-detail-context/hardware-detail-context';

export default function HardwareDetailSideInfos() {
  const {
    productDetail,
    addHardwareToBasket,
    buyWithAffirm,
    isMobile,
    onConditionChange,
    onStorageChange,
    onVariantChange,
    selectedCondition,
    conditions,
    storages,
    selectedVariant,
    selectedStorage,
    selectablePlans,
    selectedPlanId,
    onPlanChange,
  } = useContext(HardwareDetailContext);

  const [deliveryMessage, setDeliveryMessage] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      // Here, you can use a library like moment-timezone to handle timezone conversions more accurately
      const estTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      const dayOfWeek = estTime.getDay();
      const cutOffHour = 15; // 3 PM in EST

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        // Monday to Friday
        const cutOffTimeToday = new Date(estTime);
        cutOffTimeToday.setHours(cutOffHour, 0, 0, 0);

        if (estTime < cutOffTimeToday) {
          const timeDiff = cutOffTimeToday - estTime;
          const hours = Math.floor(timeDiff / 3600000);
          const minutes = Math.floor((timeDiff % 3600000) / 60000);

          setDeliveryMessage(
            `Order within ${hours} hours and ${minutes} minutes for shipping today.`
          );
        } else {
          let dayName = getNextDayName(estTime);
          if (dayName === 'Sunday' || dayName === 'Saturday') {
            dayName = 'Monday';
          }
          setDeliveryMessage(`Order now for Shipping on ${dayName}`);
        }
      } else {
        setDeliveryMessage('Order now for Shipping on Monday');
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function getNextDayName(estTime) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayIndex = estTime.getDay();
    const nextDayIndex = (dayIndex + 1) % 7;
    return daysOfWeek[nextDayIndex];
  }

  function changeCondition(idx) {
    onConditionChange(idx);
  }

  function changeStorage(idx) {
    onStorageChange(idx);
  }

  function changeVariant(idx) {
    onVariantChange(idx);
  }

  const price =
    (selectedStorage?.hasDiscount ? selectedStorage.discountedPrice : selectedStorage?.price) || 0;

  const stockText =
    selectedStorage?.stock > 0
      ? 'In Stock'
      : selectedStorage?.stock === 0
      ? 'Pre Order'
      : 'Out of Stock';

  useEffect(() => {
    if (price) {
      setTimeout(() => window.affirm.ui.refresh(), 100);
    }
  }, [price]);

  return (
    <div className="hwd-side-infos">
      {!isMobile ? <h1 className="hwd-side-infos--title">{productDetail.title}</h1> : null}
      <div className="hwd-side-infos--pills">
        <div className="hwd-side-infos--pills--title">
          Condition: <span>{selectedCondition?.conditionTitle || ''}</span>
        </div>
        <div className="hwd-side-infos--pills--items">
          {conditions.map((m, i) => (
            <div
              className={`hwd-side-infos--pills--item ${
                m.conditionTitle === selectedCondition?.conditionTitle ? 'active' : ''
              }`}
              key={m.conditionTitle}
              onClick={() => changeCondition(i)}
            >
              {m.conditionTitle}
            </div>
          ))}
        </div>
      </div>
      <div className="hwd-side-infos--pills">
        <div className="hwd-side-infos--pills--title">
          Storage:{' '}
          <span>
            {selectedStorage?.storageSize || ''} {selectedStorage?.storageUnit || ''}
          </span>
        </div>
        <div className="hwd-side-infos--pills--items">
          {storages.map((m, i) => (
            <div
              className={`hwd-side-infos--pills--item ${
                m.storageSize === selectedStorage?.storageSize ? 'active' : ''
              }`}
              key={m.storageSize}
              onClick={() => changeStorage(i)}
            >
              {m.storageSize} {m.storageUnit}
            </div>
          ))}
        </div>
      </div>
      <div className="hwd-side-infos--pills">
        <div className="hwd-side-infos--pills--title">
          Color: <span>{selectedVariant?.title || ''}</span>
        </div>
        <div className="hwd-side-infos--pills--items">
          {productDetail.HardwareVariants.map((m, i) => (
            <div
              className={`hwd-side-infos--pills--colors-item ${
                m.color === selectedVariant?.color ? 'active' : ''
              }`}
              key={i}
              onClick={() => changeVariant(i)}
            >
              <span style={{ backgroundColor: m.color }} />
            </div>
          ))}
        </div>
      </div>

      <div className="hwd-side-infos--pills">
        <div className="hwd-side-infos--pills--title">Pick Your Wireless Plan</div>
        <div className="hwd-side-infos--pills--items">
          <div
            className={`hwd-side-infos--pills--plan-item ${selectedPlanId === 0 ? 'active' : ''}`}
            onClick={() => onPlanChange(0)}
          >
            No Plan <br />
            <span>Phone Only</span>
          </div>
          {selectablePlans.map((m) => (
            <div
              className={`hwd-side-infos--pills--plan-item ${
                selectedPlanId === m.id ? 'active' : ''
              }`}
              onClick={() => onPlanChange(m.id)}
              key={m.id}
            >
              {m.internetUnit.value}
              {m.internetUnit.unit}/<small>mo</small>
              <br />
              <span>${m.price}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="hwd-side-infos--price">
        <div className="hwd-side-infos--price-left">
          {selectedStorage?.hasDiscount ? (
            <>
              <span className="hwd-side-infos--bd-price">
                ${selectedStorage.price}
                {'  '}
              </span>
              ${selectedStorage?.discountedPrice}
            </>
          ) : (
            `$${selectedStorage?.price}`
          )}
        </div>
        <div className="hwd-side-infos--price-right">
          <div
            className={`hwd-side-infos--price-right-top ${selectedStorage.stock ? 'active' : ''}`}
          >
            {stockText}
          </div>
          <div
            className={`hwd-side-infos--price-right-bottom`}
            dangerouslySetInnerHTML={{ __html: deliveryMessage }}
          />
        </div>
      </div>
      <div className="hwd-side-infos--monthly">
        <p className="affirm-as-low-as" data-page-type="product" data-amount={price * 100}></p>
      </div>
      <div className="hwd-side-infos--buttons">
        <div
          className="hwd-side-infos--add-to-basket"
          role="button"
          onClick={() => addHardwareToBasket()}
        >
          ADD TO BAG
        </div>
        <div
          className="hwd-side-infos--affirm-button"
          role="button"
          onClick={() => buyWithAffirm()}
        >
          BUY WITH AFFIRM
        </div>
      </div>
    </div>
  );
}
