import './otp-verification.scss';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {addOtpFactorSchema} from "../../../../../shared/schemas/validation-rules";
import React, {useEffect} from "react";
import routes from "../../../../../navigation/routes";
import Popup from "../../../../components/ui-component/popup/popup";
import Spinner from "../../../../components/ui-component/spinner/spinner";
import accountTypes from "../../../../../redux/workers/account/account-types";
import Button from "../../../../components/ui-component/button/button";
import OtpInput from "react-otp-input";
import get from "lodash/get";
import {useMediaQuery} from "../../../../../hooks/useMediaQuery";

export default function AccountEditAddOtpVerification() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isTabletForOtpInput = useMediaQuery('(max-width: 660px)');
    const isMobileForOtpInput = useMediaQuery('(max-width: 430px)');

    useEffect(() => {
        dispatch({
            type: accountTypes.LOAD_OTP_QR
        });
    }, []);

    const {
        id,
        verificationOtpProgress,
        loadingaccountEditMethodVerification = false,
        otpQr,
        accountData
    } = useSelector(state => ({
        id: state.accountReducer.accountData.id,
        verificationOtpProgress: state.accountReducer.verificationOtpProgress,
        loadingaccountEditMethodVerification: state.loadingReducer?.loadingaccountEditMethodVerification,
        otpQr: state.accountReducer.otpQr,
        accountData: state.accountReducer.accountData,
    }));
    const otpFactor = get(accountData, 'multiFactors.otpFactor', false);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitted },
        control
    } = useForm({
        defaultValues: {
            otpFactor: ''
        },
        resolver: yupResolver(addOtpFactorSchema())
    });

    const close = () => navigate(-1);

    const handleFormSubmit = (data) => {
        dispatch({
            type: accountTypes.SET_METHOD_VERIFICATION,
            data: { id, type: !otpFactor, ...data }
        });
    }

    useEffect(() => {
        if (verificationOtpProgress.status === 'success') {
            navigate(
                `${routes.account.base}/${routes.account.edit.base}/${routes.account.edit.registerOtpSuccessful}`,
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verificationOtpProgress.status]);

    return (
        <Popup close={close} addClass="account-edit-otp-verification">
            <div className="account-edit-otp-verification__container">
                <h6 className="account-edit-otp-verification__title">
                    {otpFactor ? 'Verify OTP Code' : 'Scan QR and Enter OTP Code'}
                </h6>

                {loadingaccountEditMethodVerification && (
                    <div className="account-edit-otp-verification__loader-wrapper">
                        <Spinner />
                    </div>
                )}

                {!loadingaccountEditMethodVerification && (
                    <form
                        onSubmit={handleSubmit(handleFormSubmit)}
                        className="account-edit-otp-verification__form"
                        style={otpFactor ? {marginTop: 30, marginBottom: 10} : null}
                    >
                        {(otpQr && !otpFactor) && (
                            <img
                                className="account-edit-otp-verification__qr"
                                src={otpQr}
                                alt="OTP QR"
                            />
                        )}

                        <Controller
                            control={control}
                            name="otpFactor"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <OtpInput
                                        value={value}
                                        onChange={onChange}
                                        numInputs={6}
                                        separator={isMobileForOtpInput ? '' : <span>-</span>}
                                        inputStyle={{
                                            width: '3rem',
                                            height: '3rem',
                                            margin: '0 1rem',
                                            fontSize: '2rem',
                                            borderRadius: '4px',
                                            border: '1px solid rgba(0, 0, 0, 0.3)',
                                            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                            backgroundColor: '#fff',
                                            ...isTabletForOtpInput ? {
                                                width: '2.5rem',
                                                height: '2.5rem',
                                                margin: '0 .3rem',
                                                fontSize: '1.5rem'
                                            } : {},
                                            ...isMobileForOtpInput ? {
                                                margin: '0 .3rem'
                                            } : {}
                                        }}
                                        containerStyle={{
                                            justifyContent: 'center'
                                        }}
                                    />
                                    {(errors.otpFactor?.message && isSubmitted) && (
                                        <p className="account-edit-otp-verification__form-error">
                                            {errors.otpFactor.message}
                                        </p>
                                    )}
                                    {verificationOtpProgress?.message && (
                                        <p className="account-edit-otp-verification__form-error">
                                            {verificationOtpProgress.message}
                                        </p>
                                    )}
                                </>
                            )}
                        />

                        <Button
                            type="submit"
                            addClass="account-edit-otp-verification__btn"
                            title={otpFactor ? 'disconnect otp' : 'connect otp'}
                        />
                    </form>
                )}
            </div>
        </Popup>
    )
}
