import './account-screen-old-customer.scss';
import {useSelector} from "react-redux";

export default function AccountScreenOldCustomer() {

    const {
        accountData
    } = useSelector(state => ({
        accountData: state.accountReducer.accountData
    }));

    function format(date) {
        if(!date) {
            return '';
        }
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    }

    return (
        <div className="old-customer">
            <h3 className="old-customer--title">
                GoTalk USA Purchases ({accountData.oldCustomerData?.length || 0})
            </h3>

            <div className="old-customer--purchases">
                {accountData.oldCustomerData.map((item, index) => (
                    <div className="old-customer--purchase" key={index}>
                        <div className="old-customer--purchase--detail">
                            <div className="old-customer--purchase--detail--label">
                                Created at
                            </div>
                            <div className="old-customer--purchase--detail--value">
                                {format(item.purchase.createdAt)}
                            </div>
                        </div>
                        <div className="old-customer--purchase--detail">
                            <div className="old-customer--purchase--detail--label">
                                Total Amount
                            </div>
                            <div className="old-customer--purchase--detail--value">
                                {item.purchase.totalAmount || '0'} {item.purchase.currency || ''}
                            </div>
                        </div>
                        <div className="old-customer--purchase--detail">
                            <div className="old-customer--purchase--detail--label">
                                Invoice Number
                            </div>
                            <div className="old-customer--purchase--detail--value">
                                {item.purchase.invoiceNumber || ''}
                            </div>
                        </div>
                        <div className="old-customer--purchase--detail">
                            <div className="old-customer--purchase--detail--label">
                                Invoice Description
                            </div>
                            <div className="old-customer--purchase--detail--value">
                                {item.purchase.invoiceDescription || ''}
                            </div>
                        </div>
                        <div className="old-customer--purchase--detail">
                            <div className="old-customer--purchase--detail--label">
                                Delivery
                            </div>
                            <div className="old-customer--purchase--detail--value">
                                {item.delivery.firstName || ''} {item.delivery.lastName} <br />
                                {item.delivery.company || ''} <br />
                                {[item.delivery.address, item.delivery.city, item.delivery.state, item.delivery.zip, item.delivery.country].filter(m => m).join(', ')}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
