import './change-plan.scss';
import Popup from "../ui-component/popup/popup";
import Plans from "../ui-component/plans/plans";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import accountTypes from "../../../redux/workers/account/account-types";
import Spinner from "../ui-component/spinner/spinner";
import {PlanFilterPageType} from "../../../hooks/useFilteredPlansByPlaceType";

export default function ChangePlan({currentProductId, currentPlanId, onClose}) {

    const changePlanLoading = useSelector((state) => state.loadingReducer.changePlanLoading);
    const dispatch = useDispatch();

    function submitChangePlan(newPlanId) {
        dispatch({
            type: accountTypes.CHANGE_PLAN,
            data: {
                productId: currentProductId,
                newPlanId
            }
        });
    }

    return (
        <Popup addClass="change-plan--popup" close={() => onClose()}>
            <h2 className="change-plan--title">
                Select a New Plan
            </h2>
            {changePlanLoading ? (
                <div className="change-plan--spinner">
                    <Spinner />
                </div>
            ) : (
                <Plans
                    wrapperClass="change-plan-wrapper"
                    change={true}
                    onChange={(id) => submitChangePlan(id)}
                    collapsible={true}
                    currentPlanId={currentPlanId}
                    planPageType={PlanFilterPageType.CHANGE_PLAN}
                />
            )}
        </Popup>
    );
}
