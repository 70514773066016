import './faq-list.scss';
import Accordion from '../ui-component/accordion/accordion';
import Search from '../ui-component/search/search';
import React, {useMemo, useState} from 'react';

const FaqList = ({questions}) => {

  const [query, setQuery] = useState('');
  const filteredQuestions = useMemo(() => {
    if(!query) {
      return questions;
    }
    return questions.filter(
        (item) => item.title.includes(query) || item.content.includes(query),
    );
  }, [query]);

  return (
    <div className="faq-list">
      <Search onChange={ev => setQuery(ev.target.value)} containerClass="faq-list__search" />
      {filteredQuestions.length ? (
          <ul className="faq-list__list">
            {filteredQuestions.map((item) => (
                <li className="faq-list__list-item" key={item.title}>
                  <Accordion
                      title={item.title}
                      content={item.content}
                  />
                </li>
            ))}
          </ul>
      ) : (
        <p className="faq-list__list--empty">Nothing found</p>
      )}
    </div>
  );
};

export default FaqList;
