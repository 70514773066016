import './private-wireless-coverage.scss';
import CoverageBannerImage from '../../../../assets/images/private-wireless/coverage-banner.png';
import CoverageBannerMobileImage from '../../../../assets/images/private-wireless/coverage-banner-mobile.png';
import useMediaBelow from "../../../../shared/hooks/useMediaBelow";

export default function PrivateWirelessCoverage() {
    const isMobile = useMediaBelow(768);

    return (
      <div className="pw-coverage" style={{
          background: isMobile ? `linear-gradient(0deg, rgba(35, 50, 79, 0.55), rgba(35, 50, 79, 0.55)), url(${CoverageBannerMobileImage})` : ''
      }}>
        <div className="pw-coverage--content">
            <div className="pw-coverage--content-title">
                We will help with deployment cost for private wireless
            </div>
            <div className="pw-coverage--content-subtitle">
                Bring your usual Carrier services to Go Talk, covering 99% of the US, and we will help offset your Private LTE/5G infrastructure deployment cost
            </div>
            <button type="button" className="pw-coverage--content-button">
                LEARN MORE
            </button>
        </div>

        <img src={CoverageBannerImage} alt="Coverage" className="pw-coverage--banner" />
      </div>
    );
}
