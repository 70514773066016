import { changeWebGuardInfos, getWebGuardInfos } from '../../../../redux/workers/account';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Spinner from '../../../components/ui-component/spinner/spinner';
import Switch from '../../../components/ui-component/switch/switch';
import { toast } from 'react-toastify';

export default function WebGuard() {
  const { search } = useLocation();
  const [loading, setLoading] = useState();
  const [data, setData] = useState();

  const id = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('id') || 0;
  }, [search]);

  useEffect(() => {
    if (id > 0) {
      setLoading(true);
      getWebGuardInfos(id).then(
        (res) => {
          setLoading(false);
          setData(res.data);
        },
        (err) => {
          setLoading(false);
        }
      );
    }
  }, [id]);

  async function onChange(guardKey, newValue) {
    try {
      const newGuards = [...data.guards];
      if (!newValue) {
        newGuards.find((m) => m.key === guardKey).checked = false;
      } else {
        for (const guard of newGuards) {
          guard.checked = guardKey === guard.key;
        }
      }
      setData({
        ...data,
        guards: newGuards,
      });

      setLoading(true);
      await changeWebGuardInfos(id, {
        guardKey,
        newValue,
      });

      toast.success(`WEB Guard update is successful`, {
        position: 'top-right',
      });
    } catch (ex) {
      toast.error(ex.response.data.payload.message, {
        position: 'top-right',
      });
    } finally {
      const res = await getWebGuardInfos(id);
      setData(res.data);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="account-sidebar__item__title">WEB Guard</div>
      {data?.guards.map((guard) => (
        <div key={guard.key} className="account-sidebar__item__history">
          <Switch
            isChecked={guard.checked}
            onChange={(checked) => onChange(guard.key, checked)}
            label={guard.label}
            addClass="account-screen-edit__switch"
          />
        </div>
      ))}

      {data?.blogUrl && (
        <div className="text-center">
          <NavLink className="account-sidebar__item__link" to={data?.blogUrl} target={'_blank'}>
            Learn More About Settings
          </NavLink>
        </div>
      )}

      {loading && (
        <div className="account-sidebar__spinner-container overlay">
          <Spinner />
        </div>
      )}
    </>
  );
}
