import './price-slider.scss';
import ReactSlider from 'react-slider'

export default function PriceSlider({ minValue, maxValue, selectedMinValue, selectedMaxValue, onChange }) {
    return (
        <div className="hwl-price-slider-container">
            <div className="hwl-price-input">
                <div className="hwl-price-input--item">
                    <input
                        type="number"
                        value={selectedMinValue}
                        min={minValue}
                        max={selectedMaxValue}
                        disabled={true}
                    />
                </div>
                <div className="hwl-price-input--item">
                    <input
                        type="number"
                        value={selectedMaxValue}
                        min={selectedMinValue}
                        max={maxValue}
                        disabled={true}
                    />
                </div>
            </div>
            <ReactSlider
                className="hwl-price-slider"
                thumbClassName="hwl-price-slider--thumb"
                trackClassName="hwl-price-slider--track"
                value={[selectedMinValue, selectedMaxValue]}
                ariaLabel={['Min price', 'Max price']}
                ariaValuetext={state => `Price: ${state.valueNow}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                pearling
                minDistance={10}
                min={minValue}
                max={maxValue}
                onAfterChange={(newValues, valueIndex) => {
                    if(valueIndex === 0) {
                        onChange({ minValue: newValues[valueIndex] });
                    } else {
                        onChange({ maxValue: newValues[valueIndex] });
                    }
                }}
            />
        </div>
    );
}
