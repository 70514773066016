import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import actionsType from "../redux/workers/main-page/actions-type";

export const PlanFilterPageType = {
    HOME: 'HOME',
    PLANS: 'PLANS',
    LANDING: 'LANDING',
    BASKET: 'BASKET',
    CHANGE_PLAN: 'CHANGE_PLAN'
}

export function useFilteredPlansByPlaceType({
    pageType,
    popular = false,
    landingPageId = null,
    planType = null,
    onlyShowLandingPagePlans = false
}) {
    const plans = useSelector((state) => state.mainReducer.plans);
    const isModeBusiness = useSelector((state) => state.mainReducer.isModeBusiness);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: actionsType.LOAD_PLANS });
    }, []);

    return useMemo(() => {
        let result = (plans || []);

        if(planType) {
            result = result.filter(m => m.planType === planType);
        }

        if(pageType !== PlanFilterPageType.BASKET) {
            result = result.filter(m => m.isCompany === isModeBusiness);
        }

        if(pageType === PlanFilterPageType.HOME || pageType === PlanFilterPageType.PLANS) {
            result = result.filter(m => m.showPlaceType === 'EVERYWHERE' || m.showPlaceType === 'ONLY_PLAN_PAGE');
        }
        else if(pageType === PlanFilterPageType.LANDING) {
            if(onlyShowLandingPagePlans) {
                result = result.filter(m => {
                    return m.showPlaceType === 'ONLY_SELECTED_LANDING_PAGE' && m.showPlaceTypeDetailId === landingPageId;
                });
            }
            else {
                result = result.filter(m => {
                    return m.showPlaceType === 'EVERYWHERE'
                        || m.showPlaceType === 'ALL_LANDING_PAGES'
                        || (m.showPlaceType === 'ONLY_SELECTED_LANDING_PAGE' && m.showPlaceTypeDetailId === landingPageId)
                });
            }
        }

        if(popular) {
            result = result
                .sort((a, b) => b.rating - a.rating)
                .slice(0, 3);
        }

        return result.sort((a, b) => a.costBuyPlan - b.costBuyPlan);
    }, [popular, pageType, landingPageId, plans, planType]);
}
