import './header-dropdown.scss';
import React, {useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import ArrowIcon from '../../../../assets/images/icons/arrow.svg';
import ArrowWhiteIcon from '../../../../assets/images/icons/arrow-white.svg';

export default function HeaderDropdown({ title, dropdownItems, isFooter }) {

    const [isOpen, setOpen] = useState(false);
    const dropdown = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!dropdown.current?.contains(e.target)) {
                setOpen(false);
            }
        };
        if (isOpen) {
            window.addEventListener('click', handleClickOutside);
        }

        return () => window.removeEventListener('click', handleClickOutside);
    }, [isOpen]);

    const handleOpening = () => {
        setOpen(!isOpen);
    };

    const handleCloseDropdown = () => {
        setOpen(false);
    };

    return (
        <div ref={dropdown} className="dropdown">
            <button
                className="dropdown__placeholder"
                onClick={handleOpening}
                type="button"
            >
                {title}
                <img src={isFooter ? ArrowWhiteIcon : ArrowIcon} alt="Show" />
            </button>
            <ul className={`dropdown__options-list ${isOpen ? '' : 'inactive'}`}>
                {dropdownItems.map((item, idx) => (
                    <li className="dropdown__option" key={idx.toString()}>
                        <NavLink
                            onClick={handleCloseDropdown}
                            to={item.path}
                            className="dropdown__option-link"
                            type="button"
                        >
                            {item.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
}
