import './vpn-block.scss';
import vpnBlockImage from '../../../assets/images/plans/vpn-block.jpeg';
import vpnBlockTickIcon from '../../../assets/images/plans/vpn-block-tick-icon.svg';

export default function VpnBlock({customData}) {
    return (
      <div className="vpn-block">
        <div className="vpn-block--left">
            {customData?.title ? (
                <h3 dangerouslySetInnerHTML={{__html: customData.title}}/>
            ) : (
                <h3>No Other Carriers<br/>Protect you like we do.</h3>
            )}
            {customData?.items ? (
                <div className="vpn-block--items">
                    {customData.items.map((m, i) => (
                        <div className="vpn-block--item" key={i}>
                            <img src={vpnBlockTickIcon} />
                            <span>{m.title}</span>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="vpn-block--items">
                    <div className="vpn-block--item">
                        <img src={vpnBlockTickIcon} />
                        <span>Free VPN</span>
                    </div>
                    <div className="vpn-block--item">
                        <img src={vpnBlockTickIcon} />
                        <span>Free Anti-Phishing Tools</span>
                    </div>
                    <div className="vpn-block--item">
                        <img src={vpnBlockTickIcon} />
                        <span>Free ID Theft Protection</span>
                    </div>
                </div>
            )}
        </div>
        <div className="vpn-block--right"
            style={{
                backgroundImage: `url(${customData?.backgroundImage || vpnBlockImage})`
            }}>
        </div>
      </div>
    );
}
