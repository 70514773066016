import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { HardwareListingSortTypes } from '../../types/hardwareListingSortTypes';
import { fetchListing } from '../../redux/workers/hardwares/hardwares';
import { generateUUID } from '../../utils/utils';
import { useLocation } from 'react-router-dom';

export const HardwareListingContext = createContext({});

let searchChangeGuid = generateUUID();

export default function HardwareListingContextProvider({ children, isMobile }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState([]);
  const [products, setProducts] = useState([]);
  const [maxResultCount, setMaxResultCount] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState(HardwareListingSortTypes.DATE_DESC);

  const brandQueryWorked = useRef(false);

  const { search } = useLocation();
  const queryBrandName = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('brand');
  }, [search]);

  useEffect(() => {
    brandQueryWorked.current = false;
  }, [queryBrandName]);

  useEffect(() => {
    initFetch().then();
  }, []);

  useEffect(() => {
    if (queryBrandName && filters.length && !brandQueryWorked.current) {
      brandQueryWorked.current = true;
      const newFilters = [...filters];
      const brandsFilter = newFilters.find((m) => m.key === 'brand');
      if (brandsFilter) {
        for (const brand of brandsFilter.items) {
          brand.checked = brand.label === queryBrandName;
        }
        onFilterChange(newFilters);
      }
    }
  }, [filters, queryBrandName]);

  async function initFetch(localChanges) {
    setLoading(true);
    try {
      const req = {
        filters: filters,
        offset: (currentPage - 1) * perPage,
        limit: perPage,
        searchQuery,
        sortBy,
      };
      if (typeof localChanges?.filters !== 'undefined') {
        req.filters = [...localChanges.filters];
      }
      if (typeof localChanges?.currentPage !== 'undefined') {
        req.offset = (localChanges.currentPage - 1) * perPage;
      }
      if (typeof localChanges?.searchQuery !== 'undefined') {
        req.searchQuery = localChanges.searchQuery.toString();
      }
      if (typeof localChanges?.sortBy !== 'undefined') {
        req.sortBy = localChanges.sortBy.toString();
      }

      const res = await fetchListing(req);
      setProducts(res.data.payload.products || []);
      setMaxResultCount(res.data.payload.maxResultCount || 0);
      setFilters(res.data.payload.filters || []);
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  }

  const hasFilter = useMemo(() => {
    let hasFilterResult = filters.some(
      (m) => m.type === 'checkbox' && m.items.some((s) => s.checked)
    );
    if (!hasFilterResult) {
      hasFilterResult = filters.some(
        (m) =>
          m.type === 'slider' &&
          (m.minValue !== m.selectedMinValue || m.maxValue !== m.selectedMaxValue)
      );
    }

    return hasFilterResult;
  }, [filters]);

  function onFilterChange(newFilters) {
    setFilters(newFilters);
    setTimeout(() => {
      initFetch({ filters: newFilters }).then();
    }, 250);
  }

  function onSearchChange(newSearch) {
    setSearchQuery(newSearch);
    searchChangeGuid = generateUUID();
    const localSearchChangeGuid = searchChangeGuid.toString();
    setTimeout(() => {
      if (searchChangeGuid === localSearchChangeGuid) {
        initFetch({ searchQuery: newSearch }).then();
      }
    }, 500);
  }

  function onPageChange(newPage) {
    setCurrentPage(newPage);
    setTimeout(() => {
      initFetch({ currentPage: newPage }).then();
    }, 250);
  }

  function clearFilters() {
    const newFilters = [...filters];
    for (const newFilter of newFilters) {
      if (newFilter.items) {
        for (const item of newFilter.items) {
          item.checked = false;
        }
      }
      if (newFilter.type === 'slider') {
        newFilter.selectedMinValue = Number(newFilter.minValue);
        newFilter.selectedMaxValue = Number(newFilter.maxValue);
      }
    }
    setFilters(newFilters);
    setTimeout(() => {
      initFetch({ filters: newFilters }).then();
    }, 250);
  }

  function onSortChange(newSortBy) {
    setSortBy(newSortBy);
    setTimeout(() => {
      initFetch({ sortBy: newSortBy }).then();
    }, 250);
  }

  return (
    <HardwareListingContext.Provider
      value={{
        isMobile,
        searchQuery,
        filters,
        products,
        maxResultCount,
        perPage,
        currentPage,
        loading,
        hasFilter,
        sortBy,
        onPageChange,
        onFilterChange,
        onSearchChange,
        clearFilters,
        onSortChange,
      }}
    >
      {children}
    </HardwareListingContext.Provider>
  );
}
