import './checkbox.scss';
import CheckMarkWhiteIcon from "../../../../assets/images/hardwares/check-mark-white.svg";

export default function Checkbox({checked, onChange, children}) {
    return (
        <div className="hwl-checkbox" onClick={() => onChange(!checked)}>
            <div className={`hwl-checkbox--box ${checked && 'checked'}`}>
                {checked && <img src={CheckMarkWhiteIcon} className="hwl-checkbox--check-mark" alt="Check" />}
            </div>
            <div className="hwl-checkbox--label">
                {children}
            </div>
        </div>
    );
}
