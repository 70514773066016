import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import routes from "../../../navigation/routes";
import authTypes from "../../../redux/workers/auth/auth-types";
import accountTypes from "../../../redux/workers/account/account-types";
import Input from "../ui-component/input/input";
import Switch from "../ui-component/switch/switch";
import Button from "../ui-component/button/button";
import './sign-in-multi-factor.scss';
import OtpInput from "react-otp-input";
import {useMediaQuery} from "../../../hooks/useMediaQuery";

const validationSchema = yup.object({
    emailFactor: yup.string()
        .when("emailFactorRequired", {
            is: true,
            then: yup.string().required('This field is required'),
            otherwise: yup.string().notRequired()
        }),
    yubicoFactor: yup.string()
        .when("yubicoFactorRequired", {
            is: true,
            then: yup.string().required('This field is required'),
            otherwise: yup.string().notRequired()
        }),
    otpFactor: yup.string()
        .when("otpFactorRequired", {
            is: true,
            then: yup.string().required('This field is required'),
            otherwise: yup.string().notRequired()
        })
});

export default function SignInMultiFactor() {
    const errorMessage = useSelector((state) => state.authReducer.signIn.error);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { handleSubmit, register, formState, control } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            emailFactor: '',
            yubicoFactor: '',
            otpFactor: '',
            emailFactorRequired: !!location.state?.emailFactor,
            yubicoFactorRequired: !!location.state?.yubicoFactor,
            otpFactorRequired: !!location.state?.otpFactor
        }
    });
    const { errors, isSubmitted } = formState;
    const isTabletForOtpInput = useMediaQuery('(max-width: 660px)');
    const isMobileForOtpInput = useMediaQuery('(max-width: 430px)');

    useEffect(() => {
        if (!location.state?.email) {
            navigate(routes.home);
        }
    }, [location.state?.email, navigate]);

    const handleSignIn = (data) => {
        dispatch({
            type: authTypes.MULTI_FACTOR_AUTH, payload: { ...data }, navigate,
        });
    };

    const handleResend = () => {
        dispatch({ type: accountTypes.SEND_VERIFICATION_EMAIL_CODE });
    };

    return (
        <form onSubmit={handleSubmit(handleSignIn)} className="sign-in-multi-factor">
            {location.state?.emailFactor && (
                <div className="sign-in-multi-factor__email-container">
                    <p className="confirmation__subtitle">A code was sent to the specified one</p>
                    <p className="confirmation__email">{location.state?.email}</p>
                    <Input
                        {...register('emailFactor')}
                        description={errors.emailFactor?.message}
                        isInvalid={!!errors?.emailFactor}
                        containerClass="confirmation__input"
                        placeholder="Enter the code"
                        type="text"
                        label="Enter the code from the email"
                    />
                    <p className="sign-in-multi-factor__resend-text">
                        Didn&apos;t get the code?
                        {' '}
                        <button
                            onClick={handleResend}
                            type="button"
                            className="sign-in-multi-factor__resend-button"
                        >
                            Resend code
                        </button>
                    </p>
                </div>
            )}
            {location.state?.yubicoFactor && (
                <div className="sign-in-multi-factor__yubico-container">
                    <p className="yubi-key-form__subtitle">Please use your YubiKey</p>
                    <ol className="yubi-key__list">
                        <li className="yubi-key-form__list-item">
                            <p className="yubi-key-form__list-item-text">Insert your YubiKey in the USB-port with the USB-contact facing upward</p>
                        </li>
                        <li className="yubi-key-form__list-item">
                            <p className="yubi-key-form__list-item-text">Wait until your YubiKey touch-button shines with a steady light</p>
                        </li>
                        <li className="yubi-key-form__list-item">
                            <p className="yubi-key-form__list-item-text">Hold your fingertip on the touch-button for 1 second</p>
                        </li>
                    </ol>
                    <Input
                        {...register('yubicoFactor')}
                        description={errors?.yubicoFactor?.message}
                        isInvalid={!!errors?.yubicoFactor}
                        placeholder="Passcode"
                        type="text"
                        containerClass="confirmation__input"
                    />
                    <div className="yubi-key-form__controls">
                          <span className="yubi-key-form__switch-container">
                            <Switch onChange={(isChecked) => isChecked} addClass="yubi-key-form__switch" />
                            <p className="yubi-key-form__switch-label">Trust this computer for 30 days</p>
                          </span>
                        <a className="yubi-key-form__link" href="/">I&apos;ve lost my Yubikey device</a>
                    </div>
                </div>
            )}
            {location.state?.otpFactor && (
                <div className="sign-in-multi-factor__otp-container">
                    <p className="yubi-key-form__subtitle">Please enter Authenticator OTP Code</p>
                    <Controller
                        control={control}
                        name="otpFactor"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OtpInput
                                value={value}
                                onChange={onChange}
                                numInputs={6}
                                separator={isMobileForOtpInput ? '' : <span>-</span>}
                                inputStyle={{
                                    width: '3rem',
                                    height: '3rem',
                                    margin: '0 1rem',
                                    fontSize: '2rem',
                                    borderRadius: '4px',
                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                                    backgroundColor: '#fff',
                                    ...isTabletForOtpInput ? {
                                        width: '2.5rem',
                                        height: '2.5rem',
                                        margin: '0 .3rem',
                                        fontSize: '1.5rem'
                                    } : {},
                                    ...isMobileForOtpInput ? {
                                        margin: '0 .3rem'
                                    } : {}
                                }}
                                containerStyle={{
                                    justifyContent: 'center'
                                }}
                            />
                        )}
                    />
                    {(errors.otpFactor?.message && isSubmitted) && (
                        <p className="sign-in-multi-factor__form-error">
                            {errors.otpFactor.message}
                        </p>
                    )}
                </div>
            )}
            {errorMessage && <p className="sign-in-multi-factor__error-message">{errorMessage}</p>}
            <Button type="submit" title="Authenticate" addClass="yubi-key-form__submit" />
        </form>
    );
}
