import './account-screen-my-plan.scss';
import MyPlan from '../../../components/account/my-plan/my-plan';
import React from 'react';

const AccountScreenMyPlan = () => (
  <div className="account-screen-my-plan">
    <MyPlan />
  </div>
);

export default AccountScreenMyPlan;
