export default {
  GET_PLANS: 'GET_PLANS',
  LOAD_CURRENT_PLAN: 'LOAD_CURRENT_PLAN',
  GET_CURRENT_PLAN: 'GET_CURRENT_PLAN',
  CHANGE_MODE: 'CHANGE_MODE',
  SET_POPULAR_PLANS: 'SET_POPULAR_PLANS',
  CHANGE_CATEGORY: 'CHANGE_CATEGORY',
  FOLLOW_ID: 'FOLLOW_ID',
  LOAD_PLANS: 'LOAD_PLANS'
};
