import './hardware-basket-item.scss';
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {createDispatchLog} from "../../../../redux/workers/conversion-tracking";
import {ConversionTrackingActionNames} from "../../../../types/conversionTrackingActionNames";
import {getBasketItems} from "../../../../shared/basketActions";
import minusWhite from "../../../../assets/images/icons/minus-white.svg";
import plusWhite from "../../../../assets/images/icons/plus-white.svg";
import trashBlack from "../../../../assets/images/icons/trash-black.svg";

export default function HardwareBasketItem({ item, onChange, onDelete }) {

    const [itemData, setItemData] = useState({
        hardwareBasketId: item.hardwareBasketId,
        count: item.count
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (onChange) {
            onChange(itemData);
        }
    }, [itemData, onChange]);

    const handleRemove = () => {
        if (itemData.count > 1) {
            setItemData({ ...itemData, count: itemData.count - 1 });
        }
    };

    const handleAddition = () => {
        setItemData({ ...itemData, count: itemData.count + 1 });
        setTimeout(() => {
            dispatch(createDispatchLog(ConversionTrackingActionNames.BASKET, {
                actionDataType: 'BASKET_UPDATE',
                data: {
                    items: getBasketItems()
                }
            }));
        }, 1000);
    };

    const handleDeletion = () => {
        if (onDelete) {
            onDelete(item.hardwareBasketId, 'hardware');
            setTimeout(() => {
                dispatch(createDispatchLog(ConversionTrackingActionNames.BASKET, {
                    actionDataType: 'BASKET_UPDATE',
                    data: {
                        items: getBasketItems()
                    }
                }));
            }, 1000);
        }
    };

    const selectedVariant = item.data.HardwareVariants[item.data.selectedVariantIndex];
    const selectedCondition = selectedVariant.conditions[item.data.selectedConditionIndex];
    const selectedStorage = selectedCondition.storages[item.data.selectedStorageIndex];
    const price = selectedStorage.hasDiscount ? selectedStorage.discountedPrice : selectedStorage.price;
    const discountPercentage = selectedStorage.hasDiscount ? ((selectedStorage.price - selectedStorage.discountedPrice) / selectedStorage.price * 100) : 0;

    return (
      <div className="hardware-basket-item">
          <div className="hardware-basket-item__row">
              <div className="hardware-basket-item__row__left">
                  <div className="hardware-basket-item__title" dangerouslySetInnerHTML={{
                      __html: item.data.title
                  }} />
                  <div className="hardware-basket-item__slogan">
                      <span className="hardware-basket-item__color" style={{
                          backgroundColor: selectedVariant.color
                      }} />
                      {selectedVariant.title}
                  </div>
                  <div className="hardware-basket-item__price small">
                      <div className="hardware-basket-item__price-row">
                          <div className="hardware-basket-item__price-unit">$</div>
                          <div className="hardware-basket-item__price-cost">{price}</div>
                      </div>
                  </div>
                  <div className="hardware-basket-item__props">
                      <div className="hardware-basket-item__props__item">
                          <div className="hardware-basket-item__props__item__text">
                              <span>Color: </span> {selectedVariant.title}
                          </div>
                      </div>
                      <div className="hardware-basket-item__props__item">
                          <div className="hardware-basket-item__props__item__text">
                              <span>Condition: </span> {selectedCondition.conditionTitle}
                          </div>
                      </div>
                      <div className="hardware-basket-item__props__item">
                          <div className="hardware-basket-item__props__item__text">
                              <span>Storage: </span> {selectedStorage.storageSize} {selectedStorage.storageUnit}
                          </div>
                      </div>
                  </div>
              </div>
              <div className="hardware-basket-item__row__right">
                  <div className="hardware-basket-item__quantity">
                      <div className="hardware-basket-item__quantity__changer">
                          <div className="hardware-basket-item__quantity__changer__text">
                              Quantity:
                          </div>
                          <img src={minusWhite} className="hardware-basket-item__quantity__changer__icon" onClick={() => handleRemove()} />
                          <span className="hardware-basket-item__quantity__changer__count">{itemData.count}</span>
                          <img src={plusWhite} className="hardware-basket-item__quantity__changer__icon" onClick={() => handleAddition()} />
                      </div>
                      <div className="hardware-basket-item__quantity__trash">
                          <img src={trashBlack} className="hardware-basket-item__quantity__trash__icon" onClick={() => handleDeletion()} />
                      </div>
                  </div>
                  <div className="hardware-basket-item__image">
                      <div style={{backgroundImage: `url(${selectedVariant.images[0].imageUrl})`}} />
                  </div>
              </div>
          </div>
      </div>
    );
}
