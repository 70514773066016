import API from "../../../axios/api";
import {HARDWARES} from "../../../axios/api-urls";

const api = new API();

export const fetchListing = (body) => api.post(HARDWARES.listing, body);

export const fetchHardwareDetail = (slugUrl) => api.get(`${HARDWARES.detail}${slugUrl}`);

export const fetchBrands = () => api.get(HARDWARES.brands);
