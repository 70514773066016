import './change-plan-payment.scss';
import {useDispatch, useSelector} from "react-redux";
import Popup from "../ui-component/popup/popup";
import React, {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Button from "../ui-component/button/button";
import Spinner from "../ui-component/spinner/spinner";
import accountTypes from "../../../redux/workers/account/account-types";

const reactAppStripeKey = process.env.REACT_APP_STRIPE_PK || '';
const stripePromise = loadStripe(reactAppStripeKey);

export default function ChangePlanPayment({onClose, productId}) {

    const dispatch = useDispatch();
    const confirmChangePlanResponse = useSelector((state) => state.accountReducer.confirmChangePlanResponse);
    useEffect(() => {
        if(confirmChangePlanResponse) {
            onClose();
            dispatch({
                type: accountTypes.LOAD_CURRENT_PRODUCT,
                id: productId,
                productType: 'sim'
            });
        }
    }, [confirmChangePlanResponse]);

    const changePlanResponse = useSelector((state) => state.accountReducer.changePlanResponse);
    if(!changePlanResponse) {
        return null;
    }

    return (
        <Popup addClass="change-plan-payment--popup" close={() => onClose()}>
            <h2 className="change-plan-payment--title">
                Plan Change Payment
            </h2>
            <div className="change-plan-payment--container">
                <Elements stripe={stripePromise} options={{clientSecret: changePlanResponse.stripId}}>
                    <ChangePlanPaymentMethodElement />
                </Elements>
            </div>
        </Popup>
    )
}

let paymentElementComplete = false;
function ChangePlanPaymentMethodElement() {

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const changePlanResponse = useSelector((state) => state.accountReducer.changePlanResponse);

    function stripeReturnUrl() {
        const urlSearchParams = new URLSearchParams(window.location.href);
        urlSearchParams.set('payId', changePlanResponse.userPayId);
        return decodeURIComponent(urlSearchParams.toString());
    }

    async function submitForm() {
        if(!paymentElementComplete) {
            const {error} = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: stripeReturnUrl(),
                },
            });
            return;
        }

        setLoading(true);
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: stripeReturnUrl(),
            },
        });
        setLoading(false);
    }

    return (
        <div className="change-plan-payment--inner">
            <PaymentElement onChange={ev => paymentElementComplete = ev.complete} />
            <div className="change-plan-payment--buttons">
                <div className="change-plan-payment--price">
                    ${changePlanResponse?.sum || 0}
                </div>
                <Button
                    addClass="change-plan-payment--button"
                    onClick={() => submitForm()}
                    title="SUBMIT"
                />
            </div>
            {loading && <Spinner addClass="change-plan-payment--spinner"/>}
        </div>
    )
}
