import API from "../../../axios/api";
import {AFFIRM} from "../../../axios/api-urls";

const api = new API();

export const saveAffirmOrder = (body) => api.post(AFFIRM.saveOrder, body);

export const saveUserAffirmOrder = (body) => api.post(AFFIRM.saveOrderUser, body);

export const generateCheckoutRequest = (body) => api.post(AFFIRM.generateCheckoutRequest, body);

export const generateUserCheckoutRequest = (body) => api.post(AFFIRM.generateCheckoutRequestUser, body);
