/* eslint-disable jsx-a11y/no-static-element-interactions */
import './card.scss';
import Button from '../button/button';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import ChevronDownIcon from "../../../../assets/images/payment/chevron-down.svg";

const Card = ({
  title,
  price,
  description,
  characteristics,
  onClick,
  clickCard,
  isCompany,
  sms,
  internet,
  minute,
  planType,
  change,
  collapsible,
  disable,
    hasDiscount, discountedPrice, hasMinMonthLimit, minMonthLimit, extraPropsContent,
                showOriginalPriceIfHasDiscount, darkenBackground
}) => {
  const getProps = (propsObj) => Object.values(propsObj || {});
  const isModeBusiness = useSelector((state) => state.mainReducer.isModeBusiness);
  const [isCompanyMode, setIsCompanyMode] = useState(isModeBusiness);
  const [showChars, setShowChars] = useState(!collapsible);

  useEffect(() => {
    setIsCompanyMode(isModeBusiness);
  }, [isModeBusiness]);

  const getCharacteristics = () =>
    getProps(characteristics)?.filter(m => m).map((characteristic) => (
      <li className="card__characteristics-item" key={characteristic}>
        {characteristic} {characteristic.trim() === 'Use your phone overseas' ? <span>*</span> : ''}
      </li>
    ));

  function renderMinMonth() {
    if(!hasMinMonthLimit) {
      return null;
    }

    const pr = (hasDiscount ? discountedPrice : price) * minMonthLimit;
    return (
        <div className="card__minmonth">
          ${pr} for First {minMonthLimit} Months
        </div>
    );
  }

  if(isCompanyMode === isCompany) {
    return (
        <div className={`card ${disable ? 'disable' : ''} ${darkenBackground ? 'darken' : ''}`}>
          <div className="card__content">
            <h4 className="card__title">{title}</h4>
            {hasDiscount ? (
                showOriginalPriceIfHasDiscount ? (
                    <h3 className="card__total-discount-price">
                      <span className="ctd-price">{`$${price}`}</span>
                      <span className="ctd-new-price">{`$${discountedPrice}`}</span>
                    </h3>
                ) : (
                    <h3 className="card__total-price">{`$${discountedPrice}`}</h3>
                )
            ) : (
                <h3 className="card__total-price">{`$${price}`}</h3>
            )}
            <p className="card__split-price">Per month / per user</p>
            <p className="card__description">{description}</p>
            <p className="card__conditions">
              {planType !== 'ONLY_VPN' ? (
                  <p>
                    <span className="card__conditions_item__value">{internet.value}{internet.unit}</span>
                    <span className="card__conditions_item__sub">4G / 5G Data</span>
                  </p>
              ) : null}
              {planType === 'DEFAULT' ? (
                  <>
                    <p>
                      <span className="card__conditions_item__value">
                        {minute === '100000' ?  'Unlimited' : minute}
                      </span>
                            <span className="card__conditions_item__sub">
                        MIN
                      </span>
                          </p>
                          <p>
                      <span className="card__conditions_item__value">
                        {sms === '100000' ?  'Unlimited' : sms}
                      </span>
                            <span className="card__conditions_item__sub">
                        SMS
                      </span>
                    </p>
                  </>
              ) : null}
            </p>
            {extraPropsContent && (
                <div className="card__extra">
                  <p dangerouslySetInnerHTML={{ __html: extraPropsContent }}/>
                  <i>plus</i>
                </div>
            )}
            {collapsible && (
                <div className="card__collapser" onClick={() => setShowChars(c => !c)}>
                  <span>Show More</span>
                  <img src={ChevronDownIcon} alt="Collapser" />
                </div>
            )}
            {showChars && <ul className="card__characteristics">{getCharacteristics()}</ul>}
          </div>
          <Button onClick={onClick} addClass="card__button" title={change ? "CHANGE" : "BUY"} />
          {renderMinMonth()}
        </div>
    );
  }

  return null;
};

Card.defaultProps = {
  title: '',
  description: '',
  characteristics: [],
  price: 0,
  isCompany: false,
  sms: '',
  internet: {
    value: 0,
    unit: 'GB',
  },
  minute: '',
  clickCard: () => {},
  onClick: () => {},
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  characteristics: PropTypes.array,
  onClick: PropTypes.func,
  price: PropTypes.number,
  isCompany: PropTypes.bool,
  clickCard: PropTypes.func,
  sms: PropTypes.string,
  internet: PropTypes.object,
  minute: PropTypes.string,
};

export default Card;
