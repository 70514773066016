import React from 'react';
import './present-block.scss';
import PropTypes from "prop-types";

const PresentBlock = ({
  children, imgFon, imgMini, addClass, imageClass,
  hideImages
}) => {
    return (
        <div className={`present-main-content ${addClass} ${hideImages ? 'pmc-hide-images' : ''}`}>
            <div className="gradient-mobile"> </div>
            <div className="present-main-content__information">
                <div className="present-main-content__information_block">{children}</div>
            </div>
            <div className={`present-main__image ${imageClass}`}>
                <div className="main-closer-triangle">
                    <div className="arrow-up"></div>
                    <div className="arrow-down"></div>
                    <div className="arrow-left"></div>
                    <div className="arrow-right"></div>
                </div>
                <img src={imgFon} alt="main" className="present-main__image-item" />
                <img src={imgMini} alt="main" className="present-main__image-item_mini" />
            </div>
        </div>
    );
}

PresentBlock.defaultProps = {
  imgFon: '',
  imgMini: '',
  addClass: '',
  imageClass: '',
  children: React.Fragment,
};

PresentBlock.propTypes = {
  imgFon: PropTypes.string,
  imgMini: PropTypes.string,
  imageClass: PropTypes.string,
  addClass: PropTypes.string,
  children: PropTypes.node,
};

export default PresentBlock;
