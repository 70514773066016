import './hardware-detail-tabbed-infos.scss';
import { useContext, useState } from 'react';
import { HardwareDetailContext } from '../../../../contexts/hardware-detail-context/hardware-detail-context';
import BatteryIcon from '../../../../assets/images/hardwares/battery.svg';
import CameraIcon from '../../../../assets/images/hardwares/camera.svg';
import ChipIcon from '../../../../assets/images/hardwares/chip.svg';
import DisplayIcon from '../../../../assets/images/hardwares/display.svg';
import SimCardIcon from '../../../../assets/images/hardwares/sim-card.svg';
import SizeIcon from '../../../../assets/images/hardwares/size.svg';
import ChevronDownIcon from '../../../../assets/images/hardwares/chevron-down.svg';

const icons = {
  battery: BatteryIcon,
  camera: CameraIcon,
  chip: ChipIcon,
  display: DisplayIcon,
  simCard: SimCardIcon,
  size: SizeIcon,
};

function TabContentDescription() {
  const { productDetail } = useContext(HardwareDetailContext);

  return (
    <div
      className="hwd-tabs--html-content"
      dangerouslySetInnerHTML={{
        __html: productDetail.tabs?.descriptionHtmlContent || '',
      }}
    />
  );
}

function TabContentWhatsInTheBox() {
  const { productDetail } = useContext(HardwareDetailContext);

  return (
    <div
      className="hwd-tabs--html-content"
      dangerouslySetInnerHTML={{
        __html: productDetail.tabs?.whatsInTheBoxHtmlContent || '',
      }}
    />
  );
}

function TabContentSpecification() {
  const { productDetail } = useContext(HardwareDetailContext);
  const specs = productDetail.tabs?.specs || [];

  function getIconImageSrc(spec) {
    if (spec.useCustomIcon && spec.customIconUrl) {
      return spec.customIconUrl;
    }

    return icons[spec.icon] || icons.battery;
  }

  return (
    <div className="hwd-tabs--spec-content">
      {specs.map((spec, sIdx) => (
        <div className="hwd-tabs--spec-item" key={sIdx.toString()}>
          <div className="hwd-tabs--spec-left">
            <img src={getIconImageSrc(spec)} alt={spec.title} />
            {spec.title}
          </div>
          <div className="hwd-tabs--spec-right">
            {spec.items?.map((m, i) => (
              <div
                key={i.toString()}
                className="hwd-tabs--spec-right--item"
                dangerouslySetInnerHTML={{
                  __html: m.label,
                }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default function HardwareDetailTabbedInfos() {
  const { isMobile } = useContext(HardwareDetailContext);
  const [activeTab, setActiveTab] = useState(0);

  const tabItems = [
    {
      title: 'Description',
      content: <TabContentDescription />,
    },
    {
      title: 'Specification',
      content: <TabContentSpecification />,
    },
    {
      title: 'What’s in the box',
      content: <TabContentWhatsInTheBox />,
    },
  ];
  const activeContent = tabItems[activeTab].content;

  if (isMobile) {
    return (
      <div className="hwd-tabs">
        <div className="hwd-tabs--dropdowns">
          {tabItems.map((m, i) => (
            <div
              className={`hwd-tabs--dropdown ${i === activeTab ? 'active' : ''}`}
              key={i.toString()}
            >
              <div
                className="hwd-tabs--dropdown--title"
                role="button"
                onClick={() => setActiveTab(i)}
              >
                {m.title}
                <img src={ChevronDownIcon} alt="Open" />
              </div>

              <div className="hwd-tabs--dropdown--content">{m.content}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="hwd-tabs">
      <div className="hwd-tabs--headers">
        {tabItems.map((m, i) => (
          <div
            className={`hwd-tabs--header ${i === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(i)}
            key={i.toString()}
          >
            {m.title}
          </div>
        ))}
      </div>
      <div className="hwd-tabs--content">{activeContent}</div>
    </div>
  );
}
