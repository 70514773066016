import './accordion.scss';
import {useState} from "react";
import ChevronDownIcon from "../../../../assets/images/hardwares/chevron-down.svg";

export default function Accordion({label, children}) {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <div className="hwl-accordion">
            <div className={`hwl-accordion--title ${collapsed && 'collapsed'}`} onClick={() => setCollapsed(s => !s)}>
                {label}
                <img src={ChevronDownIcon} className="hwl-accordion--collapser" alt="Accordion collapser" />
            </div>
            {!collapsed && (
                <div className="hwl-accordion--content">
                    {children}
                </div>
            )}
        </div>
    );
}
