import React, {useContext} from "react";
import {HardwareListingContext} from "../../../../contexts/hardware-listing-context/hardware-listing-context";
import PaginationComponent from "../../ui-component/pagination/pagination";

export default function HardwareListingPagination() {
    const {maxResultCount, perPage, currentPage, onPageChange} = useContext(HardwareListingContext);
    const pageCount = Math.ceil(100 / 10);

    return (
        <PaginationComponent
            itemsCount={maxResultCount}
            currentPage={currentPage}
            itemsPerPage={perPage}
            setCurrentPage={page => onPageChange(page)}
        />
    );
}
