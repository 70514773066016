/* eslint-disable no-console */
import routes from '../../navigation/routes';
import { PAYMENT_STATUSES } from '../reducers/payment';
import basketTypes from '../workers/basket/basket-types';
import { startLoading, stopLoading } from '../workers/loading';
import {
  buyPlan,
  buyPlanAuthorized,
  buyPlanCheck,
} from '../workers/payment/payment';
import paymentTypes from '../workers/payment/payment-types';
import get from 'lodash/get';
import { call, takeLatest, put } from 'redux-saga/effects';
import {createDispatchLog} from "../workers/conversion-tracking";
import {ConversionTrackingActionNames} from "../../types/conversionTrackingActionNames";

function* buyPlanSaga(params) {
  try {
    yield put(startLoading('buyPlansLoading'));
    const { data } = yield call(buyPlan, params.payload);

    const payIds = get(data, 'payload.payIds', '');
    if (params.navigate && !params.checkout) {
      params.navigate(routes.payment, {
        state: { clientSecret: data.payload.stripId, user: params.user, payIds },
      });
    }
    else if(params.checkout) {
      const {userSimPlanId, simType} = data.payload;
      yield put({
        type: paymentTypes.CHECKOUT_DATAS,
        payload: { clientSecret: data.payload.stripId, user: params.user, payIds, userSimPlanId, simType }
      });
    }
  } catch (error) {
    if (error.response) {
      yield put({
        type: paymentTypes.PAYMENT_FAILED,
        payload: error.response.data.errors,
      });
    }
  } finally {
    yield put(stopLoading('buyPlansLoading'));
  }
}

function* buyPlanAuthorizedSaga(params) {
  try {
    yield put(startLoading('buyPlansAuthorizedLoading'));
    const { data } = yield call(buyPlanAuthorized, params.payload);

    const payIds = get(data, 'payload.payIds', '');
    if (params.navigate && !params.checkout) {
      params.navigate(routes.checkout, {
        state: { clientSecret: data.payload.stripId, user: params.user, payIds },
      });
    } else if(params.checkout) {
      const {userSimPlanId, simType} = data.payload;
      yield put({
        type: paymentTypes.CHECKOUT_DATAS,
        payload: { clientSecret: data.payload.stripId, user: params.user, payIds, userSimPlanId, simType }
      });
    }
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message);
      yield put({
        type: paymentTypes.PAYMENT_FAILED,
        payload: error.response.data.errors,
      });
    }
  } finally {
    yield put(stopLoading('buyPlansAuthorizedLoading'));
  }
}

function* buyPlanCheckSaga({ payIds = [], email = '' }) {
  try {
    yield call(buyPlanCheck, payIds);

    yield put(createDispatchLog(ConversionTrackingActionNames.BUY, {
      actionDataType: 'BUY_SUCCESS',
      data: {
        payIds, email
      }
    }));

    yield put({
      type: paymentTypes.SET_PAYMENT_STATUS,
      paymentStatus: {
        status: PAYMENT_STATUSES.SUCCESS,
        message: `We’ll email ${email} a receipt for each invoice`,
      },
    });

    yield put({
      type: basketTypes.BASKET_UPDATE,
      payload: {
        totalCount: 0,
        totalPrice: 0,
        totalLines: 0,
        totalHardwares: 0,
        totalSimPrice: 0
      },
    });

    yield localStorage.setItem('basket', JSON.stringify([]));
  } catch (e) {
    const status = get(e, 'response.data.code', '');
    const message = get(e, 'response.data.message', '');

    if (status === 503) {
      yield put({
        type: paymentTypes.SET_PAYMENT_STATUS,
        paymentStatus: {
          status: PAYMENT_STATUSES.ERROR_STRIPE,
          message,
        },
      });
    }

    if (status === 507) {
      yield put({
        type: paymentTypes.SET_PAYMENT_STATUS,
        paymentStatus: {
          status: PAYMENT_STATUSES.ERROR_SIM_CARD,
          message,
        },
      });
    }

    yield put(createDispatchLog(ConversionTrackingActionNames.BUY, {
      actionDataType: 'BUY_STRIPE_ERROR',
      data: {
        status, message
      }
    }));
  }
}

function* buyPlanStripeSaga({
  stripe = {},
  clientSecret = '',
  paymentConfig = {},
  payIds = [],
  email = '',
}) {
  try {
    yield put(startLoading('buyPlansStripeLoading'));
    yield put(createDispatchLog(ConversionTrackingActionNames.BUY, {
      actionDataType: 'BUY_STRIPE_START',
      data: {
        stripe, clientSecret, paymentConfig, payIds, email
      }
    }));
    yield stripe.confirmCardPayment(clientSecret, paymentConfig);
    yield call(buyPlanCheckSaga, { payIds, email });
  }
  catch {}
  finally {
    yield put(stopLoading('buyPlansStripeLoading'));
  }
}

export default function* paymentWatcher() {
  yield takeLatest([paymentTypes.BUY_PLAN], buyPlanSaga);
  yield takeLatest([paymentTypes.BUY_PLAN_AUTHORIZED], buyPlanAuthorizedSaga);
  yield takeLatest([paymentTypes.BUY_PlAN_CHECK], buyPlanCheckSaga);
  yield takeLatest([paymentTypes.BUY_PLAN_STRIPE], buyPlanStripeSaga);
}
