import './hardware-detail-similar-products.scss';
import React, {useContext, useState} from "react";
import {HardwareDetailContext} from "../../../../contexts/hardware-detail-context/hardware-detail-context";
import HardwareListingProductItem from "../hardware-listing-product-item/hardware-listing-product-item";
import ChevronDownIcon from "../../../../assets/images/hardwares/chevron-down.svg";

export default function HardwareDetailSimilarProducts() {
    const { similarProducts } = useContext(HardwareDetailContext);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const itemWidth = 310;

    function goNext() {
        setCarouselIndex(s => s + 1 === similarProducts.length ? 0 : s + 1);
    }

    function goPrev() {
        setCarouselIndex(s => s - 1 < 0 ? similarProducts.length -1 : s - 1);
    }

    if(!similarProducts?.length) {
        return null;
    }

    return (
        <div className="hwd-similar-products">
            <div className="hwd-similar-products--title">
                Similar products
            </div>
            <div className="hwd-similar-products--list">
                <div className="hwd-similar-products--list-overflow-outer" style={{
                    minWidth: `${itemWidth}px`
                }}>
                    <div className="hwd-similar-products--list-overflow" style={{
                        width: `${(itemWidth * similarProducts.length)}px`,
                        left: `${carouselIndex * itemWidth * -1}px`
                    }}>
                        {similarProducts.map((product, pIdx) => (
                            <div className="hwd-similar-products--item"
                                 style={{ width: itemWidth }}
                                 key={product.id}>
                                <HardwareListingProductItem product={product} />
                            </div>
                        ))}
                    </div>
                </div>

                <img src={ChevronDownIcon} alt="Go Prev" className="hwd-similar-products--prev" onClick={goPrev} />
                <img src={ChevronDownIcon} alt="Go Next" className="hwd-similar-products--next" onClick={goNext} />
            </div>
        </div>
    );
}
