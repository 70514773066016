export default {
  GET_ACCOUNT_DATA: 'GET_ACCOUNT_DATA',
  SET_ACCOUNT_DATA: 'SET_ACCOUNT_DATA',
  PUT_ACCOUNT_DATA: 'PUT_ACCOUNT_DATA',
  POST_ACCOUNT_PASSWORD: 'POST_ACCOUNT_PASSWORD',
  LOAD_PRODUCT: 'LOAD_PRODUCT',
  GET_PRODUCT_DATA: 'GET_PRODUCT_DATA',
  LOAD_CURRENT_PRODUCT: 'LOAD_CURRENT_PRODUCT',
  GET_CURRENT_PRODUCT: 'GET_CURRENT_PRODUCT',
  SEND_VERIFICATION_EMAIL_CODE: 'SEND_VERIFICATION_EMAIL_CODE',
  SET_METHOD_VERIFICATION: 'SET_METHOD_VERIFICATION',
  SET_VERIFICATION_EMAIL_PROGERSS: 'SET_VERIFICATION_EMAIL_PROGERSS',
  SET_VERIFICATION_YUBIKEY_PROGRESS: 'SET_VERIFICATION_YUBIKEY_PROGRESS',
  POST_ACTIVATE_SIM: 'PUT_ACTIVATE_SIM',
  LOAD_ACTIVATE_SIM: 'LOAD_ACTIVATE_SIM',
  POST_CHANGE_NUMBER: 'POST_CHANGE_NUMBER',
  LOAD_CHANGE_NUMBER: 'LOAD_CHANGE_NUMBER',
  POST_ACTIVATE_ESIM: 'PUT_ACTIVATE_ESIM',
  LOAD_ACTIVATE_ESIM: 'LOAD_ACTIVATE_ESIM',
  GET_QR: 'GET_QR',
  LOAD_GET_QR: 'LOAD_GET_QR',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  LOAD_ACTIVATE_ESIM_AND_LOAD_GET_QR: 'LOAD_ACTIVATE_ESIM_AND_LOAD_GET_QR',
  IS_USER_ELIGIBLE_FOR_VPN: 'IS_USER_ELIGIBLE_FOR_VPN',
  VPN_USER: 'VPN_USER',
  VPN_USER_LOADING: 'VPN_USER_LOADING',
  LOAD_IS_USER_ELIGIBLE_FOR_VPN: 'LOAD_IS_USER_ELIGIBLE_FOR_VPN',
  LOAD_VPN_USER: 'LOAD_VPN_USER',
  POST_VPN_USER: 'POST_VPN_USER',
  LOAD_VPN_APP_LINKS: 'LOAD_VPN_APP_LINKS',
  VPN_APP_LINKS: 'VPN_APP_LINKS',
  VPN_APP_LINKS_LOADING: 'VPN_APP_LINKS_LOADING',
  RESET_VPN_USER_PASSWORD: 'RESET_VPN_USER_PASSWORD',
  RESET_VPN_USER_PASSWORD_LOADING: 'RESET_VPN_USER_PASSWORD_LOADING',
  SET_VPN_USER_PASSWORD: 'SET_VPN_USER_PASSWORD',
  SET_VPN_USER_PASSWORD_LOADING: 'SET_VPN_USER_PASSWORD_LOADING',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  CHANGE_PLAN: 'CHANGE_PLAN',
  CHANGE_PLAN_RESPONSE: 'CHANGE_PLAN_RESPONSE',
  CONFIRM_CHANGE_PLAN: 'CONFIRM_CHANGE_PLAN',
  CONFIRM_CHANGE_PLAN_RESPONSE: 'CONFIRM_CHANGE_PLAN_RESPONSE',
  SET_VERIFICATION_OTP_PROGRESS: 'SET_VERIFICATION_OTP_PROGRESS',
  LOAD_OTP_QR: 'LOAD_OTP_QR',
  OTP_QR: 'OTP_QR'
};
