import ActivateCardDelivery from '../../../components/account/delivery/activate-card-delivery/activate-card-delivery';
import Tracker from '../../../components/account/delivery/tracker/tracker';
import React from 'react';
import './account-screen-delivery.scss';
import { useParams, useLocation } from 'react-router-dom';

const AccountScreenDelivery = () => {
  const params = useParams();
  const location = useLocation();

  const renderContent = () => {
    if (params.type !== 'esim') {
      return (
        <>
          <Tracker />
          <ActivateCardDelivery />
        </>
      );
    }
    return <ActivateCardDelivery />;
  };

  return (
    <div className="account-screen-delivery-container">
      {location.search.indexOf('?BLOCKED') > -1 ? (
          <h2 className="account-title">
            Your sim has been suspended, please contact support with any questions via <a href="mailto:support@gotalkwireless.com">support@gotalkwireless.com</a>
          </h2>
      ) : renderContent()}
    </div>
  );
};

export default AccountScreenDelivery;
