import './basket-delivery-provider.scss';
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import basketTypes from "../../../redux/workers/basket/basket-types";

export default function BasketDeliveryProvider() {
    const hasDelivery = useSelector((state) => state.basketReducer.hasDelivery);
    const deliveryProvider = useSelector(state => state.basketReducer.deliveryProvider);
    const dispatch = useDispatch();

    const providers = [
        {key: 'FREE', label: 'Free Delivery (3 - 5 days)', price: 0},
        {key: 'USPS', label: 'USPS Priority (1 - 3 days)', price: 9},
        {key: 'FEDEX', label: 'FedEx Express (1 - 2 days)', price: 18},
    ];

    function changeProvider(deliveryProvider) {
        dispatch({
            type: basketTypes.BASKET_UPDATE,
            payload: {
                deliveryProvider
            },
        });
    }

    if(!hasDelivery) {
        return (
            <>
                <div className="basket__price-col">Delivery:</div>
                <div className="basket__price-col sum">{hasDelivery ? 'is 2 – 3 days' : '$0'}</div>
            </>
        );
    }

    return (
        <div className="bdp--selector">
            {providers.map(item => (
                <div className={`bdp--selector--item ${deliveryProvider.key === item.key ? 'active' : ''}`}
                    key={item.key}
                    onClick={() => changeProvider(item)}>
                    <div className="bdp--selector--item--check" />
                    <div className="bdp--selector--item--label">
                        {item.label}
                    </div>
                    <div className="bdp--selector--item--price">
                        ${item.price}
                    </div>
                </div>
            ))}
        </div>
    );
}
