import routes from '../../../navigation/routes';
import NavigationLink from '../../components/navigation-link/navigation-link';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import './navigation-menu.scss';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import HeaderAccountDropdown from "../../components/ui-component/header-account-dropdown/header-account-dropdown";
import authTypes from "../../../redux/workers/auth/auth-types";
import bag from "../../../assets/images/icons/bag.svg";
import HeaderDropdown from "../../components/ui-component/header-dropdown/header-dropdown";
import {fetchBrands} from "../../../redux/workers/hardwares/hardwares";

const NavigationMenu = ({
  addClass, classLink, links, onLinkClick, mapNav, baseHeader
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    firstName = '',
    lastName = '',
    basketItemsCount = 0,
    isSignedIn = false,
  } = useSelector((store) => ({
    basketItemsCount: store.basketReducer.totalCount,
    isSignedIn: store.authReducer.isSignedIn,
    firstName: store.authReducer.user.firstName,
    lastName: store.authReducer.user.lastName,
  }));
  const dispatch = useDispatch();

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    fetchBrands().then(res => {
      setBrands(res.data.payload || []);
    })
  }, []);

  useEffect(() => {
    if (location.hash === '#map-section') {
      const section = document.getElementById('map-section');
      section?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const placeholderName = useMemo(() => {
    if(isSignedIn) {
      return `${firstName} ${lastName?.substr(0, 1)}.`;
    }
    return '';
  }, [firstName, isSignedIn, lastName]);

  const renderLinks = () => links.map((link, idx) => {
    if(link.type === 'hardwares') {
      return (
          <HeaderDropdown
              title={link.title}
              dropdownItems={[
                  ...link.dropdownItems,
                  ...brands.map(b => ({
                    title: b, path: `${routes.hardwares.base}${routes.hardwares.cellPhones}?brand=${b}`
                  }))
              ]}
              key={idx}
              isFooter={addClass?.indexOf('footer-navigation') > -1}
          />
      );
    }
    if(link.dropdown) {
      return (
          <HeaderDropdown
            title={link.title}
            dropdownItems={link.dropdownItems}
            key={idx}
            isFooter={addClass?.indexOf('footer-navigation') > -1}
          />
      );
    }
    return (
        <NavigationLink
            onClick={onLinkClick}
            addClass={`header-link-navigation ${classLink}`}
            title={link.title}
            path={link.path}
            key={link.path}
        />
    );
  });

  const handleCoverageNavigation = () => {
    if (location.pathname !== routes.home) {
      navigate(`${routes.home}#map-section`);
    }

    const section = document.getElementById('map-section');
    section?.scrollIntoView({ behavior: 'smooth' });
    onLinkClick();
  };

  const handleExit = () => {
    localStorage.setItem('got-accessToken', '');
    localStorage.setItem('got-user', '');
    dispatch({ type: authTypes.LOGOUT });
    navigate(routes.signIn.base);
  };

  return (
    <div className={`navigation-menu ${addClass}`}>
      {/*{mapNav && (*/}
      {/*  <button*/}
      {/*    onClick={handleCoverageNavigation}*/}
      {/*    className={`header-link-navigation ${classLink}`}*/}
      {/*    type="button"*/}
      {/*  >*/}
      {/*    Check coverage*/}
      {/*  </button>*/}
      {/*)}*/}
      {renderLinks()}
      {baseHeader && (
          <>
            {isSignedIn ? (
                <HeaderAccountDropdown
                    placeholder={placeholderName}
                    accountPath={routes.account.base}
                    onExit={handleExit}
                    showUserIcon
                />
            ) : (
                <NavigationLink
                    addClass={`header-link-navigation red ${classLink}`}
                    title="Sign in"
                    path={routes.signIn.base}
                />
            )}
            <NavLink className="header-link-bag" to={routes.bag}>
              <div className="header-link-bag--icon">
                <img src={bag} alt="bag" />
                {basketItemsCount > 0 && <p className="header-link-bag--icon--count">{basketItemsCount}</p>}
              </div>
              Bag
            </NavLink>
          </>
      )}
    </div>
  );
};

NavigationMenu.defaultProps = {
  onLinkClick: () => {},
  addClass: '',
  classLink: '',
  links: [
    {
      title: 'Phones',
      type: 'hardwares',
      dropdown: true,
      dropdownItems: [
        { title: 'ALL', path: `${routes.hardwares.base}${routes.hardwares.cellPhones}` },
      ]
    },
    { title: 'Why Change Carrier', path: `${routes.worksNew}` },
    { title: 'FAQ', path: `${routes.faq}` },
    { title: 'Fraud Protection', path: `${routes.protect}` },
    { title: 'Blog', path: `${routes.blog}` },
    {
      title: 'Business', dropdown: true,
      dropdownItems: [
        { title: 'Private Wireless', path: routes.privateWireless },
        { title: 'iOT Services', path: `${routes.landingPage}/iot-plans` },
      ]
    },
  ],
  mapNav: false,
};

NavigationMenu.propTypes = {
  addClass: PropTypes.string,
  classLink: PropTypes.string,
  links: PropTypes.array,
  onLinkClick: PropTypes.func,
  mapNav: PropTypes.bool,
  baseHeader: PropTypes.bool
};

export default NavigationMenu;
