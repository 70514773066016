export function beautifyPhoneNumber(phone) {
    if(phone?.length === 11) {
        return `+${phone.substr(0, 1)} ${phone.substr(1, 3)} ${phone.substr(4, 3)}-${phone.substr(7, 2)}-${phone.substr(9, 2)}`
    }
    else if(phone?.length === 12) {
        return `+${phone.substr(0, 2)} ${phone.substr(2, 3)} ${phone.substr(5, 3)}-${phone.substr(8, 2)}-${phone.substr(10, 2)}`
    }
    else if(phone?.length >= 13) {
        return `+${phone.substr(0, 3)} ${phone.substr(3, 3)} ${phone.substr(6, 3)}-${phone.substr(9, 2)}-${phone.substr(11, 2)}`
    }
    return phone;
}

export function changeModeUrl(navigate, isBusiness) {
    if(isBusiness) {
        if(window.location.search.indexOf('business') > -1) {
            return;
        }
        navigate({
            pathname: window.location.pathname,
            search: window.location.search ? `${window.location.search}&business` : '?business'
        });
    }
    else {
        if(window.location.search.indexOf('business') === -1) {
            return;
        }
        navigate({
            pathname: window.location.pathname,
            search: window.location.search
                .replace('&business', '')
                .replace('?business', '')
        });
    }
}

export function generateUUID() {
    const chars = '0123456789abcdef'.split('');
    let uuid = [], rnd = Math.random, r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4'; // version 4

    for (let i = 0; i < 36; i++)
    {
        if (!uuid[i])
        {
            r = 0 | rnd() * 16;
            uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r & 0xf];
        }
    }

    return uuid.join('');
}

export const UNLIMITED_VALUE = 100000;
export function isUnlimited(val) {
    return Number(val) >= UNLIMITED_VALUE;
}
