import { canUseCoupon } from '../redux/workers/basket/coupon';

export const addToBasket = (itemId, isLinxdot) => {
  if (!itemId) {
    return;
  }

  let currentBasketItems = JSON.parse(localStorage.getItem('basket'));
  let itemNotInBasket = true;

  if (!currentBasketItems) {
    currentBasketItems = [];
  }

  currentBasketItems = currentBasketItems.map((item) => {
    const updatedItem = item;

    if (updatedItem.planId === itemId) {
      updatedItem.count += 1;
      if (isLinxdot) {
        updatedItem.isLinxdot = isLinxdot;
      }
      itemNotInBasket = false;
    }

    return updatedItem;
  });

  if (itemNotInBasket) {
    currentBasketItems.push({ count: 1, planId: itemId, isEsim: true, isLinxdot, type: 'sim' });
  }

  localStorage.setItem('basket', JSON.stringify(currentBasketItems));
};

export const addHardwareToBasket = (hardwareBasketId, data) => {
  if (!data?.id) {
    return;
  }

  let currentBasketItems = JSON.parse(localStorage.getItem('basket'));
  let itemNotInBasket = true;

  if (!currentBasketItems) {
    currentBasketItems = [];
  }

  currentBasketItems = currentBasketItems.map((item) => {
    if (item.type !== 'hardware') {
      return item;
    }

    const updatedItem = item;

    if (hardwareBasketId === item.hardwareBasketId) {
      updatedItem.count += 1;
      itemNotInBasket = false;
    }

    return updatedItem;
  });

  if (itemNotInBasket) {
    currentBasketItems.push({
      count: 1,
      type: 'hardware',
      hardwareBasketId,
      hardwareId: data.id,
      data,
    });
  }

  localStorage.setItem('basket', JSON.stringify(currentBasketItems));

  if (data.selectedPlanId) {
    addToBasket(data.selectedPlanId);
  }
};

export const deleteFromBasket = (itemId, type = 'sim') => {
  if (!itemId) {
    return;
  }

  const currentBasketItems = JSON.parse(localStorage.getItem('basket'));
  let indexToDelete = -1;

  if (type === 'hardware') {
    indexToDelete = currentBasketItems.findIndex((m) => m.hardwareBasketId === itemId);
  } else {
    indexToDelete = currentBasketItems.findIndex((m) => m.planId === itemId);
  }
  currentBasketItems.splice(indexToDelete, 1);

  localStorage.setItem('basket', JSON.stringify(currentBasketItems));
};

export const getBasketItems = () => {
  let basketItems = localStorage.getItem('basket') || [];

  if (!Array.isArray(basketItems)) {
    basketItems = JSON.parse(basketItems);
  }

  return basketItems.reduce((basket, basketItem) => {
    if (basketItem.planId || basketItem.hardwareBasketId) {
      basket.push(basketItem);
    }

    return basket;
  }, []);
};

export const updateBasket = (itemId, amount, isEsim = true) => {
  let currentBasketItems = JSON.parse(localStorage.getItem('basket'));

  currentBasketItems = currentBasketItems.map((item) => {
    const updatedItem = item;
    if (updatedItem.planId === itemId) {
      updatedItem.count = amount;
      updatedItem.isEsim = isEsim;
    }
    return updatedItem;
  });

  localStorage.setItem('basket', JSON.stringify(currentBasketItems));
};

export const updateHardwareBasket = (hardwareBasketId, amount) => {
  let currentBasketItems = JSON.parse(localStorage.getItem('basket'));

  currentBasketItems = currentBasketItems.map((item) => {
    const updatedItem = item;
    if (updatedItem.hardwareBasketId === hardwareBasketId) {
      updatedItem.count = amount;
    }
    return updatedItem;
  });

  localStorage.setItem('basket', JSON.stringify(currentBasketItems));
};

export const countPrice = (plans, hasDelivery, deliveryProvider, onlyType) => {
  let price = 0;
  let basketItems = getBasketItems();
  if (onlyType) {
    basketItems = basketItems.filter((m) => m.type === 'sim');
  }
  for (const item of basketItems) {
    if (item.type === 'hardware') {
      const selectedStorage =
        item.data.HardwareVariants[item.data.selectedVariantIndex].conditions[
          item.data.selectedConditionIndex
        ].storages[item.data.selectedStorageIndex];
      const pr = selectedStorage.hasDiscount
        ? selectedStorage.discountedPrice
        : selectedStorage.price;
      price += pr * (item.count || 0);
    } else {
      const plan = plans.find((m) => m.id === item.planId);
      let pr = plan ? (plan.hasDiscount ? plan.discountedPrice : plan.costBuyPlan) : 0;

      if (item.isLinxdot) {
        price += pr * item.count * 12;
      } else if (plan?.hasMinMonthLimit) {
        price += pr * (item.count || 0) * plan.minMonthLimit;
      } else {
        price += pr * (item.count || 0);
      }
    }
  }

  if (hasDelivery && !onlyType) {
    price += deliveryProvider.price;
  }

  return price || 0;
};

export const countTotalPlans = () =>
  getBasketItems()
    .filter((m) => m.type !== 'hardware')
    .reduce((total, item) => total + item.count, 0);

export const countTotalHardwares = () =>
  getBasketItems()
    .filter((m) => m.type === 'hardware')
    .reduce((total, item) => total + item.count, 0);

export const hasDeliverableBasketItem = () => {
  const basketItems = getBasketItems();
  return basketItems.some((m) => !m.isEsim) || basketItems.some((m) => m.type === 'hardware');
};

export const decidePaymentType = () => {
  const basketItems = getBasketItems();
  return basketItems.some((m) => m.type === 'hardware') ? 'authorizeNet' : 'stripe';
};

export const setCouponToLocalStorage = (coupon) => {
  try {
    if (!coupon) {
      localStorage.removeItem('coupon');
    } else {
      localStorage.setItem('coupon', JSON.stringify(coupon));
    }
  } catch {}
};

export const getCouponFromLocalStorage = async () => {
  try {
    const storedCoupon = JSON.parse(localStorage.getItem('coupon'));
    if (!storedCoupon) {
      return null;
    }

    const basketItems = getBasketItems();
    const coupon = await canUseCoupon({
      code: storedCoupon.code,
      planIds: basketItems.filter((m) => m.planId).map((m) => m.planId),
      hardwareIds: basketItems.filter((m) => m.hardwareId).map((m) => m.hardwareId),
    });
    setCouponToLocalStorage(coupon?.data?.payload);
    if (coupon?.data?.payload) {
      return coupon.data.payload;
    } else {
      localStorage.removeItem('coupon');
      return null;
    }
  } catch {
    return null;
  }
};

export const getDiscountAmount = (coupon, totalSimPrice) => {
  if (!coupon) {
    return 0;
  }

  if (coupon.discountType === 'PERCENTAGE') {
    return totalSimPrice / (100 / coupon.discountPercentage);
  }

  return coupon.discountAmount;
};
