import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import accountTypes from "../../../../redux/workers/account/account-types";
import './account-screen-vpn.scss';
import Input from "../../../components/ui-component/input/input";
import Spinner from "../../../components/ui-component/spinner/spinner";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import Popup from "../../../components/ui-component/popup/popup";

const schema = yup.object({
    password: yup
        .string()
        .min(10, 'Must contain at least 10 characters')
        .matches(
            /^(?=.*\d)(?=.*[!@#$%^&*?])(?=.*[a-z])(?=.*[A-Z]).{10,}$/,
            'Must contain one uppercase, one lowercase, one number and one special case character',
        )
        .required('This field is required'),
    passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'Passwords must match'),
});

export default function AccountScreenVpn() {

    const {
        vpnAppLinks,
        vpnAppLinksLoading,
        vpnUser,
        vpnUserLoading,
        accountData,
        resetVpnUserPasswordLoading,
        setVpnUserPasswordLoading
    } = useSelector(state => ({
        vpnUser: state.accountReducer.vpnUser,
        vpnUserLoading: state.accountReducer.vpnUserLoading,
        vpnAppLinks: state.accountReducer.vpnAppLinks,
        vpnAppLinksLoading: state.accountReducer.vpnAppLinksLoading,
        accountData: state.accountReducer.accountData,
        resetVpnUserPasswordLoading: state.accountReducer.resetVpnUserPasswordLoading,
        setVpnUserPasswordLoading: state.accountReducer.setVpnUserPasswordLoading
    }));
    const dispatch = useDispatch();
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            password: '',
            passwordConfirmation: ''
        }
    });

    useEffect(() => {
        if(vpnAppLinks.length === 0) {
            dispatch({
                type: accountTypes.LOAD_VPN_APP_LINKS
            });
        }

        if(!vpnUser) {
            dispatch({
                type: accountTypes.LOAD_VPN_USER
            });
        }
    }, []);

    const vpnAppLinksOutput = useMemo(() => {
        return (vpnAppLinks || []).map(m => {
            let img;
            switch (m.os) {
                case 'windows':
                    img = require('../../../../assets/images/logo/windows-logo.png');
                    break;

                case 'macos':
                    img = require('../../../../assets/images/logo/macos-logo.png');
                    break;

                case 'android':
                    img = require('../../../../assets/images/logo/android-logo.png');
                    break;

                case 'ios':
                    img = require('../../../../assets/images/logo/ios-logo.png');
                    break;
            }
            return {
                ...m,
                img
            };
        })
    }, [vpnAppLinks]);

    function createVpnAccount(values) {
        dispatch({
            type: accountTypes.POST_VPN_USER,
            payload: values
        });
    }

    function resetVpnPassword() {
        dispatch({
            type: accountTypes.RESET_VPN_USER_PASSWORD,
            data: {
                email: accountData.email
            }
        });
    }

    function issueVpnPassword(values) {
        dispatch({
            type: accountTypes.SET_VPN_USER_PASSWORD,
            data: {
                email: accountData.email,
                password: values.password
            }
        });
    }

    function renderVpnUser() {
        if(vpnUserLoading || resetVpnUserPasswordLoading) {
            return (
                <div className="vpn-nouser">
                    <Spinner />
                </div>
            );
        }

        if(!vpnUser) {
            return null;
        }

        if(vpnUser.noUser) {
            return (
                <div className="vpn-nouser">
                    <p>
                        You can create a vpn account and use it on our mobile app and other apps below
                    </p>
                    <form onSubmit={handleSubmit(createVpnAccount)}>
                        <Input
                            type="email"
                            label="E-mail address"
                            value={accountData?.email || ''}
                            disabled={true}
                            containerClass="vpn-user-input"
                        />
                        <Input
                            {...register('password')}
                            description={errors.password?.message}
                            isInvalid={!!errors.password}
                            label="New password"
                            placeholder="Enter password"
                            type="password"
                            containerClass="vpn-user-input"
                        />
                        <Input
                            {...register('passwordConfirmation')}
                            description={errors.passwordConfirmation?.message}
                            isInvalid={!!errors.passwordConfirmation}
                            label="Confirm password"
                            placeholder="Confirm password"
                            type="password"
                            containerClass="vpn-user-input"
                        />
                        <button type="submit" className="vpn-nouser-button">
                            Create VPN Account
                        </button>
                    </form>
                </div>
            );
        }

        return (
          <div className="vpn-user">
              {vpnUser.status === "active" && (
                  <p>Issue a password below for your VPN account access</p>
              )}
              {vpnUser.status === "passive" && (
                  <p style={{color: "#f00"}}>Your account is passive!</p>
              )}

              <Input
                  type="email"
                  label="E-mail address"
                  value={accountData?.email || ''}
                  disabled={true}
                  containerClass="vpn-user-input"
              />

              <button type="button" className="vpn-user-reset-button" onClick={() => setPasswordModalOpen(true)}>
                  Issue Password
              </button>
          </div>
        );
    }

    return (
        <div className="vpn-container">
            <div className="vpn-user-container">
                <h3>VPN Account</h3>
                {renderVpnUser()}
            </div>

            <div className="vpn-app-links-container">
                <h3>VPN App Links</h3>
                <div className="vpn-app-links">
                    {vpnAppLinksLoading && <Spinner />}
                    {vpnAppLinksOutput.map(item => (
                        <a href={item.link} target="_blank" className="vpn-app-link-item" key={item.os}>
                            <div className="vpn-app-link-image">
                                <img src={item.img} alt={item.title} />
                            </div>
                            <div className="vpn-app-link-item-text">
                                {item.title}
                            </div>
                        </a>
                    ))}
                </div>
            </div>

            {passwordModalOpen && (
                <Popup close={() => setPasswordModalOpen(false)}
                       addClass="vpn-password-popup">
                    <h3 className="vpn-modal-title">Issue New Password</h3>
                    {setVpnUserPasswordLoading ? (
                        <Spinner />
                    ) : (
                        <form onSubmit={handleSubmit(issueVpnPassword)}>
                            <Input
                                {...register('password')}
                                description={errors.password?.message}
                                isInvalid={!!errors.password}
                                label="New password"
                                placeholder="Enter password"
                                type="password"
                                containerClass="vpn-user-input"
                            />
                            <Input
                                {...register('passwordConfirmation')}
                                description={errors.passwordConfirmation?.message}
                                isInvalid={!!errors.passwordConfirmation}
                                label="Confirm password"
                                placeholder="Confirm password"
                                type="password"
                                containerClass="vpn-user-input"
                            />
                            <button type="submit" className="vpn-password-button">
                                Set Password
                            </button>
                        </form>
                    )}
                </Popup>
            )}
        </div>
    );
}
